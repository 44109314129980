import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: ${(props) => props.theme.spacings.xs1} ${(props) => props.theme.spacings.xs};
  gap: ${(props) => props.theme.spacings.xs2}
  width: 100%;
  max-width: 1440px;
`;
