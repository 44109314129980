import { trpc } from "$client";
import { useEffect } from "react";

export function Guard({ children, companyId }: { companyId: string; children: React.ReactNode }) {
  const { invalidate } = trpc.useUtils();

  useEffect(() => {
    invalidate();
  }, [companyId, invalidate]);

  return <>{children}</>;
}
