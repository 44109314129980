import { trpc } from "$client";
import { ExternalRoutes } from "$frontend/routes/routes";
import { dispatchToast } from "$frontend/utils/dispatch-events";
import { ExpenseType } from "$serverTypes";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export function useApproveExpense(type: ExpenseType) {
  const navigate = useNavigate();
  const { t } = useTranslation("translations", {
    keyPrefix: `pages.visualizeApproval.${type === ExpenseType.CORPORATE_CARD ? "movement" : "reimbursement"}`,
  });
  const { mutate, isLoading: isApproving } = trpc.expense.approvals.approve.useMutation({
    onSuccess: () => {
      dispatchToast({
        type: "success",
        content: t("toast.approve.success"),
        description: t("toast.approve.successSubtitle"),
      });
      if (type === ExpenseType.CORPORATE_CARD) navigate(ExternalRoutes.PENDING_ACCOUNT_APPROVALS);
      if (type === ExpenseType.REIMBURSEMENT) navigate(ExternalRoutes.PENDING_REIMBURSEMENT_APPROVALS);
    },
    onError: () => {
      dispatchToast({
        type: "error",
        content: t("toast.approve.error"),
        description: t("toast.tryAgain"),
      });
    },
  });

  function approve(id: string) {
    mutate({
      ids: [id],
    });
  }

  return { approve, isApproving };
}
