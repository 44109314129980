import { trpc } from "$client";
import { FeatureFlagService } from "$frontend/services";
import { dispatchToast } from "$frontend/utils/dispatch-events";
import { external } from "@flash-hros/corporate-onboarding";
import { useCheckAcquisition } from "@flash-tecnologia/expense-web-activation-utils";
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Acquisition } from "../form/useAcquisitionForm";

export function useSaveForm() {
  const { t } = useTranslation("translations", {
    keyPrefix: "pages.acquisitionForm.error",
  });
  const isCorporateCardOnboarding = FeatureFlagService.getFlag("corporateCardOnboarding");
  const navigate = useNavigate();
  function onFinish() {
    navigate(-1);
  }
  const { selectedCompany } = useSelectedCompany();
  const { updateResultToLS } = useCheckAcquisition();

  const { mutate, isLoading } = trpc.company.saveAcquisitionForm.useMutation({
    onSuccess: () => {
      updateResultToLS(selectedCompany.id, { isAnswered: true });
      if (isCorporateCardOnboarding) external.showCorporateOnboarding({ onFinish });
    },
    onError: () => {
      dispatchToast({ type: "error", content: t("title"), description: t("description") });
    },
  });

  const save = (data: Acquisition) => {
    const isCurrentUser = data.expense.adminManager.currentUser === "me";
    mutate({
      contracts: data.contracts,
      expense: {
        adminManager: {
          currentUser: isCurrentUser,
          email: data.expense.adminManager.email,
        },
        expectedStarting: data.expense.expectedStarting,
        expectedValue: data.expense.expectedValue,
      },
    });
  };

  return { save, isLoading };
}
