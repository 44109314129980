import { Skeleton, TextField, Typography } from "@flash-tecnologia/hros-web-ui-v2"; //TODO passar pelo styled ou criar componente
import { useTranslation } from "react-i18next";

import { Counters } from "./styled";

type ExpenseDescriptionInputProps = {
  value?: string;
  onChange: (description: string) => void;
  isLoading?: boolean;
  disabled?: boolean;
};

export const ExpenseDescriptionInput = (props: ExpenseDescriptionInputProps) => {
  const { t } = useTranslation("translations", { keyPrefix: "molecules.expenseDescriptionInput" });
  const maxCharacters = 500;

  function onChange(incomingValue: string) {
    if (incomingValue.length <= maxCharacters) {
      props.onChange(incomingValue);
    }
  }

  if (props.isLoading) {
    return <Skeleton height="122px" width="100%" />;
  }

  return (
    <div>
      <TextField
        value={props.value}
        onPaste={(e) => {
          e.preventDefault();
          onChange(e.clipboardData.getData("text"));
        }}
        onChange={(e) => {
          e.preventDefault();
          onChange(e.target.value);
        }}
        id="description"
        label={t("label")}
        aria-pressed
        fullWidth={true}
        multiline
        rows={3}
        disabled={props.disabled}
      />
      <Counters>
        <Typography variant="caption" color="neutral.40">
          {t("maxCharacters", { count: maxCharacters })}
        </Typography>
        <Typography variant="caption" color="neutral.40">
          {props.value?.length ?? 0}/{maxCharacters}
        </Typography>
      </Counters>
    </div>
  );
};
