import { IconTypes } from "@flash-tecnologia/hros-web-ui-v2";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { ShapeIcon, Typography } from "$atoms";
import { trpc } from "$client";
import { DangerActionModal, ReasonSelect, SuccessActionModal, SuccessActionModalProps } from "$molecules";
import { Expense, ExpenseStatus, ExpenseType } from "$serverTypes";
import { ColorVariant } from "$themes";
import { Alignment, useDisplayToast } from "$utils";

export type UpdatableExpenseStatus =
  | ExpenseStatus.FINISHED
  | ExpenseStatus.REQUIRE_CHANGES
  | ExpenseStatus.REJECTED
  | ExpenseStatus.PENDING_ACCOUNTING;

type ConfigType = {
  variant: ColorVariant;
  icon: IconTypes;
  isSuccess: boolean;
  allowedStatus: ExpenseStatus[];
  hasAccountingCommentsOptions?: boolean;
  isRequiredAccountingCommentsOptions?: boolean;
  hideSelectField?: boolean;
};

const configs: Record<UpdatableExpenseStatus, ConfigType> = {
  [ExpenseStatus.FINISHED]: {
    variant: "success",
    icon: "IconCurrencyDollar",
    isSuccess: true,
    allowedStatus: [ExpenseStatus.PENDING_ACCOUNTING],
  },
  [ExpenseStatus.REQUIRE_CHANGES]: {
    variant: "error",
    icon: "IconAlertTriangle",
    isSuccess: false,
    hasAccountingCommentsOptions: true,
    isRequiredAccountingCommentsOptions: true,
    hideSelectField: true,
    allowedStatus: [ExpenseStatus.PENDING_ACCOUNTING],
  },
  [ExpenseStatus.REJECTED]: {
    variant: "error",
    icon: "IconAlertTriangle",
    isSuccess: false,
    hasAccountingCommentsOptions: true,
    isRequiredAccountingCommentsOptions: true,
    hideSelectField: false,
    allowedStatus: [ExpenseStatus.PENDING_ACCOUNTING],
  },
  [ExpenseStatus.PENDING_ACCOUNTING]: {
    variant: "error",
    icon: "IconAlertCircle",
    isSuccess: false,
    allowedStatus: [ExpenseStatus.DRAFT, ExpenseStatus.REQUIRE_CHANGES, ExpenseStatus.FINISHED, ExpenseStatus.REJECTED],
  },
};

type PropsType = {
  /** Status to update (null means to not show the modal) */
  targetStatus: UpdatableExpenseStatus | null;

  /** Expenses to update the status */
  expensesToUpdate: Expense[];

  /** called when modal is closed */
  onClose: () => void;

  /** Called when status update is finished */
  onUpdateFinished?: () => void;
};

export const ExpensesStatusUpdateModal = (props: PropsType) => {
  const expenseType = props.expensesToUpdate[0]?.type ?? ExpenseType.REIMBURSEMENT;
  const { t } = useTranslation("translations", {
    keyPrefix: `organisms.expensesStatusUpdateModal.variants.${expenseType}.${props.targetStatus}`,
  });
  const { t: rootT } = useTranslation("translations", {
    keyPrefix: `organisms.expensesStatusUpdateModal`,
  });
  const { displayToast } = useDisplayToast();
  const [selectedReason, setSelectedReason] = useState<string>("");

  const { mutateAsync: updateExpensesStatus, isLoading } = trpc.expense.updateManyStatus.useMutation();

  async function onActionClick(targetStatus: ExpenseStatus, config: ConfigType, expensesToUpdate: Expense[]) {
    const accountingComments = selectedReason.trim();
    const count = expensesToUpdate.length;
    if (config.isRequiredAccountingCommentsOptions && accountingComments === "") {
      displayToast({
        type: "error",
        title: t("toasts.failed.title", { count }),
        description: rootT("pleaseSelectAReason"),
      });
      return;
    }

    try {
      await updateExpensesStatus({
        targetStatus,
        expenseIds: expensesToUpdate.map(({ id }) => id),
        ...(config.isRequiredAccountingCommentsOptions ? { accountingComments } : {}),
      });
    } catch (e) {
      displayToast({
        type: "error",
        title: t("toasts.failed.title", { count }),
        description: rootT("pleaseTryAgainLater"),
      });
      throw e;
    }

    displayToast({
      type: "success",
      title: t("toasts.success.title", { count }),
    });
    props.onUpdateFinished?.();
    props.onClose();
  }

  if (!props.targetStatus) {
    return <></>;
  }

  const targetStatus = props.targetStatus;
  const config = configs[props.targetStatus];
  const expensesToUpdate = props.expensesToUpdate.filter(({ status }) => config.allowedStatus.includes(status));

  if (expensesToUpdate.length === 0) {
    // TODO: maybe toast? this case is unexpected
    return <></>;
  }

  const modalProps: SuccessActionModalProps = {
    open: true,
    isLoading,
    headerIcon: <ShapeIcon size="large" variant={config.variant} icon={config.icon} />,
    headerIconLabel: rootT("warning"),
    headerTitle: t("headerTitle", { count: expensesToUpdate.length }),
    headerSubtitle:
      targetStatus !== ExpenseStatus.FINISHED && targetStatus !== ExpenseStatus.REJECTED
        ? t("headerSubtitle", { count: expensesToUpdate.length })
        : "",
    labelButtonAction: t("buttonLabel"),
    showCancelButton: true,
    iconPosition: Alignment.left,
    onActionClick: () => onActionClick(targetStatus, config, expensesToUpdate),
    onCloseClick: props.onClose,
    children: config.hasAccountingCommentsOptions ? (
      <>
        <Typography variant="body4" color="neutral.40">
          {t("reasonLabel", { count: expensesToUpdate.length })}
        </Typography>
        <ReasonSelect
          onChange={(selectedReason) => setSelectedReason(selectedReason.name)}
          hideSelectField={config.hideSelectField}
          error={false}
          type={expenseType}
        />
      </>
    ) : undefined,
  };

  if (config.isSuccess) {
    return <SuccessActionModal {...modalProps} />;
  }

  return <DangerActionModal {...modalProps} />;
};
