import { useTranslation } from "react-i18next";

export const useFormOptions = () => {
  const { t } = useTranslation("translations", {
    keyPrefix: "pages.acquisitionForm.options",
  });

  const budgetOptions = [
    { label: t("budgetOptions.upTo10k"), value: 10000 },
    { label: t("budgetOptions.from10kTo20k"), value: 20000 },
    { label: t("budgetOptions.from20kTo30k"), value: 30000 },
    { label: t("budgetOptions.from30kTo50k"), value: 50000 },
    { label: t("budgetOptions.from50kTo100k"), value: 100000 },
    { label: t("budgetOptions.from100kTo200k"), value: 200000 },
    { label: t("budgetOptions.above200k"), value: 250000 },
    { label: t("budgetOptions.unknown"), value: 0 },
  ];

  const personOptions = [
    { label: t("personOptions.me"), value: "me" },
    { label: t("personOptions.other"), value: "other" },
  ];

  const deadlineExpectation = [
    { label: t("deadlineExpectation.upTo1Month"), value: 1 },
    { label: t("deadlineExpectation.upTo2Month"), value: 2 },
    { label: t("deadlineExpectation.upTo3Month"), value: 3 },
    { label: t("deadlineExpectation.upTo4Month"), value: 4 },
    { label: t("deadlineExpectation.upTo5Month"), value: 5 },
    { label: t("deadlineExpectation.above6Months"), value: 6 },
    { label: t("deadlineExpectation.unknown"), value: 0 },
  ];

  return { budgetOptions, personOptions, deadlineExpectation };
};
