import { ColorToken, ThemesType, colors } from "@flash-tecnologia/hros-web-ui-v2";
import React from "react";
import styled from "styled-components";

type Props = {
  /** align-items */
  align?: "start" | "end" | "center" | "space-around" | "space-between";
  /** Gap between children
   * @default 0
   *
   * Options:
   *   - xs5: '4px'
   *   - xs4: '8px'
   *   - xs3: '12px'
   *   - xs2: '16px'
   *   - xs1: '20px'
   *   - xs: '24px'
   *   - s: '32px'
   *   - m: '40px'
   *   - l: '64px'
   *   - xl5: '80px'
   *   - xl4: '96px'
   *   - xl3: '120px'
   *   - xl2: '144px'
   *   - xl: '160px'
   */
  gap?: keyof ThemesType["spacings"];
  /** justify-content */
  justify?: "start" | "end" | "center" | "space-around" | "space-between";
  /** justify-item */
  justifyItem?: "start" | "end" | "center" | "space-around" | "space-between";
  /** padding */
  padding?: keyof ThemesType["spacings"];
  /** Custom styles */
  style?: React.CSSProperties;
  /** Children */
  children: React.ReactNode;
  /** Flex-grow */
  grow?: number;
  /** background-color */
  backgroundColor?: ColorToken;
  /** border-width */
  borderWidth?: keyof ThemesType["borders"]["width"];
  /** border-color */
  borderColor?: ColorToken;
  /** border-radius */
  radius?: keyof ThemesType["borders"]["radius"];
  templateColumns: string;
};

const StyledGrid = styled.div<Props>(({ theme, ...props }) => ({
  alignItems: props.align,
  display: "grid",
  gridTemplateColumns: props.templateColumns,
  gap: props.gap ? theme.spacings[props.gap] : 0,
  justifyContent: props.justify,
  justifyItems: props.justifyItem,
  padding: props.padding ? theme.spacings[props.padding] : 0,
  borderRadius: props.radius ? theme.borders.radius[props.radius] : undefined,
  backgroundColor: props.backgroundColor ? colors.get(props.backgroundColor) : undefined,
  ...(props.borderWidth && {
    border: `${theme.borders.width[props.borderWidth]} solid ${props.borderColor ? colors.get(props.borderColor) : theme.colors.neutral[90]}`,
  }),
}));

const Grid = React.forwardRef<HTMLDivElement, Props>((props, ref) => <StyledGrid ref={ref} {...props} />);

Grid.displayName = "Grid";

export default Grid;
