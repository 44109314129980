import { trpc } from "$client";
import { useRef, useState } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { SkeletonLoader } from "$atoms";
import { DatePeriodMenu } from "$frontend/components/molecules/DatePeriodMenu/DatePeriodMenu";
import { useReimbursementsContext } from "$frontend/pages/Reimbursements/context/ReimbursementsContext";
import { Routes } from "$frontend/routes";
import { useGetSummaryWithOutsideDateRangeCount } from "$frontend/shared/hooks/trpc-utils/use-get-summary-with-outside-date-range-count";
import { NotificationCard, PageContainer, PageHeader } from "$molecules";
import {
  ConfirmationModalDeleteExpense,
  ConfirmationModalEditExpense,
  ExpenseChart,
  ExpenseFilterDrawer,
  ExpenseFiltersFields,
  ExpenseStatusCardList,
  ExpenseTable,
  ExpenseTableHandles,
} from "$organisms";
import { Expense, ExpenseStatus, ExpenseType } from "$serverTypes";
import { defaultStatus, useDisplayToast } from "$utils";
import { Button } from "@flash-tecnologia/hros-web-ui-v2";
import { ButtonContainer, HeaderItemsContainers, SummaryCards } from "./styled";

export function ReimbursementsContent() {
  const { t } = useTranslation("translations", { keyPrefix: "pages.reimbursements" });

  const navigate = useNavigate();

  const expenseType = ExpenseType.REIMBURSEMENT;

  const pageSizes = [5, 10, 15, 25, 50] as const;

  const { displayToast } = useDisplayToast();

  const { methods } = useReimbursementsContext();
  const { control } = methods;
  const contextState = methods.watch();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const [expensesToDelete, setExpensesToDelete] = useState<Expense[]>([]);
  const [expenseToEdit, setExpenseToEdit] = useState<Expense>();

  const [selectedFiltersCount, setSelectedFiltersCount] = useState(0);

  const { data, isFetching, isError, refetch } = trpc.expense.listMe.useQuery(
    {
      type: expenseType,
      status: contextState.filters.status.length > 0 ? contextState.filters.status : defaultStatus,
      categoryNanoIds: contextState.filters.categoryNanoIds.length > 0 ? contextState.filters.categoryNanoIds : [],
      category: contextState.search,
      initialDate: contextState.filters.datePeriod.startDate.toISOString(),
      endDate: contextState.filters.datePeriod.endDate.toISOString(),
      minAmount: contextState.filters.valueRange.min,
      maxAmount: contextState.filters.valueRange.max,
      pageNumber: contextState.pagination.pageNumber,
      pageSize: contextState.pagination.pageSize,
    },
    {
      initialData: {
        expenses: [],
        totalCount: 0,
      },
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  );

  const { mutateAsync: deleteExpense, isLoading: isDeleting } = trpc.expense.deleteExpense.useMutation();

  const expenses = data?.expenses ?? [];
  const expenseTable = useRef<ExpenseTableHandles | null>(null);

  const {
    data: { summaries },
    isFetching: isSummaryLoading,
    refetch: refetchSummary,
    remainingAwaitingAccountExpensesCount,
  } = useGetSummaryWithOutsideDateRangeCount({
    status: defaultStatus,
    targetStatus: [ExpenseStatus.DRAFT, ExpenseStatus.REQUIRE_CHANGES],
    type: expenseType,
    dateRange: { from: contextState.filters.datePeriod.startDate, to: contextState.filters.datePeriod.endDate },
    isAdmin: false,
  });

  const handleFilterChange = (selectedFilters: ExpenseFiltersFields) => {
    methods.setValue("filters.status", selectedFilters?.expenseStatus || []);
    methods.setValue("filters.categoryNanoIds", selectedFilters?.categoryNanoIds || []);
    methods.setValue("filters.valueRange", selectedFilters?.valueRange || { min: undefined, max: undefined });
    methods.setValue("pagination.pageNumber", 1);
  };

  function refetchAll() {
    refetch();
    refetchSummary();
  }

  async function onDelete(): Promise<void> {
    if (!expensesToDelete) {
      return;
    }

    try {
      await deleteExpense(expensesToDelete.map((expense) => expense.id));
      displayToast({
        title: t("toasts.deleteSuccess.title"),
      });
    } catch (e) {
      displayToast({
        type: "error",
        title: t("toasts.deleteError.title"),
        description: t("toasts.deleteError.description"),
      });
      throw e;
    }

    expenseTable.current?.resetSelection();
    setExpensesToDelete([]);
    refetchAll();
  }

  function handleEdit(expense: Expense) {
    if (expense.status === ExpenseStatus.PENDING_APPROVAL) setExpenseToEdit(expense);
    else navigateToEditPage(expense);
  }

  function navigateToEditPage(expense?: Expense) {
    if (expense) navigate(Routes.REIMBURSEMENT_EDIT.replace(":expenseId", expense.id));
    else setExpenseToEdit(undefined);
  }

  return (
    <>
      <PageHeader title={t("title")} subtitle={t("subtitle")}>
        <HeaderItemsContainers>
          <Controller
            name="filters.datePeriod"
            control={control}
            render={({ field }) => (
              <DatePeriodMenu
                value={field.value}
                showAlertBadge={remainingAwaitingAccountExpensesCount > 0}
                alertBadgeTooltip={t("remainingExpensesWarning", { count: remainingAwaitingAccountExpensesCount })}
                isLoading={isSummaryLoading}
                handleDateChange={(value) => {
                  field.onChange(value);
                  methods.setValue("pagination.pageNumber", 1);
                }}
              />
            )}
          />
          <ButtonContainer>
            <Button
              variant="primary"
              size="small"
              onClick={() => navigate("/expense-lifecycle/reimbursement/create")}
              loading={isFetching}
            >
              {t("addReimbursement")}
            </Button>
          </ButtonContainer>
        </HeaderItemsContainers>
      </PageHeader>
      <PageContainer>
        {isError ? (
          <NotificationCard type="error" variant="contained" iconName="IconExclamationMark">
            {t("failedToGetReimbursements")}
          </NotificationCard>
        ) : (
          <>
            {isSummaryLoading ? (
              <SummaryCards>
                <SkeletonLoader isVisible={true} height={212} />
              </SummaryCards>
            ) : (
              <SummaryCards>
                <ExpenseChart type={expenseType} summaries={summaries} />
                <Controller
                  name="filters.status"
                  control={control}
                  render={({ field }) => (
                    <ExpenseStatusCardList
                      summaries={summaries}
                      status={field.value}
                      onStatusChange={(value) => {
                        field.onChange(value);
                      }}
                      type={expenseType}
                    />
                  )}
                />
              </SummaryCards>
            )}

            <ExpenseTable
              ref={expenseTable}
              expenses={expenses}
              isLoading={isFetching || isDeleting}
              isSearchLoading={isSummaryLoading}
              selectedFiltersCount={selectedFiltersCount}
              count={data?.totalCount ?? 0}
              pageSizes={pageSizes}
              pagination={contextState.pagination}
              type={expenseType}
              onPaginationChange={(pagination) => {
                methods.setValue("pagination", pagination, { shouldValidate: true });
              }}
              onDelete={setExpensesToDelete}
              onEdit={handleEdit}
              onVisualize={(expense) => navigate(Routes.REIMBURSEMENT.replace(":expenseId", expense.id))}
              onFilterClick={() => setDrawerOpen(!drawerOpen)}
              onSearch={(search) => {
                methods.setValue("search", search || "");
              }}
            />
          </>
        )}
      </PageContainer>

      <ExpenseFilterDrawer
        expenseType={ExpenseType.REIMBURSEMENT}
        isOpen={drawerOpen}
        onFilterCountChange={(count) => setSelectedFiltersCount(count)}
        onClose={() => setDrawerOpen(false)}
        onSubmit={(selectedFilters: ExpenseFiltersFields) => {
          handleFilterChange(selectedFilters);
          setDrawerOpen(false);
        }}
        filters={{
          expenseStatus: contextState.filters.status,
          categoryNanoIds: contextState.filters.categoryNanoIds,
          valueRange: contextState.filters.valueRange,
        }}
        config={{
          expenseStatus: true,
          categoryNanoIds: true,
          valueRange: true,
        }}
      />

      <ConfirmationModalDeleteExpense
        isOpen={Boolean(expensesToDelete.length > 0)}
        expensesCount={expensesToDelete.length}
        onActionClick={onDelete}
        isLoading={isDeleting}
        onCloseClick={() => setExpensesToDelete([])}
      />

      <ConfirmationModalEditExpense
        isOpen={expenseToEdit !== undefined}
        onActionClick={() => navigateToEditPage(expenseToEdit)}
        isLoading={isDeleting}
        onCloseClick={() => setExpenseToEdit(undefined)}
        type={expenseType}
      />
    </>
  );
}
