import styled from "styled-components";

export const AttachmentContainerContent = styled.div`
  display: flex;
  align-self: stretch;
  padding: ${(props) => props.theme.spacings.xs2} 48px;
  gap: 80px;
  overflow: auto;
  align-items: flex-start;
  justify-content: center;
  flex: 1 0 0;
`;
