import { EmptyGroupList, ExpensesNotifyModal, ExpenseUserGroupRow, ListAccordions } from "$organisms";
import { Expense, ExpensesGroupedEmployeesResponse, ExpenseType } from "$serverTypes";
import { tableControllers } from "@flash-tecnologia/hros-web-ui-v2";
import { PaginationState } from "@flash-tecnologia/hros-web-ui-v2/dist/components/Table/components/Pagination";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ExpensesStatusUpdateModal, UpdatableExpenseStatus } from "../ExpensesStatusUpdateModal";
import { Container, ScrollBox } from "./styled";

type ExpenseUserGroupListProps = {
  /**
   * expense type
   */
  type: ExpenseType;

  /**
   * array of grouped expenses data for each employee
   */
  data: ExpensesGroupedEmployeesResponse[];

  /**
   * total number of items for pagination
   */
  total: number;

  /**
   * indicates if data is currently loading
   */
  isLoading: boolean;

  /**
   * loading state indicator for the search component in table
   */
  isSearchLoading?: boolean;

  /**
   * pagination state for the expense groups
   */
  groupPagination: PaginationState;

  /**
   Current selected filters count
   */
  selectedFiltersCount?: number;

  /**
   * callback for handling pagination changes
   */
  onGroupPaginationChange: (state: PaginationState) => void;

  /**
   * callback for action is complete
   */
  onActionComplete?: () => void;

  /**
   * callback triggered when the filter button is clicked
   */
  onFilterClick?: () => void;

  /**
   * callback triggered when the input search has changed
   */
  onSearch?: (state: string) => void;
};

export const ExpenseUserGroupList: React.FC<ExpenseUserGroupListProps> = ({
  data,
  total,
  isLoading,
  isSearchLoading,
  selectedFiltersCount,
  groupPagination,
  onGroupPaginationChange,
  onActionComplete,
  type,
  onFilterClick,
  onSearch,
}) => {
  const [selectedExpensesByEmployee, setSelectedExpensesByEmployee] = useState<Record<string, Expense[]>>({});
  const selectedExpenses = Object.values(selectedExpensesByEmployee).reduce(
    (expenses, current) => [...current, ...expenses],
    [],
  );

  const { t } = useTranslation("translations", { keyPrefix: "organisms.expenseUserGroupList" });
  const [statusModalProps, setStatusModalProps] = useState<{
    targetStatus: UpdatableExpenseStatus;
    expensesToUpdate: Expense[];
  } | null>(null);

  const [notifyModalProps, setNotifyModalProps] = useState<{
    expensesToNotify: Expense[];
  } | null>(null);

  const table = tableControllers.useTableColumns({
    data,
    total: data.length,
    columns: [
      {
        header: "id",
        accessorKey: "employeeId",
      },
    ],
    options: {
      selectable: (row) => row.employeeId,
    },
    pagination: groupPagination,
    onPaginationChange: onGroupPaginationChange,
  });

  function getSelected() {
    if (table.selected.selected.length > 0) {
      return table.selected.selected.map((row) => row.original);
    }

    if (table.selected.allSelected) {
      return table.rows.map((row) => row.original);
    }

    return [];
  }

  function onUpdateStatusAll(targetStatus: UpdatableExpenseStatus, groupClicked: ExpensesGroupedEmployeesResponse) {
    const selected = getSelected();
    if (selected.length > 0) {
      setStatusModalProps({
        expensesToUpdate: getSelected().flatMap((group) => group.expenses),
        targetStatus,
      });
    }

    setStatusModalProps({
      targetStatus,
      expensesToUpdate: groupClicked.expenses,
    });
  }

  function onNotifyAll(groupClicked: ExpensesGroupedEmployeesResponse) {
    const selected = getSelected();

    if (selected.length > 0) {
      setNotifyModalProps({
        expensesToNotify: selected.flatMap((group) => group.expenses),
      });
      return;
    }

    setNotifyModalProps({
      expensesToNotify: groupClicked.expenses,
    });
  }

  return (
    <ScrollBox>
      <Container>
        <ListAccordions
          loading={isLoading}
          isSearchLoading={isSearchLoading}
          total={total}
          selectedFiltersCount={selectedFiltersCount}
          pagination={{
            ...groupPagination,
            onChange: onGroupPaginationChange,
          }}
          onFilterClick={onFilterClick}
          onSearch={onSearch}
        >
          {[
            table.rows.length > 0 ? (
              table.rows.map((row, index) => (
                <ExpenseUserGroupRow
                  table={table}
                  type={type}
                  key={`expense-row-${index}`}
                  row={row}
                  onActionComplete={onActionComplete}
                  onSelectedChange={(expenses) =>
                    setSelectedExpensesByEmployee({
                      ...selectedExpensesByEmployee,
                      [row.original.employeeId]: expenses,
                    })
                  }
                  selectedExpenses={selectedExpenses}
                  onUpdateStatusAll={(targetStatus) => onUpdateStatusAll(targetStatus, row.original)}
                  onNotifyAll={() => onNotifyAll(row.original)}
                />
              ))
            ) : (
              <EmptyGroupList title={t("emptyState.title")} description={t("emptyState.description")} />
            ),
          ]}
        </ListAccordions>
        {statusModalProps && (
          <ExpensesStatusUpdateModal
            targetStatus={statusModalProps.targetStatus}
            expensesToUpdate={statusModalProps.expensesToUpdate}
            onClose={() => setStatusModalProps(null)}
            onUpdateFinished={() => {
              table.resetSelected();
              onActionComplete?.();
            }}
          />
        )}

        {notifyModalProps && (
          <ExpensesNotifyModal
            expensesToNotify={notifyModalProps.expensesToNotify}
            onClose={() => setNotifyModalProps(null)}
            onNotifyFinished={() => {
              table.resetSelected();
              onActionComplete?.();
              setNotifyModalProps(null);
            }}
          />
        )}
      </Container>
    </ScrollBox>
  );
};
