import { trpc } from "$client";

export function useBalanceChangeEnabled() {
  const response = trpc.corporateCard.checkBalanceChangeEnabled.useQuery(undefined, {
    cacheTime: Infinity,
    staleTime: Infinity,
  });

  return response?.data?.isEnabled || false;
}
