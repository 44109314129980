import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { trpc } from "$client";
import { useDefaultCostCenter } from "$containers/costCenter/hooks/useDefaultCostCenter";
import { Routes } from "$frontend/routes/routes";
import { handleTrcpError, useDisplayToast } from "$frontend/utils";
import { Expense, ExpenseStatus, OcrResult } from "$serverTypes";
import { useCheckActiveModule } from "@flash-tecnologia/expense-web-activation-utils";
import { ExpenseFormDto } from "../types";

export function useCreateExpense() {
  const { displayToast } = useDisplayToast();
  const { t } = useTranslation("translations", {
    keyPrefix: "pages.createReimbursement",
  });
  const navigate = useNavigate();
  const { data: defaultCostCenter } = useDefaultCostCenter();

  const methods = useForm<ExpenseFormDto>({
    defaultValues: {
      amount: undefined,
      attachments: undefined,
      comments: undefined,
      date: undefined,
      category: undefined,
      establishment: undefined,
      costCenter: undefined,
    },
  });
  const [expenses, setExpenses] = useState<Expense[]>([]);
  const [showSummary, setShowSummary] = useState(false);
  const [ocrData, setOcrData] = useState<OcrResult | null>(null);
  const hasApprovalModule = useCheckActiveModule("approval");

  const { mutateAsync: createExpense, isLoading, isSuccess } = trpc.expense.createExpenseV2.useMutation();

  const { setValue, reset } = methods;

  useEffect(() => {
    if (isSuccess) reset();
    if (defaultCostCenter !== undefined) setValue("costCenter", defaultCostCenter);
  }, [defaultCostCenter, setValue, isSuccess, reset]);

  async function onSubmit(
    formData: ExpenseFormDto,
    shouldSubmit: boolean,
    shouldNavigateBack: boolean,
    shouldShowSummary: boolean,
  ) {
    if (!formData.date || isNaN(new Date(formData.date).getTime())) return;

    const expense = {
      ...formData,
      currency: "BRL",
      date: formData.date.toISOString(),
      costCenter: formData.costCenter
        ? { id: formData.costCenter.id, code: formData.costCenter.code, name: formData.costCenter.name }
        : undefined,
      establishment: formData.establishment
        ? {
            sessionToken: formData.establishment.sessionToken,
            placeId: formData.establishment.placeAutocomplete.placeId,
            name: formData.establishment.placeAutocomplete.name,
            description: formData.establishment.placeAutocomplete.description,
          }
        : undefined,
      ocrData: ocrData?.data ? ocrData.data : undefined,
    };

    try {
      const submitType =
        shouldSubmit && hasApprovalModule ? "sendToApproval" : shouldSubmit ? "sendToAccount" : undefined;
      const { expense: createdExpense, approvalData } = await createExpense({ data: expense, submitType });
      if (submitType === "sendToApproval" && !approvalData?.success) {
        displayToast({
          type: "error",
          title: t("toasts.creationFailed.title"),
          description: t("toasts.creationFailed.submitToApprovalError"),
        });
      } else {
        displayToast({
          title: t("toasts.creationSuccess.title"),
        });
        if (submitType === "sendToApproval") createdExpense.status = ExpenseStatus.PENDING_APPROVAL;
        if (submitType === "sendToAccount") createdExpense.status = ExpenseStatus.PENDING_ACCOUNTING;
      }

      setExpenses([...expenses, createdExpense]);
      if (shouldShowSummary) {
        setShowSummary(true);
      }
      if (shouldNavigateBack) {
        navigate(Routes.REIMBURSEMENTS);
      }
      setOcrData(null);
    } catch (e) {
      const description = handleTrcpError(e, (errorCode) =>
        t(`toasts.creationFailed.messages.${errorCode}`, {
          defaultValue: null,
        }),
      );

      displayToast({
        type: "error",
        title: t("toasts.creationFailed.title"),
        description,
      });
    }
  }

  return {
    onSubmit,
    isLoading,
    methods,
    expenses,
    showSummary,
    setOcrData,
    defaultCostCenter,
  };
}
