import { trpc } from "$client";
import { Routes } from "$frontend/routes";
import { dispatchToast } from "$frontend/utils/dispatch-events";
import { ExpenseType } from "$serverTypes";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export function useRequestReview(type: ExpenseType) {
  const { t } = useTranslation("translations", {
    keyPrefix: type === ExpenseType.CORPORATE_CARD ? "pages.adminMovement" : "pages.adminReimbursement",
  });
  const navigate = useNavigate();
  const { mutate, isLoading: isRequestingReview } = trpc.expense.approvals.requestReview.useMutation({
    onSuccess: () => {
      dispatchToast({
        type: "success",
        content: t("toasts.requestReviewSuccess.title"),
      });

      if (type === ExpenseType.CORPORATE_CARD) {
        navigate(Routes.ADMIN_STATEMENT);
      } else {
        navigate(Routes.ADMIN_REIMBURSEMENTS);
      }
    },
    onError: () => {
      dispatchToast({
        type: "error",
        content: t("toasts.requestReviewError.title"),
        description: t("toasts.requestReviewError.description"),
      });
    },
  });

  function requestReview(id: string, reason: string) {
    if (!reason) {
      dispatchToast({
        type: "error",
        content: t(`toasts.requestReviewCommentRequired.title`),
        description: t(`toasts.requestReviewCommentRequired.description`),
      });
      return;
    }
    mutate({
      ids: [id],
      comment: reason,
    });
  }

  return { requestReview, isRequestingReview };
}
