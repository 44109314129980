import { ShapeIcon } from "$atoms";
import { DangerActionModal, FocusedFooter, ReasonSelect, SuccessActionModal } from "$frontend/components/molecules";
import { Alignment } from "$frontend/utils";
import { Expense, ExpenseType } from "$serverTypes";
import { Button, Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useApproveExpense } from "../data/useApproveExpense";
import { useReproveExpense } from "../data/useReproveExpense";
import { useRequestReview } from "../data/useRequestReview";
export function PendingApprovalActions({ expense }: { expense: Expense }) {
  const { t } = useTranslation("translations", { keyPrefix: "pages.visualizeApproval" });

  const context = expense.type === ExpenseType.CORPORATE_CARD ? "movement" : "reimbursement";

  const { isReproving, reprove } = useReproveExpense(expense.type);
  const { requestReview, isRequestingReview } = useRequestReview(expense.type);
  const { approve, isApproving } = useApproveExpense(expense.type);

  const [openReproveModal, setOpenReproveModal] = useState(false);
  const [openRequestReviewModal, setOpenRequestReviewModal] = useState(false);
  const [openApproveModal, setApprovalModal] = useState(false);

  const [selectedReason, setSelectedReason] = useState<string>("");
  return (
    <>
      <FocusedFooter
        end={[
          <Button
            key="reject"
            variant="secondary"
            variantType="neutral"
            size="small"
            onClick={() => setOpenReproveModal(true)}
          >
            {t("footer.reprove")} <Icons name="IconX" fill="transparent" size={24} />
          </Button>,
          <Button
            key="request-review"
            variant="secondary"
            variantType="neutral"
            size="small"
            onClick={() => setOpenRequestReviewModal(true)}
          >
            {t("footer.requestReview")} <Icons name="IconAlertTriangle" fill="transparent" size={24} />
          </Button>,
          <Button
            key="mark-reimbursed"
            variant="primary"
            variantType="success"
            size="small"
            onClick={() => setApprovalModal(true)}
          >
            {t("footer.approve")} <Icons name="IconCheck" fill="transparent" size={24} />
          </Button>,
        ]}
      />
      <DangerActionModal
        iconPosition={Alignment.left}
        isLoading={isReproving}
        headerIconLabel={t(`${context}.modals.reprove.attention`)}
        headerTitle={t(`${context}.modals.reprove.title`)}
        labelButtonAction={t(`${context}.modals.reprove.confirm`)}
        onActionClick={() => reprove(expense.id, selectedReason)}
        onCancelClick={() => setOpenReproveModal(false)}
        onCloseClick={() => setOpenReproveModal(false)}
        open={openReproveModal}
      >
        <Typography variant="body4" color="neutral.40">
          {t(`${context}.modals.reprove.reasonLabel`)}
        </Typography>
        <ReasonSelect
          onChange={(selectedReason) => setSelectedReason(selectedReason.name)}
          hideSelectField={false}
          error={false}
          type={expense.type}
        />
      </DangerActionModal>
      <DangerActionModal
        iconPosition={Alignment.left}
        isLoading={isRequestingReview}
        headerIconLabel={t(`${context}.modals.requestReview.attention`)}
        headerTitle={t(`${context}.modals.requestReview.title`)}
        headerSubtitle={t(`${context}.modals.requestReview.subtitle`)}
        labelButtonAction={t(`${context}.modals.requestReview.confirm`)}
        onActionClick={() => requestReview(expense.id, selectedReason)}
        onCancelClick={() => setOpenRequestReviewModal(false)}
        onCloseClick={() => setOpenRequestReviewModal(false)}
        open={openRequestReviewModal}
      >
        <Typography variant="body4" color="neutral.40">
          {t(`${context}.modals.requestReview.reasonLabel`)}
        </Typography>
        <ReasonSelect
          onChange={(selectedReason) => setSelectedReason(selectedReason.name)}
          hideSelectField={true}
          error={false}
          type={expense.type}
        />
      </DangerActionModal>
      <SuccessActionModal
        iconPosition={Alignment.left}
        isLoading={isApproving}
        headerIcon={<ShapeIcon size="large" variant={"success"} icon={"IconCurrencyDollar"} />}
        headerIconLabel={t(`${context}.modals.approve.attention`)}
        headerTitle={t(`${context}.modals.approve.title`)}
        labelButtonAction={t(`${context}.modals.approve.confirm`)}
        headerSubtitle={t(`${context}.modals.approve.subtitle`)}
        onActionClick={() => approve(expense.id)}
        onCancelClick={() => setApprovalModal(false)}
        onCloseClick={() => setApprovalModal(false)}
        open={openApproveModal}
      />
    </>
  );
}
