import styled from "styled-components";

export const EmployeeNameContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs5};
  min-width: 200px;
  align-items: center;
`;

export const SummaryItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${(props) => props.theme.spacings.xs5};
  min-width: 150px;
`;

export const AccordionTotals = styled.div`
  color: ${({ theme }) => theme.colors.neutral[20]};
`;
