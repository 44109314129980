import { FormControlLabel } from "@mui/material";
import styled from "styled-components";

export const ListHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${(props) => props.theme.spacings.xs};
`;

export const BatchToggleContainer = styled(FormControlLabel)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${(props) => props.theme.spacings.xs4};

  .MuiFormControlLabel-label {
    font-family: ${(props) => props.theme.fonts.secondary};
    color: ${(props) => props.theme.colors.neutral[30]};
    font-weight: 600;
    white-space: nowrap;
  }
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
  gap: ${(props) => props.theme.spacings.xs};
  margin-top: ${(props) => props.theme.spacings.xs};
  list-style-type: none;
`;
