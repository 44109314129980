import { trpc } from "$client";
import { Routes } from "$frontend/routes";
import { dispatchToast } from "$frontend/utils/dispatch-events";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Origin } from "../ExpenseDetails";

export function useDeleteExpense(origin: Origin) {
  const navigate = useNavigate();
  const { t } = useTranslation("translations", { keyPrefix: "pages.visualizeReimbursement" });
  const { mutate: deleteExpense, isLoading: isDeleting } = trpc.expense.deleteExpense.useMutation({
    onSuccess: () => {
      dispatchToast({
        type: "success",
        content: t("toasts.deleteSuccess.title"),
      });
      const route = resolveNavigate(origin);
      navigate(route);
    },
    onError: () => {
      dispatchToast({
        type: "error",
        content: t("toasts.deleteError.title"),
        description: t("toasts.deleteError.description"),
      });
    },
  });

  return { deleteExpense, isDeleting };
}

function resolveNavigate(origin: Origin) {
  switch (origin) {
    case "admin-reimbursement":
      return Routes.ADMIN_REIMBURSEMENTS;
    case "user-reimbursement":
      return Routes.REIMBURSEMENT;
    default:
      return "/home";
  }
}
