import { Stepper, StepperProps } from "@flash-tecnologia/hros-web-ui-v2"; //TODO passar pelo styled ou criar componente

import { BreadcrumbItem, BreadcrumbPage } from "../BreadcrumbPage/BreadcrumbPage";
import { Body, Container, Content, FlexContainer, Footer, Header, Subtitle, Title } from "./styled";

type HeaderPageProps = {
  title?: string;
  subTitle?: string;
  breadcrumbItems?: BreadcrumbItem[];
  action?: React.ReactNode;
};
type BasePageProps = {
  children?: React.ReactNode;
  header?: HeaderPageProps;
  stepProps?: StepperProps;
  footer?: React.ReactNode;
  className?: string;
  size?: "sm" | "md";
};

export const BasePage = ({ header, children, footer, stepProps, className, size = "md" }: BasePageProps) => {
  return (
    <Container className={className}>
      <Content hasFooter={Boolean(footer)} className="base-page-content">
        {(header?.breadcrumbItems || stepProps) && (
          <FlexContainer>
            <BreadcrumbPage items={header?.breadcrumbItems || []} />
            {stepProps && <Stepper {...stepProps} />}
          </FlexContainer>
        )}
        {(header?.title || header?.subTitle) && (
          <Header>
            <Container>
              <Title variant={size === "md" ? "headline6" : "headline8"} className="base-page-header-title">
                {header?.title}
              </Title>
              <Subtitle variant="body3" className="base-page-header-subtitle">
                {header?.subTitle}
              </Subtitle>
            </Container>
            <div>{header?.action && header.action}</div>
          </Header>
        )}
        <Body className="base-page-body">{children}</Body>
      </Content>
      {footer && <Footer>{footer}</Footer>}
    </Container>
  );
};
