import { Icons, LinkButton, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { Loader } from "$atoms";
import { trpc } from "$client";
import { ExpenseFormDto } from "$frontend/pages";
import { Routes } from "$frontend/routes/routes";
import { handleTrcpError, useDisplayToast } from "$frontend/utils";
import { ExpenseFooterActions, FocusedContainer, FocusedHeader, MovementForm } from "$molecules";
import { Expense, ExpenseStatus } from "$serverTypes";

export const AdminMovementEditPage = () => {
  const { expenseId } = useParams();
  const [loading, setLoading] = useState(false);
  const { displayToast } = useDisplayToast();
  const { t } = useTranslation("translations", {
    keyPrefix: "pages.adminEditMovement",
  });
  const navigate = useNavigate();

  const { mutateAsync: patchExpense } = trpc.expense.patchExpense.useMutation();

  const { data, isLoading } = trpc.expense.getExpenseById.useQuery(expenseId ?? "");
  const { mutateAsync: updateExpenseStatus } = trpc.expense.updateSingleStatus.useMutation();

  const methods = useForm<ExpenseFormDto>({
    defaultValues: {},
  });

  function saveEditMovementData(data: Expense | ExpenseFormDto) {
    methods.setValue("amount", data.amount);
    methods.setValue("attachments", data.attachments);
    methods.setValue("category", data.category);
    methods.setValue("comments", data.comments);
    methods.setValue("date", new Date(data.date));
  }

  useEffect(() => {
    if (data) {
      saveEditMovementData(data);
    }
  }, [data, saveEditMovementData]);

  function shouldSendToAccountByStatus(status?: ExpenseStatus): boolean {
    if (!status) return false;
    return status === ExpenseStatus.DRAFT || status === ExpenseStatus.REQUIRE_CHANGES;
  }

  async function onContinueClick(formData: ExpenseFormDto, shouldSendToAccount?: boolean, navigateToList?: boolean) {
    if (!formData.date || isNaN(new Date(formData.date).getTime())) return;

    const fieldsToPatch = {
      attachments: formData.attachments,
      comments: formData.comments,
      category: formData.category,
    };

    setLoading(true);
    try {
      const expenseId = data?.id ?? "";
      if (!expenseId) {
        displayToast({
          type: "error",
          title: t("toasts.failedEdit.title"),
          description: t("toasts.failedEdit.messages.missingId"),
        });
        return;
      }

      await patchExpense([expenseId, fieldsToPatch]);

      if (shouldSendToAccount) {
        await updateExpenseStatus({
          expenseId: expenseId,
          targetStatus: ExpenseStatus.PENDING_ACCOUNTING,
        });
      }

      displayToast({ title: t("toasts.successEdit.title") });

      if (navigateToList) {
        navigate(Routes.ADMIN_STATEMENT);
      }
      navigate(Routes.ADMIN_MOVEMENT.replace(":expenseId", expenseId ?? ""));
    } catch (e) {
      const description = handleTrcpError(e, (errorCode) =>
        t(`toasts.failedEdit.messages.${errorCode}`, {
          defaultValue: null,
        }),
      );

      displayToast({
        type: "error",
        title: t("toasts.failedEdit.title"),
        description,
      });
    } finally {
      setLoading(false);
    }
  }

  if (isLoading) {
    return <Loader show={true} />;
  }

  const header = (
    <FocusedHeader
      start={[
        <LinkButton variant="neutral" key={1} onClick={() => navigate(Routes.ADMIN_STATEMENT)}>
          <Icons name="IconChevronLeft" />
          {t("back")}
        </LinkButton>,
      ]}
      middle={[
        <Typography variant="headline9" weight={700} color="neutral.30">
          {t("title")}
        </Typography>,
      ]}
    />
  );

  return (
    <FocusedContainer
      header={header}
      footer={
        <ExpenseFooterActions
          onCancelClick={() => navigate(Routes.ADMIN_STATEMENT)}
          methods={methods}
          hasExpense={!shouldSendToAccountByStatus(data?.status)}
          isLoading={loading}
          onContinueClick={(item) => onContinueClick(item, shouldSendToAccountByStatus(data?.status), false)}
          onQuitAndSave={(item) => onContinueClick(item, false, true)}
          type={data?.type}
        />
      }
    >
      {data ? (
        <MovementForm
          expense={data}
          isLoading={isLoading}
          methods={methods}
          formToggle={true}
          onFormToggle={() => {
            return null;
          }}
        />
      ) : (
        <Loader show={isLoading} />
      )}
    </FocusedContainer>
  );
};
