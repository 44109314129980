import { Button, Icons, LinkButton, PillButton, ShapeIcon, Typography } from "@flash-tecnologia/hros-web-ui-v2"; // TODO: passar pelo styled ou criar componente
import { DragEventHandler, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { ReceiptApiClient, ReceiptApiException } from "$frontend/services/receipt-api-client";
import { useDisplayToast } from "$frontend/utils";
import { Alignment } from "$frontend/utils/enum";
import { ButtonGroup, DangerActionModal, ImagesModal } from "$molecules";
import { MAX_RECEIPT_UPLOAD_SIZE } from "$serverConstants";
import { Attachment } from "$serverTypes";
import { AttachmentContainer } from "./AttachmentContainer/AttachmentContainer";
import { EmptyDropzone, Footer, Gallery, Header, HeaderEnd } from "./styled";

const acceptedFileExtensions = ["pdf", "png", "jpeg", "jpg"];

export type ReceiptDropzoneProps = {
  onChange: (attachments: Attachment[]) => void;
  value: Attachment[];
  isLoading?: boolean;
  disabled?: boolean;
};

export const ReceiptDropzone = ({ onChange, value, isLoading: propsIsLoading, disabled }: ReceiptDropzoneProps) => {
  const enabled = !disabled;
  const dummyInput = useRef<HTMLInputElement>(null);
  const { displayToast } = useDisplayToast();
  const { t } = useTranslation("translations", {
    keyPrefix: "molecules.receiptDropzone",
  });

  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [isDragging, setIsDragging] = useState(false);
  const [isReading, setIsReading] = useState(false);
  const [isWriting, setIsWriting] = useState(false);
  const isLoading = isReading || isWriting || propsIsLoading;

  const [attachmentsSrc, setAttachmentsSrc] = useState<Record<string, string>>({});
  const [currentIndex, setCurrentIndex] = useState(0);
  const currentAttachment = value.at(currentIndex);

  useEffect(() => {
    onValuesUpdated();
  }, [value]);

  function updateAttachmentsSrc(newBlobs: Record<string, string>) {
    setAttachmentsSrc({ ...attachmentsSrc, ...newBlobs });
  }

  async function onValuesUpdated() {
    setIsReading(true);
    const attachmentsToProcess = value.filter((attachment) => !attachmentsSrc[attachment.fileName]);
    try {
      const newBlobs = Object.fromEntries(
        await Promise.all(
          attachmentsToProcess.map(
            async (attachment) =>
              [attachment.fileName, URL.createObjectURL(await ReceiptApiClient.getFile(attachment))] as const,
          ),
        ),
      );
      updateAttachmentsSrc(newBlobs);
    } finally {
      setIsReading(false);
    }
  }

  function onDelete(indexToDelete: number) {
    onChange(value.filter((_, index) => index != indexToDelete));
    setCurrentIndex(indexToDelete == 0 ? 0 : indexToDelete - 1);
  }

  async function onFilesChange(incomingFiles: File[]) {
    setIsWriting(true);
    let incomingAttachments: Attachment[] = [];
    try {
      incomingAttachments = await Promise.all(incomingFiles.map(ReceiptApiClient.uploadFile));
    } catch (e) {
      const description =
        e instanceof ReceiptApiException
          ? t(`toasts.uploadError.messages.${e.code}`, {
              defaultValue: null,
            })
          : undefined;
      displayToast({
        type: "error",
        title: t("toasts.uploadError.title"),
        description,
      });
    } finally {
      setIsWriting(false);
    }
    if (incomingAttachments.length > 0) {
      updateAttachmentsSrc(
        Object.fromEntries(
          incomingAttachments.map((attachment, i) => [attachment.fileName, URL.createObjectURL(incomingFiles[i])]),
        ),
      );
      const newValues = [...incomingAttachments, ...value];
      onChange(newValues);
      setCurrentIndex(newValues.length - 1);
    }
  }

  const onDrop: DragEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const files = [...e.dataTransfer.items].map((item) => item.getAsFile()).filter((item) => item !== null) as File[];
    onFilesChange(files);
  };

  const dummyInputElem = (
    <input
      style={{ display: "none" }}
      type="file"
      ref={dummyInput}
      accept={acceptedFileExtensions.map((ext) => "." + ext).join(",")}
      size={MAX_RECEIPT_UPLOAD_SIZE}
      onClick={(e) => (e.currentTarget.value = "")}
      onChange={(e) => onFilesChange([...(e.target.files ?? [])])}
    />
  );

  if (value.length == 0) {
    return (
      <>
        {dummyInputElem}
        <EmptyDropzone
          isDragging={isDragging}
          onDrop={disabled ? undefined : onDrop}
          onDragEnter={() => setIsDragging(true)}
          onDragOver={(e) => e.preventDefault()}
        >
          <ShapeIcon name="IconDragDrop" size={40} variant="default" stroke="default" />
          <div style={{ textAlign: "center" }}>
            <Typography weight={600} variant="body4" color="neutral.20">
              {t("empty.title")}
            </Typography>
            <Typography variant="caption" color="neutral.40">
              {t("empty.subtitle", {
                acceptedFileExtensions: acceptedFileExtensions.map((ext) => ext.toUpperCase()).join(", "),
              })}
            </Typography>
          </div>
          <div>
            <Button
              loading={isLoading}
              variant="secondary"
              size="small"
              onClick={() => dummyInput.current?.click()}
              disabled={disabled}
            >
              {t("empty.uploadButton")}
              <Icons name="IconUpload" />
            </Button>
          </div>
        </EmptyDropzone>
      </>
    );
  }

  return (
    <>
      {dummyInputElem}
      <Gallery
        isDragging={isDragging}
        onDrop={disabled ? undefined : onDrop}
        onDragEnter={() => setIsDragging(true)}
        onDragOver={(e) => e.preventDefault()}
      >
        <Header>
          <div>
            <PillButton
              size="extra-small"
              variant="default"
              icon="IconWindowMaximize"
              onClick={() => setShowModal(true)}
            />
          </div>
          <HeaderEnd>
            <Typography weight={700} variant="caption" color="neutral.40">
              {currentIndex + 1}/{value.length}
            </Typography>
            <ButtonGroup
              options={[
                {
                  icon: "IconChevronLeft",
                  label: t("prevReceipt"),
                  onClick: () => setCurrentIndex(currentIndex - 1),
                  disabled: currentIndex <= 0,
                },
                {
                  icon: "IconChevronRight",
                  label: t("nextReceipt"),
                  onClick: () => setCurrentIndex(currentIndex + 1),
                  disabled: currentIndex >= value.length - 1,
                },
              ]}
            />
          </HeaderEnd>
        </Header>

        <AttachmentContainer
          isLoading={isLoading}
          currentAttachment={currentAttachment}
          attachmentsSrc={attachmentsSrc}
        />

        {enabled && (
          <Footer>
            <div>
              <PillButton
                size="extra-small"
                variant="default"
                icon="IconTrash"
                onClick={() => setConfirmDeleteModalOpen(true)}
              />
            </div>
            <div>
              <LinkButton
                variant="default"
                onClick={() => {
                  dummyInput.current?.click();
                }}
              >
                {t("addReceipt")} <Icons name="IconPlus" />
              </LinkButton>
            </div>
          </Footer>
        )}
      </Gallery>
      {showModal && (
        <ImagesModal
          attachments={value}
          loadingDescription={t("loading")}
          onCloseModalClick={() => setShowModal(false)}
          initialIndex={currentIndex}
        />
      )}
      <DangerActionModal
        iconPosition={Alignment.left}
        isLoading={isLoading}
        headerIconLabel={t("modals.delete.iconLabel")}
        headerTitle={t("modals.delete.title")}
        headerSubtitle={t("modals.delete.subtitle")}
        labelButtonAction={
          <Button>
            {t("modals.delete.action")}
            <Icons name="IconTrash" fill="transparent" size={24} />
          </Button>
        }
        onActionClick={() => {
          onDelete(currentIndex);
          setConfirmDeleteModalOpen(false);
        }}
        onCancelClick={() => setConfirmDeleteModalOpen(false)}
        onCloseClick={() => setConfirmDeleteModalOpen(false)}
        open={confirmDeleteModalOpen}
      ></DangerActionModal>
    </>
  );
};
