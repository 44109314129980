import {
  ColorToken,
  Icons as FlashIcons,
  IconsProps as FlashIconsProps,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";

import { useTheme } from "styled-components";
import { Container } from "./styled";

export type MenuOptionProps = {
  /** Icon for the menu option */
  icon: FlashIconsProps["name"];
  /** Optional color for the icon */
  iconColor?: string;
  /** Label text for the menu option */
  label: string;
  /** Optional color for the label text */
  labelColor?: ColorToken;
  /** Optional click handler for the menu option */
  onClick?: () => void;
};

export const MenuOption = ({ icon, iconColor, label, labelColor }: MenuOptionProps) => {
  const { colors } = useTheme();
  return (
    <Container>
      <FlashIcons name={icon} size={16} color={iconColor ?? colors.neutral[30]} fill="transparent" />
      <Typography variant="body4" color={labelColor ?? "neutral.30"} weight={600}>
        {label}
      </Typography>
    </Container>
  );
};
