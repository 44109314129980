import styled from "styled-components";

export const ExpenseAmountTrailingContainer = styled.div`
  max-width: 200px;
  display: flex;
  flex-direction: row;
  overflow: clip;
  white-space: nowrap;
  gap: ${({ theme }) => theme.spacings.xs2};
  align-items: center;
`;
