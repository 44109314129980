import { IconsProps, ShapeIcon, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { Container } from "./styled";

export type HighlightCardProps = {
  title: string;
  description: string;
  iconName: IconsProps["name"];
};

export function HighlightCard({ iconName, title, description }: HighlightCardProps) {
  return (
    <Container>
      <ShapeIcon name={iconName} variant={"default"} size={40} stroke="default" />
      <>
        <Typography variant="headline9" color="neutral.20">
          {title}
        </Typography>

        <Typography variant="body4" color="neutral.40">
          {description}
        </Typography>
      </>
    </Container>
  );
}
