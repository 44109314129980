import { EventSourcePolyfill } from "event-source-polyfill";
import { getAccessToken } from "@flash-tecnologia/hros-web-utility";

import { Attachment } from "$serverTypes";
import EnvService from "../EnvService";
import { ReceiptApiException } from "./exceptions/receipt-api-exception";
import { services } from "$frontend/utils/services";

const baseUrl = services.bff[EnvService.BUILD_ENV];

export const ReceiptApiClient = {
  uploadFile: async function (file: File, companyId: string, shouldProcessOcr = false): Promise<Attachment> {
    const url = new URL(`${baseUrl}/api/receipt/upload`);
    url.searchParams.append("shouldProcessOcr", shouldProcessOcr.toString());

    const res = await fetch(url.toString(), {
      method: "POST",
      body: file,
      headers: {
        "X-Flash-Auth": `Bearer ${await getAccessToken()}`,
        "company-id": companyId,
      },
    });
    if (res.status !== 200) {
      throw await ReceiptApiException.fromResponse(res);
    }

    return await res.json();
  },

  getFile: async function (attachment: Attachment, companyId: string) {
    const params = new URLSearchParams({
      ...attachment,
    });
    const res = await fetch(`${baseUrl}/api/receipt/data?${params.toString()}`, {
      method: "GET",
      headers: {
        "X-Flash-Auth": `Bearer ${await getAccessToken()}`,
        "company-id": companyId,
      },
    });
    if (res.status !== 200) {
      throw await ReceiptApiException.fromResponse(res);
    }

    return await res.blob();
  },

  checkReceiptOcrStatus: async function (
    companyId: string,
    customJobId: string,
    onMessage: (data: { status: string; [key: string]: any }) => void,
    onError?: (error: Error) => void,
  ) {
    if (!customJobId) {
      const error = new ReceiptApiException(
        400,
        "INVALID_REFERENCE_ID",
        "Reference ID is required to check OCR status.",
      );
      if (onError) return onError(error);
      throw error;
    }

    const accessToken = await getAccessToken();
    const url = `${baseUrl}/api/receipt/check-ocr-status/${customJobId}`;

    const eventSource = new EventSourcePolyfill(url, {
      headers: {
        "X-Flash-Auth": `Bearer ${accessToken}`,
        "company-id": companyId,
      },
      heartbeatTimeout: 60000,
    });

    eventSource.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        onMessage(data);

        if (data.status === "completed" || data.status === "failed") {
          eventSource.close();
        }
      } catch (error) {
        const parseError = new ReceiptApiException(500, "SSE_DATA_PARSE_ERROR", "Error parsing SSE data.");
        eventSource.close();
        if (onError) return onError(parseError);
        throw parseError;
      }
    };

    eventSource.onerror = () => {
      eventSource.close();
      const error = new ReceiptApiException(500, "SSE_CONNECTION_ERROR", "Failed to establish SSE connection.");
      if (onError) return onError(error);
      throw error;
    };

    return eventSource;
  },
};
