import { trpc } from "$client";
import { useCheckCompanyTier } from "@flash-tecnologia/expense-web-activation-utils";

const TEN_MINUTES = 1000 * 60 * 10;

export function useDefaultCostCenter() {
  const isPaidPlan = useCheckCompanyTier(["PAID"]);

  return trpc.costCenter.getDefault.useQuery(undefined, {
    staleTime: TEN_MINUTES,
    cacheTime: TEN_MINUTES,
    enabled: !!isPaidPlan,
  });
}
