import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { trpc } from "$client";
import { useStatementContext } from "$frontend/pages/Statement/context/StatementContext";
import { DateRangeField, PageContainer, PageHeader } from "$molecules";
import { KnowMore } from "./KnowMore";
import { StatementContent } from "./StatementContent";

export const StatementPage = () => {
  const { t } = useTranslation("translations", { keyPrefix: "pages.statement" });
  const { data: hasAny, isLoading } = trpc.corporateCard.me.hasAny.useQuery();

  const { methods } = useStatementContext();
  const { control } = methods;

  return (
    <>
      <PageHeader title={t("title")}>
        <Controller
          name="filters.dateRange"
          control={control}
          render={({ field }) => (
            <DateRangeField
              {...field}
              onChange={(value) => {
                field.onChange(value);
                methods.setValue("pagination.pageNumber", 1);
              }}
            />
          )}
        />
      </PageHeader>
      <PageContainer isLoading={isLoading}>{hasAny ? <StatementContent /> : <KnowMore />}</PageContainer>
    </>
  );
};
