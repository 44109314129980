import { useRegisterRoutes } from "@flash-tecnologia/hros-web-utility";
import { BrowserRouter, Routes as ReactRoutes, Route } from "react-router-dom";

import { AcquisitionFormPage } from "$frontend/pages/AcquisitionFormPage/AcquisitionFormPage";
import {
  AdminCategoriesPage,
  AdminReimbursementEditPage,
  AdminReimbursementsPage,
  AdminStatementPage,
  ExpenseDetails,
  MovementEditPage,
  ReimbursementCreatePage,
  ReimbursementEditPage,
  ReimbursementsPage,
  StatementPage,
  StatementPendingItemsPage,
} from "$pages";
import { external } from "@flash-hros/corporate-onboarding";
import { AcquisitionGuard } from "./AcquisitionGuard";
import { ProtectedRoutes } from "./ProtectedRoutes";
import { Routes } from "./routes";

const AppRouter = () => {
  const pathsWithoutHeader = [Routes.STATEMENT_PENDING_ITEMS, Routes.REIMBURSEMENT_CREATE];

  useRegisterRoutes(pathsWithoutHeader.map((path) => ({ path, hideHeader: true })));

  return (
    <BrowserRouter>
      <ReactRoutes>
        <Route element={<ProtectedRoutes />}>
          <Route element={<AcquisitionGuard />}>
            {/* Admin Routes for Categories Management */}
            <Route path={Routes.ADMIN_CATEGORIES} element={<AdminCategoriesPage />} />

            {/* Admin Routes for Reimbursements Management */}
            <Route path={Routes.ADMIN_REIMBURSEMENTS} element={<AdminReimbursementsPage />} />
            <Route path={Routes.ADMIN_REIMBURSEMENT} element={<ExpenseDetails origin="admin-reimbursement" />} />
            <Route path={Routes.ADMIN_REIMBURSEMENT_EDIT} element={<AdminReimbursementEditPage />} />

            {/* Admin Routes for Statement and Movement Management */}
            <Route path={Routes.ADMIN_STATEMENT} element={<AdminStatementPage />} />
            <Route path={Routes.ADMIN_MOVEMENT} element={<ExpenseDetails origin="admin-movement" />} />
            <Route path={Routes.ADMIN_MOVEMENT_EDIT} element={<MovementEditPage isAdmin={true} />} />
          </Route>

          {/* User Routes for Reimbursements list and visualize a Reimbursement */}
          <Route path={Routes.REIMBURSEMENTS} element={<ReimbursementsPage />} />
          <Route path={Routes.REIMBURSEMENT} element={<ExpenseDetails origin="user-reimbursement" />} />
          <Route path={Routes.REIMBURSEMENT_APPROVAL} element={<ExpenseDetails origin="approval-reimbursement" />} />
          <Route path={Routes.REIMBURSEMENT_EDIT} element={<ReimbursementEditPage />} />
          <Route path={Routes.REIMBURSEMENT_CREATE} element={<ReimbursementCreatePage />} />

          {/* User Routes for Statement list and visualize a Movement */}
          <Route path={Routes.STATEMENT} element={<StatementPage />} />
          <Route path={Routes.MOVEMENT} element={<ExpenseDetails origin="user-movement" />} />
          <Route path={Routes.MOVEMENT_APPROVAL} element={<ExpenseDetails origin="approval-movement" />} />
          <Route path={Routes.MOVEMENT_EDIT} element={<MovementEditPage isAdmin={false} />} />
          <Route path={Routes.STATEMENT_PENDING_ITEMS} element={<StatementPendingItemsPage />} />
        </Route>
        <Route
          path={Routes.ADMIN_ACQUISITION_FORM}
          element={
            <external.ExternalRoot>
              <AcquisitionFormPage />
            </external.ExternalRoot>
          }
        />
      </ReactRoutes>
    </BrowserRouter>
  );
};

export default AppRouter;
