import { useState } from "react";
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";

import { OcrResult, Attachment } from "$serverTypes";
import { ReceiptApiClient } from "$frontend/services/receipt-api-client";

type UseOcrProcessingProps = {
  onOcrUpdate?: (update: OcrResult) => void;
  updateAttachmentsSrc: (newBlobs: Record<string, string>) => void;
};

export const useOcrProcessing = ({ onOcrUpdate, updateAttachmentsSrc }: UseOcrProcessingProps) => {
  const { selectedCompany } = useSelectedCompany();
  const [isAnalyzing, setIsAnalyzing] = useState(false);

  async function processOcrForAttachment(attachment: Attachment, originalFile: File) {
    if (!attachment.ocrCustomJobId) return;
    setIsAnalyzing(true);

    await ReceiptApiClient.checkReceiptOcrStatus(
      selectedCompany.id,
      attachment.ocrCustomJobId,
      (data) => {
        if (data.status === "completed") {
          handleOcrCompletion(attachment, originalFile, data.message);
        }
      },
      () => {
        setIsAnalyzing(false);
      },
    );
  }

  function handleOcrCompletion(attachment: Attachment, originalFile: File, data: string) {
    setIsAnalyzing(false);
    const parsedData = JSON.parse(data);
    if (onOcrUpdate) {
      onOcrUpdate({
        referenceId: parsedData.referenceId,
        data: Object.fromEntries(
          Object.entries({
            date: parsedData.date,
            amount: parsedData.total,
            establishment: parsedData.address,
            ocrScore: parsedData.ocrScore,
            processingTimeMs: parsedData.processingTimeMs,
          }).filter(([_, v]) => v != null),
        ),
      });
    }

    updateAttachmentsSrc({
      [attachment.fileName]: URL.createObjectURL(originalFile),
    });
  }

  return { processOcrForAttachment, isAnalyzing };
};
