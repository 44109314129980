import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  gap: ${(props) => props.theme.spacings.xs3};
  justify-content: center;
  align-items: center;
  padding: ${(props) => props.theme.spacings.xs4};
`;

export const Description = styled(Typography).attrs({
  weight: 600,
})`
  color: ${(props) => props.theme.colors.neutral[50]};
  text-align: center;
`;
