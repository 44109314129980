import { useEffect, useMemo, useState } from "react";

import { ShapeIcon } from "$atoms";
import { trpc } from "$client";
import { useDebounce } from "$frontend/shared/hooks/debounce";
import { getRandomStr } from "$frontend/utils";
import { Autocomplete, type Option } from "$molecules";
import { PlaceAutocomplete } from "$serverTypes";
import { AutocompleteInputChangeReason } from "@mui/material";
import { useTranslation } from "react-i18next";

type EstablishmentSelectProps = {
  /* Allow user input to change the actual value*/
  allowFreeText?: boolean;
  /* Actual value being used in Autocomplete that will be submitted*/
  value?: PlaceAutocomplete;
  /* Callback for when actual value changes*/
  onValueChange: (value: PlaceAutocomplete | null, sessionToken: string) => void;
  /* User input*/
  inputValue: string;
  /* Set user input*/
  setInputValue: (value: string) => void;
  /* If the component has invalid input and can't be submitted*/
  error?: boolean;
};

function placeAutoCompleteToOption(placeAutocomplete: PlaceAutocomplete) {
  return {
    value: placeAutocomplete.placeId,
    label: placeAutocomplete.name,
  } satisfies Option;
}

export const EstablishmentSelect = ({
  value,
  onValueChange,
  error,
  allowFreeText,
  inputValue,
  setInputValue,
}: EstablishmentSelectProps) => {
  const { t } = useTranslation("translations", { keyPrefix: "molecules.establishmentSelect" });
  const sessionToken = useMemo(() => getRandomStr(12), []);
  // value to be debounced
  const [searchValue, setSearchValue] = useState("");
  // debounced from searchValue and used in trpc
  const [query, setQuery] = useState("");

  // sync input value and search value with actual value when value changes outside of autocomplete option selection
  useEffect(() => {
    setInputValue(value?.name || "");
    if (!value) setSearchValue("");
  }, [value, setInputValue]);

  const { data, isFetching } = trpc.places.searchPlace.useQuery(
    {
      query,
      sessionToken,
    },
    {
      initialData: { results: value ? [value] : [] },
      refetchOnMount: false,
      enabled: !!query && query.trim().length > 0,
    },
  );

  const options = useMemo(() => {
    if (isFetching || !searchValue) return [];
    const opts = data.results.map(placeAutoCompleteToOption);
    if (opts.length === 0 && value) {
      // no results returned by the trpc and value were set
      return [placeAutoCompleteToOption(value)];
    }

    return opts;
  }, [data.results, isFetching, value, searchValue]);

  useDebounce(() => setQuery(searchValue), [searchValue], 500);

  function getNoOptionDescription() {
    if (query == "") {
      return t("typeEstablishmentNameOrAddress");
    }
    if (options.length == 0) {
      return t("noEstablishmentFound");
    }
    return "";
  }

  function onSelectChange(value: Option | null): void {
    const placeAutocompleteSelected = data.results.find(({ placeId }) => placeId == value?.value) ?? null;

    setInputValue(placeAutocompleteSelected?.name ?? "");

    onValueChange(placeAutocompleteSelected, sessionToken);
  }

  function onInputValueChange(
    _: React.SyntheticEvent,
    inputValueAutocomplete: string,
    reason: AutocompleteInputChangeReason,
  ) {
    switch (reason) {
      case "reset":
        if (inputValueAutocomplete === value?.name && (_?.type === "click" || _?.type === "keydown")) {
          /* for some reason this is triggered when the user clicks or presses the option with the current value
           but inputValue is different from inputValueAutocomplete, so input has to be set */
          setInputValue(inputValueAutocomplete);
        }
        break;
      case "clear":
        setInputValue(inputValueAutocomplete);
        onSelectChange(null);
        setSearchValue("");

        break;
      case "input":
        setInputValue(inputValueAutocomplete);
        setSearchValue(inputValueAutocomplete);
        // sync value to be submitted with every user input change
        if (allowFreeText) {
          onValueChange({ name: inputValueAutocomplete, placeId: "" }, sessionToken);
        }
        break;
    }
  }

  return (
    <Autocomplete
      label={t("establishment")}
      loadingText={t("loading")}
      error={error}
      isLoading={isFetching || searchValue !== query}
      noOptionContent={{ title: getNoOptionDescription() }}
      renderIcon={(_) => <ShapeIcon icon="IconMapPin" size="extra_small" />}
      value={value ? placeAutoCompleteToOption(value) : null}
      options={options}
      onSelectChange={onSelectChange}
      filterOptions={() => options}
      onInputChange={onInputValueChange}
      inputValue={inputValue}
    />
  );
};
