import {
  Button,
  Icons,
  IconTypes,
  LinkButton,
  PillButton,
  ShapeIconOptions,
  Tooltip,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"; // TODO passar pelo styled ou criar componente
import { useState } from "react";
import { useForm } from "react-hook-form";
import { TFunction, useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useTheme } from "styled-components";

import { Loader } from "$atoms";
import { trpc } from "$client";
import { ExpenseFormDto } from "$frontend/pages";
import { Routes } from "$frontend/routes";
import { useDisplayToast } from "$frontend/utils";
import { FocusedContainer, FocusedFooter, FocusedHeader, ReimbursementDetails } from "$molecules";
import { BasePage, ConfirmationModalDeleteExpense, ConfirmationModalEditExpense } from "$organisms";
import { Expense, ExpenseStatus, ExpenseType } from "$serverTypes";
import { ButtonsContainer } from "./styled";

export type Features = {
  /** Icon color when form is toggled */
  toggledIconColor?: ShapeIconOptions;
  /** If true, show edit and delete buttons for this expense status  */
  showEditAndDeleteButtons?: boolean;
  /** Alert box properties  */
  alertBox?: {
    color: string;
    iconVariant: ShapeIconOptions;
    icon: IconTypes;
    title: string;
    subtitle: string;
    bottomSubtitle?: string;
  };
};

function useFeatures(expense: Expense | undefined, t: TFunction<"D", "A">): Features {
  const { colors } = useTheme();
  if (!expense) return {};
  const statusLabel = t(`expenseStatus.${expense.status}`);
  switch (expense.status) {
    case undefined:
    case ExpenseStatus.DRAFT:
    case ExpenseStatus.PENDING_ACCOUNTING:
      return { showEditAndDeleteButtons: false };
    case ExpenseStatus.PENDING_APPROVAL:
      return { showEditAndDeleteButtons: true };
    case ExpenseStatus.REQUIRE_CHANGES:
      return {
        showEditAndDeleteButtons: true,
        alertBox: {
          title: t("reviewedBy", { name: expense.accountingBy }),
          iconVariant: "error",
          icon: "IconX",
          color: colors.feedback.error[70],
          subtitle: t("reviewReason", { accountingComments: expense.accountingComments }),
        },
      };
    case ExpenseStatus.REJECTED:
      return {
        alertBox: {
          title: t("rejectedBy", { name: expense.accountingBy }),
          iconVariant: "negative",
          icon: "IconX",
          color: colors.feedback.negative[70],
          subtitle: t("rejectionReason", { accountingComments: expense.accountingComments }),
        },
      };
    case ExpenseStatus.FINISHED:
      return {};
  }
}

export const ReimbursementPage = () => {
  const { expenseId } = useParams();

  const {
    data: expense,
    isFetching: isFetchingExpense,
    refetch,
  } = trpc.expense.getExpenseById.useQuery(expenseId ?? "");

  const { mutateAsync: updateExpenseStatus, isLoading: isSendingExpenseToAccount } =
    trpc.expense.updateSingleStatus.useMutation();

  const { mutateAsync: deleteExpense, isLoading: isDeleting } = trpc.expense.deleteExpense.useMutation();

  const { mutateAsync: patchExpense, isLoading: isLoadingPatchExpense } = trpc.expense.patchExpense.useMutation();

  const [formToggle, setFormToggle] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);

  const navigate = useNavigate();
  const { t } = useTranslation("translations", { keyPrefix: "pages.visualizeReimbursement" });
  const { t: tDetails } = useTranslation("translations", { keyPrefix: "molecules.reimbursementDetails" });

  const { displayToast } = useDisplayToast();
  const features = useFeatures(expense, tDetails);

  const isLoading = isFetchingExpense || isLoadingPatchExpense || isSendingExpenseToAccount;
  const isExpenseRequireChanges = expense?.status === ExpenseStatus.REQUIRE_CHANGES;

  const currentExpenseFormData = expense && {
    attachments: expense.attachments ?? [],
    category: expense.category ?? { nanoId: "", description: "" },
    comments: expense.comments ?? "",
    amount: expense.amount,
    date: new Date(expense.date),
  };
  const methods = useForm<ExpenseFormDto>({
    defaultValues: currentExpenseFormData,
    values: currentExpenseFormData,
  });
  const unchangedForm = !methods.formState.isDirty;

  async function onSubmit(formData: ExpenseFormDto, shouldSendToAccount: boolean) {
    if ((unchangedForm && !shouldSendToAccount) || !expense) {
      return;
    }

    const fieldsToPatch = {
      attachments: formData.attachments,
      comments: formData.comments,
      category: formData.category,
    };

    try {
      await patchExpense([expense.id, fieldsToPatch]);
      if (shouldSendToAccount) {
        await updateExpenseStatus({
          expenseId: expense.id,
          targetStatus: ExpenseStatus.PENDING_ACCOUNTING,
        });
      }
      displayToast({
        title: t(shouldSendToAccount ? "toasts.successSendingToAccount.title" : "toasts.successEdit.title"),
      });

      setFormToggle(false);
      refetch();
    } catch (e) {
      displayToast({
        type: "error",
        title: t(shouldSendToAccount ? "toasts.failedSendingToAccount.title" : "toasts.failedEdit.title"),
        description: t(
          shouldSendToAccount ? "toasts.failedSendingToAccount.description" : "toasts.failedEdit.description",
        ),
      });
    }
  }

  async function onDelete(): Promise<void> {
    if (!openDeleteModal) {
      return;
    }
    if (expense) {
      try {
        await deleteExpense([expense?.id]);
        displayToast({
          title: t("toasts.deleteSuccess.title"),
        });
      } catch (e) {
        displayToast({
          type: "error",
          title: t("toasts.deleteError.title"),
          description: t("toasts.deleteError.description"),
        });
        setOpenDeleteModal(false);
        throw e;
      }
    }
    setOpenDeleteModal(false);
    navigate(Routes.REIMBURSEMENTS);
  }

  function handleEdit(expense?: Expense) {
    if (expense && expense.status === ExpenseStatus.PENDING_APPROVAL) setOpenEditModal(true);
    else navigateToEditPage(expense);
  }

  function navigateToEditPage(expense?: Expense) {
    if (expense) navigate(Routes.REIMBURSEMENT_EDIT.replace(":expenseId", expense?.id ?? ""));
    setOpenEditModal(false);
  }

  function onInvalidFields() {
    displayToast({
      type: "warning",
      title: t("toasts.requiredField.title"),
      description: t("toasts.requiredField.description"),
    });
  }

  const pageContent = (
    <>
      {expense ? (
        <ReimbursementDetails
          features={features}
          expense={expense}
          isLoading={isLoading}
          methods={methods}
          formToggle={formToggle}
        />
      ) : (
        <Loader show={isLoading} />
      )}
    </>
  );

  return (
    <>
      {formToggle ? (
        <FocusedContainer
          header={
            <FocusedHeader
              start={[
                <LinkButton variant="neutral" key={1} onClick={() => navigate(Routes.REIMBURSEMENTS)}>
                  {t("back")}
                </LinkButton>,
              ]}
              middle={[
                <Typography variant="headline9" weight={700} color="neutral.30">
                  {t("titleEdit")}
                </Typography>,
              ]}
            />
          }
          footer={
            <FocusedFooter
              start={[
                <LinkButton variant="neutral" key={2} onClick={() => setFormToggle(false)}>
                  {t("cancel")}
                </LinkButton>,
              ]}
              end={[
                <Button
                  disabled={unchangedForm}
                  loading={isLoading}
                  variant="primary"
                  size="medium"
                  key={2}
                  onClick={methods.handleSubmit(
                    (formData) => onSubmit(formData, isExpenseRequireChanges),
                    onInvalidFields,
                  )}
                >
                  {t(isExpenseRequireChanges ? "submit.sendExpenseToAccount" : "submit.save")}
                  <Icons name="IconCheck" />
                </Button>,
              ]}
            />
          }
        >
          {pageContent}
        </FocusedContainer>
      ) : (
        <BasePage
          header={{
            title: t("title"),
            action: features.showEditAndDeleteButtons ? (
              <ButtonsContainer>
                <Tooltip title={t("edit")}>
                  <div>
                    <PillButton
                      icon="IconPencil"
                      size="small"
                      variant="default"
                      onClick={() => handleEdit(expense)}
                      disabled={isLoading}
                    />
                  </div>
                </Tooltip>
                <Tooltip title={t("delete")}>
                  <div>
                    <PillButton
                      icon="IconTrash"
                      size="small"
                      variant="default"
                      onClick={() => setOpenDeleteModal(true)}
                      disabled={isLoading}
                    />
                  </div>
                </Tooltip>
              </ButtonsContainer>
            ) : undefined,
            breadcrumbItems: [
              {
                to: Routes.REIMBURSEMENTS,
                label: t("breadcrumbs.previous"),
              },
              {
                to: "",
                label: t("breadcrumbs.current"),
              },
            ],
          }}
          footer={undefined}
        >
          {pageContent}
        </BasePage>
      )}
      <ConfirmationModalDeleteExpense
        isOpen={openDeleteModal}
        expensesCount={1}
        onActionClick={onDelete}
        isLoading={isDeleting}
        onCloseClick={() => setOpenDeleteModal(false)}
      />
      <ConfirmationModalEditExpense
        isOpen={openEditModal}
        onCloseClick={() => setOpenEditModal(false)}
        onActionClick={() => navigateToEditPage(expense)}
        isLoading={isDeleting}
        type={ExpenseType.REIMBURSEMENT}
      />
    </>
  );
};
