import styled from "styled-components";

import { Icons, Typography } from "$atoms";
import { ShapeIcon } from "@flash-tecnologia/hros-web-ui-v2";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: ${(props) => props.theme.spacings.xs4};
  border: 1px solid ${(props) => props.theme.colors.neutral[90]};
`;

export const Header = styled.div(({ theme }) => ({
  display: "flex",
  height: "48px",
  padding: `${theme.spacings.xs3} ${theme.spacings.xs1}`,
  alignItems: "center",
  gap: `${theme.spacings.xs4}`,
  alignSelf: "stretch",
  justifyContent: "space-between",

  borderRadius: `${theme.spacings.xs4} ${theme.spacings.xs4} 0px 0px`,
  borderBottom: `1px solid ${theme.colors.neutral[90]}`,
}));

export const HeaderIcon = styled(Icons).attrs(({ theme }) => ({
  name: "IconWallet",
  size: 16,
  stroke: theme.colors.neutral[60],
  color: theme.colors.neutral[60],
}))``;

export const HeaderText = styled(Typography).attrs({
  variant: "headline10",
  weight: 700,
})`
  color: ${(props) => props.theme.colors.neutral[50]};
`;

export const HeaderTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${(props) => props.theme.spacings.xs4};
`;

export const Content = styled.div`
  display: flex;
  padding: ${(props) => props.theme.spacings.xs1} ${(props) => props.theme.spacings.xs1} 0px
    ${(props) => props.theme.spacings.xs1};
  align-items: center;
  gap: ${(props) => props.theme.spacings.xs2};
  align-self: stretch;
`;

export const Cards = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
`;

export const ContentRow = styled.div`
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacings.xs3};
  align-self: stretch;
`;

export const ContentFirstColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContentSecondColumn = styled.div`
  display: flex;
  padding-bottom: ${(props) => props.theme.spacings.xs1};
  flex-direction: column;
  align-items: flex-start;
  gap: ${(props) => props.theme.spacings.xs5};
  flex: 1 0 0;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: ${(props) => props.theme.spacings.xs4};
  align-self: stretch;
  border-radius: ${(props) => props.theme.spacings.xs4};
`;

export const CardContent = styled.div`
  display: flex;
  padding-top: ${(props) => props.theme.spacings.xs4};
  flex-direction: column;
  align-items: flex-start;
  gap: ${(props) => props.theme.spacings.xs5};
  align-self: stretch;
  border-top: 1px solid ${(props) => props.theme.colors.neutral[90]};
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${(props) => props.theme.spacings.xs3};
  width: 100%;
`;

export const CalendarIcon = styled(ShapeIcon).attrs({
  name: "IconCalendarEvent",
  size: 24,
  variant: "neutral",
  stroke: "neutral",
})``;

export const CardHeaderText = styled(Typography).attrs({
  variant: "body4",
  weight: 600,
})`
  color: ${(props) => props.theme.colors.neutral[40]};
`;

export const CardHeaderBigNumber = styled(Typography).attrs({
  variant: "headline9",
  weight: 700,
})`
  color: ${(props) => props.theme.colors.neutral[30]};
`;

export const CardText = styled(Typography).attrs(({ theme }) => ({
  variant: "caption",
  weight: 600,
  color: theme.colors.neutral[40],
}))``;
