import styled from "styled-components";

export const ReimbursementsPageContainer = styled.div`
  width: 100%;
  height: auto;
`;

export const SummaryCards = styled.div`
  margin-bottom: ${(props) => props.theme.spacings.s};
`;

export const HeaderItemsContainers = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${(props) => props.theme.spacings.xs2};
`;

export const ButtonContainer = styled.div`
  display: flex;
`;
