import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: ${({ theme }) => `${theme.borders.width.xs2} solid ${theme.colors.neutral[90]}`};
  border-radius: ${({ theme }) => theme.borders.radius.l};
  padding: ${({ theme }) => theme.spacings.xs};
  gap: ${({ theme }) => theme.spacings.xs5};
  text-align: center;

  svg {
    margin-bottom: ${({ theme }) => theme.spacings.xs4};
  }
`;
