import dayjs from "dayjs";

import { Typography } from "$atoms";
import { getLastMinuteAndSecondOfDay } from "$frontend/utils/formatters";
import { DateRangePicker, ShapeIcon } from "@flash-tecnologia/hros-web-ui-v2";
import { useMemo, useState } from "react";
import { DateRange } from "react-day-picker";
import { useTranslation } from "react-i18next";
import { DatePeriodState } from "../../molecules/DatePeriodMenu/DatePeriodMenu";
import { Modal } from "../../molecules/Modal/Modal";
import { IconContainer, MessageContainer, ModalContainer } from "./styled";

type DateRangeModalProps = {
  initialDateRange: DatePeriodState;
  isOpen: boolean;
  onApply(range: DatePeriodState): void;
  onClose(): void;
};

export function DateRangeModal(props: DateRangeModalProps) {
  const { t } = useTranslation("translations", { keyPrefix: "organisms.modals.dateRange" });

  const initialRange = useMemo(() => {
    if (props.initialDateRange.selected === "CUSTOM") {
      return {
        from: props.initialDateRange.startDate,
        to: props.initialDateRange.endDate,
      };
    }
    return { from: undefined, to: undefined };
  }, [props.initialDateRange]);

  const [dateRange, setDateRange] = useState<DateRange>(initialRange);

  function onApply(range: DateRange) {
    if (range.from && range.to) {
      props.onApply({ selected: "CUSTOM", startDate: range.from, endDate: range.to });
      props.onClose();
    }
  }

  function onDateRangeChange(range?: DateRange) {
    if (range) {
      setDateRange({ from: range.from, to: range.to && getLastMinuteAndSecondOfDay(range.to) });
    }
  }
  const isFormValid = useMemo(() => !!dateRange.from && !!dateRange.to, [dateRange.from, dateRange.to]);

  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
      title={t("title")}
      footerProps={{
        onConfirm: () => onApply(dateRange),
        cancelText: t("buttons.cancel"),
        confirmText: t("buttons.confirm"),
        confirmDisabled: !isFormValid,
      }}
    >
      <ModalContainer>
        <DateRangePicker
          initialRange={initialRange}
          disableFooter
          onRangeChange={onDateRangeChange}
          styles={{
            table: {
              width: "400px",
              maxWidth: "400px",
            },
            root: {
              ["--rdp-cell-size" as string]: "55px",
            } as React.CSSProperties,
          }}
          toDate={dayjs().toDate()}
          onSubmit={() => null}
        />
        <MessageContainer>
          <IconContainer>
            <ShapeIcon name={"IconInfoCircle"} size={24} variant={"neutral"} />
          </IconContainer>
          <Typography color="neutral.30" variant="caption">
            {t("helpMessage")}
          </Typography>
        </MessageContainer>
      </ModalContainer>
    </Modal>
  );
}
