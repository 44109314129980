import { useCheckCompanyTier } from "@flash-tecnologia/expense-web-activation-utils";
import { Dot, LinkButton, ShapeIcon, Tooltip, Typography } from "@flash-tecnologia/hros-web-ui-v2"; //TODO passar pelo styled ou criar componente
import { useTranslation } from "react-i18next";

import Flex from "$frontend/components/atoms/Flex/Flex";
import { ConfirmationModalReclassifyTransaction } from "$frontend/components/organisms";
import { getValueWithCurrency } from "$frontend/utils";
import {
  ExpenseAmountTrailingContainer,
  ExpenseStatusStepperCard,
  InfoCard,
  InfoCardStatus,
  ReceiptDropzone,
} from "$molecules";
import { Expense, ExpenseTransactionCardType, ExpenseType } from "$serverTypes";
import { useState } from "react";
import { useBalanceChangeEnabled } from "../data/useBalanceChangeEnabled";
import { useDetailBox } from "../hooks/useDetailBox";
import {
  Card,
  CardTile,
  CardTitle,
  Container,
  Content,
  FormContent,
  ReadonlyTextInput,
  StepperCard,
  WarningCard,
  WarningCardSubtitle,
} from "./styled";

type ExpenseDetailsProps = {
  /** Expense object with its details to be displayed */
  expense: Expense;
  /** Loading status */
  isLoading: boolean;
};

export const ReimbursementDetails = ({ isLoading, expense }: ExpenseDetailsProps) => {
  const { t } = useTranslation("translations", { keyPrefix: "molecules.expenseDetails" });
  const { t: tMovement } = useTranslation("translations", { keyPrefix: "molecules.movementForm" });
  const alertBox = useDetailBox(expense);
  const isPaidPlan = useCheckCompanyTier(["PAID"]);

  const [isReclassifyModalOpen, setIsReclassifyModalOpen] = useState(false);
  const balanceChangeEnabled = useBalanceChangeEnabled(expense.status);

  return (
    <Container>
      <Content>
        <ReceiptDropzone
          isLoading={isLoading}
          onChange={() => {
            return null;
          }}
          value={expense.attachments || []}
          disabled
        />

        <FormContent>
          <Card>
            <CardTile bottomBorder={true}>
              <InfoCard
                leading={<ShapeIcon variant={"neutral"} stroke={"neutral"} name="IconReceipt" size={40} />}
                title={
                  <Typography variant="caption" color="neutral.40">
                    {t("cardTitle")}
                  </Typography>
                }
                subTitle={
                  <Tooltip title={t("cardTitle")}>
                    <Tooltip title={expense.establishment?.description}>
                      <div>
                        <CardTitle weight={700} variant="headline8" color="neutral.30">
                          {expense.establishment?.name || expense.transaction?.description}
                        </CardTitle>
                      </div>
                    </Tooltip>
                  </Tooltip>
                }
                trailing={
                  <ExpenseAmountTrailingContainer>
                    <InfoCard
                      alignItems="flex-end"
                      title={
                        <Typography variant="caption" color="neutral.40">
                          {t("expenseAmount")}
                        </Typography>
                      }
                      subTitle={
                        <Typography weight={700} variant="headline8" color="neutral.30">
                          {getValueWithCurrency({
                            value: expense?.amount ?? 0,
                            currencyPrefix: expense?.currency,
                          })}
                        </Typography>
                      }
                    />
                  </ExpenseAmountTrailingContainer>
                }
              />
              <InfoCardStatus status={expense?.status} />
            </CardTile>
            <Flex padding="xs2" direction="column" gap="xs2">
              {alertBox && (
                <WarningCard color={alertBox.color}>
                  <InfoCard
                    leading={
                      <ShapeIcon
                        name={alertBox.icon}
                        variant={alertBox.iconVariant}
                        stroke={alertBox.iconVariant}
                        size={32}
                      />
                    }
                    title={
                      <Typography weight={700} variant="body4" color="neutral.30">
                        {alertBox.title}
                      </Typography>
                    }
                    subTitle={
                      <WarningCardSubtitle>
                        <Typography weight={400} variant="caption" color="neutral.30" tag="p">
                          {alertBox.subtitle}
                        </Typography>
                        {alertBox.bottomSubtitle && (
                          <Typography weight={700} variant="caption" color="neutral.30" tag="p">
                            {alertBox.bottomSubtitle}
                          </Typography>
                        )}
                      </WarningCardSubtitle>
                    }
                  />
                </WarningCard>
              )}
              <InfoCard
                leading={<ShapeIcon name="IconCategory" variant="neutral" stroke="neutral" size={32} />}
                title={
                  <Typography weight={600} variant="caption" color="neutral.40">
                    {t("fields.category")}
                  </Typography>
                }
                subTitle={
                  <Typography weight={600} variant="body4" color="neutral.20">
                    {expense.category?.description}
                  </Typography>
                }
              />
              <InfoCard
                leading={<ShapeIcon name="IconCalendarEvent" variant="neutral" stroke="neutral" size={32} />}
                title={
                  <Typography weight={600} variant="caption" color="neutral.40">
                    {t("fields.date")}
                  </Typography>
                }
                subTitle={
                  <Typography weight={600} variant="body4" color="neutral.20">
                    {t(expense.type === ExpenseType.REIMBURSEMENT ? "expenseDateValue" : "expenseDateTimeValue", {
                      date: new Date(expense.date).toLocaleDateString("pt-BR", {
                        day: "2-digit",
                        month: "long",
                        year: "numeric",
                      }),
                      time: new Date(expense.date).toLocaleTimeString("pt-BR", {
                        hour: "2-digit",
                        minute: "2-digit",
                      }),
                    })}
                  </Typography>
                }
              />
              {isPaidPlan && (
                <InfoCard
                  leading={<ShapeIcon name="IconCurrencyDollar" variant="neutral" stroke="neutral" size={32} />}
                  title={
                    <Typography weight={600} variant="caption" color="neutral.40">
                      {t("fields.costCenter")}
                    </Typography>
                  }
                  subTitle={
                    <Typography weight={600} variant="body4" color="neutral.20">
                      {expense.costCenter?.name || "-"}
                    </Typography>
                  }
                />
              )}

              <InfoCard
                leading={<ShapeIcon name="IconIdBadge2" variant="neutral" stroke="neutral" size={32} />}
                title={
                  <Typography weight={600} variant="caption" color="neutral.40">
                    {t("fields.id")}
                  </Typography>
                }
                subTitle={
                  <Typography weight={600} variant="body4" color="neutral.20">
                    {expense.referenceId}
                  </Typography>
                }
              />
              <InfoCard
                fullWidth
                leading={<ShapeIcon name="IconQuote" variant="neutral" stroke="neutral" size={32} />}
                title={
                  <Typography weight={600} variant="caption" color="neutral.40">
                    {t("fields.comments")}
                  </Typography>
                }
                subTitle={
                  <ReadonlyTextInput>
                    <Typography weight={600} variant="body4" color="neutral.20" style={{ width: "100%" }}>
                      {expense.comments}
                    </Typography>
                  </ReadonlyTextInput>
                }
              />
            </Flex>
          </Card>
          {expense.type === ExpenseType.CORPORATE_CARD && (
            <Card>
              <CardTile bottomBorder={balanceChangeEnabled}>
                <InfoCard
                  leading={<ShapeIcon name="IconCreditCard" variant="neutral" stroke="neutral" size={32} />}
                  title={
                    <Typography weight={600} variant="caption" color="neutral.40">
                      {tMovement("payment")}
                    </Typography>
                  }
                  subTitle={
                    <Flex direction="row" gap="xs4" align="center">
                      <Typography weight={600} variant="body4" color="neutral.20">
                        {tMovement("corporative")}
                      </Typography>
                      <Dot variant="gray" />
                      <Typography weight={600} variant="body4" color="neutral.20">
                        {expense &&
                          tMovement(`cardType.${expense.transaction?.cardType ?? ExpenseTransactionCardType.PLASTIC}`) +
                            (expense.transaction?.cardLastDigits ?? "")}
                      </Typography>
                    </Flex>
                  }
                />
              </CardTile>
              {balanceChangeEnabled && (
                <CardTile bottomBorder={true}>
                  <InfoCard
                    leading={<ShapeIcon name="IconRefreshAlert" variant="default" stroke="default" size={32} />}
                    title={
                      <Typography weight={600} variant="body4" color="neutral.20">
                        {tMovement("balanceChange.title")}
                      </Typography>
                    }
                    subTitle={
                      <Typography weight={600} variant="caption" color="neutral.40">
                        {tMovement("balanceChange.subTitle")}
                      </Typography>
                    }
                    trailing={
                      <LinkButton
                        variant="default"
                        onClick={() => {
                          return;
                        }}
                      >
                        {tMovement("balanceChange.submit")}
                      </LinkButton>
                    }
                  />
                </CardTile>
              )}
              <ConfirmationModalReclassifyTransaction
                isOpen={isReclassifyModalOpen}
                transaction={expense.transaction}
                onCloseClick={() => setIsReclassifyModalOpen(false)}
              />
            </Card>
          )}
          <StepperCard>
            <ExpenseStatusStepperCard status={expense.status} type={expense.type} />
          </StepperCard>
        </FormContent>
      </Content>
    </Container>
  );
};
