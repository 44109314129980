import dayjs from "dayjs";
import { useMemo, useState } from "react";

import { DateRangeModal } from "$frontend/components/organisms/DateRangeModal/DateRangeModal";
import { formatPeriod } from "$frontend/utils/formatters";
import { PillButton, Tooltip } from "@flash-tecnologia/hros-web-ui-v2";
import { Menu } from "../Menu/Menu";
import { Container, WarningBadge, WarningBadgeContainer } from "./styled";

export const lastDay = dayjs().endOf("day").toDate();

export const mappedDays = {
  LAST_NINETY: dayjs().subtract(90, "days").startOf("day"),
  LAST_THIRTY: dayjs().subtract(30, "days").startOf("day"),
  LAST_MONTH: dayjs().startOf("month").startOf("day"),
  LAST_FIFTEEN: dayjs().subtract(15, "days").startOf("day"),
  LAST_SEVEN_DAYS: dayjs().subtract(7, "days").startOf("day"),
};

export type DateType = "LAST_NINETY" | "LAST_THIRTY" | "LAST_MONTH" | "LAST_FIFTEEN" | "LAST_SEVEN_DAYS" | "CUSTOM";

export type DatePeriodState = {
  selected: DateType;
  startDate: Date;
  endDate: Date;
};

type DatePeriodMenuProps = {
  /** Selected date range value */
  value: DatePeriodState;
  /** Show alert badge on top right of the button  */
  showAlertBadge?: boolean;
  /** Alert badge tooltip (note that tooltip will only show if showAlertBadge is true) */
  alertBadgeTooltip?: string;
  /** Toggles loading state */
  isLoading?: boolean;
  handleDateChange: (type: DatePeriodState) => void;
};

export function DatePeriodMenu({
  value,
  showAlertBadge,
  alertBadgeTooltip,
  handleDateChange: handleApplyMenuItem,
}: DatePeriodMenuProps) {
  const menuOptions = useMemo(() => {
    return [
      {
        type: "LAST_NINETY",
        label: "Últimos 90 dias",
        description: formatPeriod(mappedDays.LAST_NINETY.toDate(), lastDay),
        selected: value.selected === "LAST_NINETY",
        onClick: () =>
          handleApplyMenuItem({
            selected: "LAST_NINETY",
            startDate: mappedDays.LAST_NINETY.toDate(),
            endDate: lastDay,
          }),
      },
      {
        type: "LAST_THIRTY",
        label: "Últimos 30 dias",
        description: formatPeriod(mappedDays.LAST_THIRTY.toDate(), lastDay),
        selected: value.selected === "LAST_THIRTY",
        onClick: () =>
          handleApplyMenuItem({
            selected: "LAST_THIRTY",
            startDate: mappedDays.LAST_THIRTY.toDate(),
            endDate: lastDay,
          }),
      },
      {
        type: "LAST_MONTH",
        label: "Este mês",
        description: formatPeriod(mappedDays.LAST_MONTH.toDate(), lastDay),
        selected: value.selected === "LAST_MONTH",
        onClick: () =>
          handleApplyMenuItem({
            selected: "LAST_MONTH",
            startDate: mappedDays.LAST_MONTH.toDate(),
            endDate: lastDay,
          }),
      },

      {
        type: "LAST_FIFTEEN",
        label: "Últimos 15 dias",
        description: formatPeriod(mappedDays.LAST_FIFTEEN.toDate(), lastDay),
        selected: value.selected === "LAST_FIFTEEN",
        onClick: () =>
          handleApplyMenuItem({
            selected: "LAST_FIFTEEN",
            startDate: mappedDays.LAST_FIFTEEN.toDate(),
            endDate: lastDay,
          }),
      },
      {
        type: "LAST_SEVEN_DAYS",
        label: "Últimos 7 dias",
        description: formatPeriod(mappedDays.LAST_SEVEN_DAYS.toDate(), lastDay),
        selected: value.selected === "LAST_SEVEN_DAYS",
        onClick: () =>
          handleApplyMenuItem({
            selected: "LAST_SEVEN_DAYS",
            startDate: mappedDays.LAST_SEVEN_DAYS.toDate(),
            endDate: lastDay,
          }),
      },
      {
        type: "CUSTOM",
        label: "Personalizado...",
        selected: value.selected === "CUSTOM",
        onClick: () => setIsModalOpen(true),
      },
    ];
  }, [value.selected, handleApplyMenuItem]);

  const label = useMemo(() => {
    if (value.selected !== "CUSTOM") {
      return menuOptions.find((option) => option.type === value.selected)?.label;
    }

    return formatPeriod(value.startDate, value.endDate);
  }, [value.selected, value.startDate, value.endDate, menuOptions]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <Container>
      <Menu options={menuOptions}>
        <PillButton variant="default" size="small" icon="IconCalendarTime" label={label} iconPosition="left" />
      </Menu>
      {showAlertBadge ? (
        <Tooltip title={alertBadgeTooltip}>
          <WarningBadgeContainer>
            <WarningBadge />
          </WarningBadgeContainer>
        </Tooltip>
      ) : undefined}
      <DateRangeModal
        initialDateRange={value}
        isOpen={isModalOpen}
        onApply={handleApplyMenuItem}
        onClose={() => setIsModalOpen(false)}
      />
    </Container>
  );
}
