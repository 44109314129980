import { AcronymContainer, AcronymText } from "./styled";

type AcronymProps = {
  /* Two letter word to display */
  acronym: string;
};

/**
 * Tiny round icon with 2 letters
 */
export const Acronym = (props: AcronymProps) => {
  return (
    <AcronymContainer>
      <AcronymText>{props.acronym.slice(0, 2).toUpperCase()}</AcronymText>
    </AcronymContainer>
  );
};
