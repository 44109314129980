import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { EnableReimbursementSection } from "../EnableReimbursementSection/EnableReimbursementSection";
import { ReimbursementHighlightCards } from "./ReimbursementHighlightCards";
import { Container } from "./styled";

export const ReimbursementEmptyStateSection = () => {
  return (
    <Container>
      <Typography variant="headline7" color="neutral.10">
        Reembolsos
      </Typography>
      <EnableReimbursementSection />
      <ReimbursementHighlightCards />
    </Container>
  );
};
