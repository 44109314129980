import { Button, Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { useTranslation } from "react-i18next";
import { FeatureHighlight } from "../FeatureHighlight/FeatureHighlight";
import {
  BodyContainer,
  BodyContainerSubtitle,
  BodyContainerTitle,
  Container,
  Content,
  FeatureHighlightContainer,
  HeaderContainer,
  Image,
  ImageContainer,
} from "./styled";

export function EnableReimbursementSection() {
  const { t } = useTranslation("translations", { keyPrefix: "pages.reimbursements.enableReimbursementSection" });

  const handleUsageGuideClick = () => {
    window.open("https://drive.google.com/file/d/1ZMUf_ph_S52Il9fW-OO1lP-elet1VL_O/view", "_blank");
  };

  return (
    <Container>
      <Content>
        <HeaderContainer>
          <Typography variant="headline8" color="neutral.30" weight={700}>
            {t("title")}
          </Typography>
          <Typography variant="body4" color="neutral.40" weight={400}>
            {t("subtitle")}
          </Typography>

          <FeatureHighlightContainer>
            <FeatureHighlight iconName="IconRotateClockwise2">{t("features.trackReimbursements")}</FeatureHighlight>
            <FeatureHighlight iconName="IconEyeCheck">{t("features.signalInconsistencies")}</FeatureHighlight>
            <FeatureHighlight iconName="IconReceipt2">{t("features.easyControl")}</FeatureHighlight>
          </FeatureHighlightContainer>
        </HeaderContainer>

        <BodyContainer>
          <BodyContainerTitle>
            <Icons name="IconCheckbox" size={16} stroke="neutral" />
            <Typography variant="headline10" color="neutral.30">
              {t("stepOne")}
            </Typography>
          </BodyContainerTitle>

          <BodyContainerSubtitle style={{ display: "flex", gap: "16px", flexWrap: "wrap" }}>
            <Typography variant="body4" color="neutral.30" weight={400}>
              {t("stepOneDescription")}
            </Typography>

            <Button variant="primary" size="small" onClick={handleUsageGuideClick}>
              <Icons size={16} name="IconClick" stroke="neutral" />
              <Typography variant="body4" color="neutral.100" weight={700}>
                {t("usageGuide")}
              </Typography>
            </Button>
          </BodyContainerSubtitle>
        </BodyContainer>
      </Content>

      <ImageContainer>
        <Image src="https://images.flashapp.com.br/expense/expense-lifecycle/reimbursements-card.png" />
      </ImageContainer>
    </Container>
  );
}
