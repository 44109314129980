import { Icons, SelectField, Skeleton, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { useTranslation } from "react-i18next";

import { trpc } from "$client";
import { CategoryStatus } from "$frontend/utils/enum";
import { Category } from "$serverTypes";
import { useTheme } from "styled-components";
import { ErrorContainer } from "./styled";

type CategorySelectProps = {
  /** category nano id*/
  value?: string;
  isLoading?: boolean;
  onChange: (category: Category) => void;
  disabled?: boolean;
  error?: boolean;
};

export const CategorySelect = (props: CategorySelectProps) => {
  const { colors } = useTheme();
  const { t } = useTranslation("translations", { keyPrefix: "molecules.categorySelect" });
  const { data, isFetching } = trpc.category.getCompanyCategories.useQuery(undefined, {
    initialData: [],
  });

  const activeCategories = data?.filter((category) => category.status === CategoryStatus.ACTIVE);
  const isLoading = isFetching || props.isLoading;

  if (isLoading) {
    return <Skeleton height={60} />;
  }

  return (
    <div>
      <SelectField
        error={props.error}
        noOptionsText=""
        id="category"
        disabled={props.disabled || isFetching}
        required={true}
        options={activeCategories?.map((category) => ({
          label: category.name,
          value: category.id,
        }))}
        onSelectChange={(_, option) => {
          const categorySelected = data.find((category) => category.id == option.value);
          if (categorySelected) {
            props.onChange(categorySelected);
          }
        }}
        value={props.value ?? ""}
        fullWidth={true}
        label={t("label")}
      />
      {props.error && (
        <ErrorContainer>
          <Icons size={16} name="IconAlertCircle" color={colors.feedback.error[50]} />
          <Typography color="neutral.40" weight={600} variant="caption">
            {t("errorDescription")}
          </Typography>
        </ErrorContainer>
      )}
    </div>
  );
};
