import { useTranslation } from "react-i18next";

import { ShapeIcon } from "$atoms";
import { DangerActionModal, DangerActionModalProps } from "$molecules";
import { Expense } from "$serverTypes";
import { Alignment } from "$utils";
import { useSendNotification } from "./data/useSendNotification";

type PropsType = {
  /** Expenses to notify */
  expensesToNotify: Expense[];

  /** called when modal is closed */
  onClose: () => void;

  /** Called when notification is finished */
  onNotifyFinished?: () => void;
};

export const ExpensesNotifyModal = (props: PropsType) => {
  const { t } = useTranslation("translations", { keyPrefix: "organisms.expensesNotifyModal" });

  const { sendNotification, isLoading } = useSendNotification();

  async function onActionClick(expensesToNotify: Expense[]) {
    await sendNotification(expensesToNotify, () => {
      props.onNotifyFinished?.();
      props.onClose();
    });
  }

  if (!props.expensesToNotify || props.expensesToNotify.length === 0) {
    return null;
  }

  const modalProps: DangerActionModalProps = {
    open: true,
    isLoading,
    headerIcon: <ShapeIcon size="large" variant="error" icon="IconAlertCircle" />,
    headerIconLabel: t("headerIconLabel"),
    headerTitle: t("headerTitle"),
    headerSubtitle: t("headerSubtitle"),
    labelButtonAction: t("buttonLabel"),
    showCancelButton: true,
    iconPosition: Alignment.left,
    onActionClick: () => onActionClick(props.expensesToNotify),
    onCloseClick: props.onClose,
  };

  return <DangerActionModal {...modalProps} />;
};
