import { PaginationState } from "@flash-tecnologia/hros-web-ui-v2/dist/components/Table/components/Pagination";
import { createContext, useContext } from "react";
import { useForm, UseFormReturn } from "react-hook-form";

import { DatePeriodState, lastDay, mappedDays } from "$frontend/components/molecules/DatePeriodMenu/DatePeriodMenu";
import { ExpenseStatus, TransactionStatus } from "$serverTypes";

export type StatementState = {
  filters: {
    status: ExpenseStatus[];
    transactionStatus: TransactionStatus[];
    categoryNanoIds: string[];
    datePeriod: DatePeriodState;
    valueRange?: { max: number; min: number };
  };
  pagination: PaginationState;
};

const defaultValues: StatementState = {
  filters: {
    status: [],
    transactionStatus: [],
    categoryNanoIds: [],
    datePeriod: {
      selected: "LAST_NINETY",
      startDate: mappedDays.LAST_NINETY.toDate(),
      endDate: lastDay,
    },
    valueRange: undefined,
  },
  pagination: { pageNumber: 1, pageSize: 10 },
};

export type ISessionProviderProps = {
  children: React.ReactNode;
};

type StatementContextProps = {
  methods: UseFormReturn<StatementState>;
};

const StatementContext = createContext<StatementContextProps | undefined>(undefined);

export const StatementProvider = ({ children }: ISessionProviderProps) => {
  const methods = useForm<StatementState>({ defaultValues });

  return <StatementContext.Provider value={{ methods }}>{children}</StatementContext.Provider>;
};

export const useStatementContext = () => {
  const context = useContext(StatementContext);
  if (!context) {
    throw new Error("useStatementContext must be used within an StatementProvider");
  }
  return context;
};
