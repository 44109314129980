import { useCheckCompanyTier } from "@flash-tecnologia/expense-web-activation-utils";
import { DatePicker, SelectField, ShapeIcon, TextField, Typography } from "@flash-tecnologia/hros-web-ui-v2"; //TODO passar pelo styled ou criar componente
import { useState } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";

import { TextArea } from "$atoms";
import { FeatureFlagService } from "$frontend/services";
import { getValueWithCurrency } from "$frontend/utils/formatters";
import {
  CategorySelect,
  EstablishmentSelect,
  ExpenseAmountTrailingContainer,
  InfoCard,
  InfoCardStatus,
  ReceiptDropzone,
} from "$molecules";
import { ExpenseFormDto } from "$pages";
import { ExpenseStatus, OcrResult } from "$serverTypes";
import { CostCenter } from "server/src/services/expense-lifecycle-service/expense-lifecycle-types";
import { CostCenterSelect } from "../CostCenterSelect";
import { Card, CardTile, Container, Form, FormCurrencyAmountContainer } from "./styled";

type ReimbursementFormProps = {
  methods: UseFormReturn<ExpenseFormDto, Record<string, unknown>>;
  expenseStatus?: ExpenseStatus;
  defaultCostCenter?: CostCenter | null;
  setOcrData?: (data: OcrResult | null) => void;
};

export const ReimbursementForm = ({
  methods,
  expenseStatus,
  defaultCostCenter,
  setOcrData,
}: ReimbursementFormProps) => {
  const { t } = useTranslation("translations", {
    keyPrefix: "molecules.reimbursementForm",
  });

  const ocrIsActive = FeatureFlagService.getFlag("ocr");
  const isPaidPlan = useCheckCompanyTier(["PAID"]);

  const canUseOcr = ocrIsActive && !!isPaidPlan;

  const [allowEstablishmentFreeText, setAllowEstablishmentFreeText] = useState(false);
  const [establishmentInputValue, setEstablishmentInputValue] = useState("");
  const [centerCostInputValue, setCenterCostInputValue] = useState("");

  const isEstablishmentValid = (userInputValue: string, actualValue: string) => {
    return !!userInputValue && userInputValue.trim().length > 0 && userInputValue === actualValue;
  };

  const isCostCenterValid = (userInputValue: string, actualValue: string) => {
    return userInputValue === actualValue;
  };

  const shouldDisableDate = (date: Date) => {
    const today = new Date();
    today.setHours(23, 59, 59, 999);
    return date > today;
  };

  const handleOcrUpdate = (ocrData: OcrResult) => {
    if (setOcrData) {
      setOcrData(ocrData);
    }

    if (ocrData.data.amount) {
      methods.setValue("amount", ocrData.data.amount * 100);
    }

    if (ocrData.data.date) {
      methods.setValue("date", new Date(ocrData.data.date));
    }

    if (ocrData.data.establishment) {
      methods.setValue("establishment", {
        sessionToken: "",
        placeAutocomplete: { name: ocrData.data.establishment, placeId: "", description: ocrData.data.establishment },
      });
      setAllowEstablishmentFreeText(true);
      setEstablishmentInputValue(ocrData.data.establishment);
    }
  };

  return (
    <Container>
      <Controller
        control={methods.control}
        name="attachments"
        rules={{ required: true }}
        render={({ field }) => (
          <ReceiptDropzone
            onChange={field.onChange}
            onOcrUpdate={handleOcrUpdate}
            value={field.value ?? []}
            applyOcr={canUseOcr}
          />
        )}
      />
      <Card>
        <CardTile bottomBorder={true}>
          <InfoCard
            fullWidth={true}
            leading={<ShapeIcon variant={"neutral"} stroke={"neutral"} name="IconReceipt" size={40} />}
            title={
              <Typography variant="caption" color="neutral.40">
                {methods.watch().category?.description ?? t("category")}
              </Typography>
            }
            subTitle={
              <Typography weight={700} variant="headline8" color="neutral.30">
                {methods.watch().establishment?.placeAutocomplete?.name ?? t("establishment")}
              </Typography>
            }
            trailing={
              <ExpenseAmountTrailingContainer>
                <InfoCard
                  fullWidth={true}
                  alignItems="flex-end"
                  title={
                    <Typography variant="caption" color="neutral.40">
                      {t("expenseAmount")}
                    </Typography>
                  }
                  subTitle={
                    <Typography weight={700} variant="headline8" color="neutral.30">
                      {getValueWithCurrency({ value: methods.watch().amount }) ?? t("amount")}
                    </Typography>
                  }
                />
              </ExpenseAmountTrailingContainer>
            }
          />
          <InfoCardStatus status={expenseStatus ?? ExpenseStatus.DRAFT} />
        </CardTile>
        <Form>
          <FormCurrencyAmountContainer>
            <SelectField
              id="currency"
              disabled={true}
              value="BRL"
              options={[
                {
                  label: "R$ (BRL)",
                  value: "BRL",
                },
              ]}
              fullWidth={true}
              label={t("fields.currency")}
            />
            <Controller
              control={methods.control}
              name="amount"
              rules={{ required: true }}
              render={({ field, fieldState }) => (
                <NumericFormat
                  allowNegative={false}
                  error={fieldState.invalid}
                  prefix={"R$"}
                  customInput={TextField}
                  fullWidth={true}
                  label={t("fields.amount")}
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale={2}
                  fixedDecimalScale
                  onFocus={(event) => event.target.select()}
                  value={Number(field.value / 100).toFixed(2)}
                  onValueChange={(values) => {
                    if (values.floatValue) {
                      const floatValue = Math.trunc(values.floatValue * 100);
                      field.onChange(floatValue);
                    } else {
                      field.onChange(undefined);
                    }
                  }}
                />
              )}
            />
          </FormCurrencyAmountContainer>
          <Controller
            control={methods.control}
            name="establishment"
            rules={{
              required: true,
              validate: (value) => {
                return isEstablishmentValid(establishmentInputValue, value?.placeAutocomplete?.name ?? "");
              },
            }}
            render={({ field, fieldState }) => (
              <EstablishmentSelect
                inputValue={establishmentInputValue}
                setInputValue={setEstablishmentInputValue}
                error={
                  fieldState.invalid &&
                  !isEstablishmentValid(establishmentInputValue, field.value?.placeAutocomplete?.name ?? "")
                }
                value={field.value?.placeAutocomplete}
                allowFreeText={allowEstablishmentFreeText}
                onValueChange={(placeAutocomplete, sessionToken) => {
                  return field.onChange({ placeAutocomplete: { ...placeAutocomplete }, sessionToken });
                }}
              />
            )}
          />

          <Controller
            control={methods.control}
            name="date"
            defaultValue={new Date()}
            rules={{ required: true }}
            render={({ field, fieldState }) => {
              return (
                <DatePicker
                  id="date"
                  error={fieldState.invalid}
                  key={"date"}
                  onDateChange={(date) => {
                    if (date) {
                      field.onChange(date.toDate());
                    }
                  }}
                  label={t("fields.date")}
                  value={field.value}
                  fullWidth={true}
                  disabledDate={shouldDisableDate}
                />
              );
            }}
          />
          <Controller
            control={methods.control}
            rules={{ required: true }}
            name="category"
            render={({ field, fieldState }) => (
              <CategorySelect
                error={fieldState.invalid}
                onChange={(category) => {
                  field.onChange({
                    nanoId: category.id,
                    description: category.name,
                  });
                }}
                value={field.value?.nanoId ?? ""}
              />
            )}
          />
          {isPaidPlan && (
            <Controller
              control={methods.control}
              name="costCenter"
              rules={{
                required: false,
                validate: (value) => isCostCenterValid(centerCostInputValue, value?.name ?? ""),
              }}
              render={({ field, fieldState }) => (
                <CostCenterSelect
                  inputValue={centerCostInputValue}
                  setInputValue={setCenterCostInputValue}
                  error={fieldState.invalid && !isCostCenterValid(centerCostInputValue, field.value?.name ?? "")}
                  value={field.value}
                  defaultCostCenter={defaultCostCenter}
                  onValueChange={field.onChange}
                />
              )}
            />
          )}
          <Controller
            control={methods.control}
            name="comments"
            render={({ field }) => (
              <TextArea
                id="description"
                aria-pressed
                placeholder={t("fields.description")}
                resizable
                maxLength={500}
                onChange={field.onChange}
                value={field.value ?? ""}
              />
            )}
          />
        </Form>
      </Card>
    </Container>
  );
};
