import { Navigate, Outlet } from "react-router-dom";

import { useCheckActiveModule } from "@flash-tecnologia/expense-web-activation-utils";
import { Routes } from "./routes";

export const ProtectedRoutes = () => {
  const isLifecycleActive = useCheckActiveModule("lifecycle");

  if (isLifecycleActive === null) {
    return <div></div>;
  }
  return isLifecycleActive ? <Outlet /> : <Navigate to={Routes.SUBSCRIPTION} replace />;
};
