import { z } from "zod";
import {
  TransactionMovement,
  TransactionStatus,
  TransactionType,
} from "../expense-management-service/expense-management-service-types";

export type NanoID = string;

export const CATEGORY_STATUS = ["ACTIVE", "INACTIVE"] as const;

export type CategoryStatus = (typeof CATEGORY_STATUS)[number];

export type Category = {
  id: NanoID;
  name: string;
  externalId?: string;
  status: CategoryStatus;
  isDefault: boolean;
};

export type CreateCategory = Pick<Category, "name" | "externalId">;

export type PatchCategory = Omit<Category, "id" | "deleted" | "isDefault">;

export type Attachment = {
  fileName: string;
  s3Path: string;
  ocrCustomJobId?: string;
};

export type OcrResult = {
  referenceId: string;
  data: OcrData;
};

export type OcrData = {
  date?: string;
  amount?: number;
  establishment?: string;
  ocrScore?: number;
  processingTimeMs?: number;
};

export type ExpenseCategory = {
  nanoId: string;
  description: string;
};

export enum ExpenseStatus {
  /** Rascunho (Pendente) */
  DRAFT = "DRAFT",
  /** Em aprovação (Somente quando tem fluxo de aprovação) */
  PENDING_APPROVAL = "PENDING_APPROVAL",
  /** Em pagamento */
  PENDING_ACCOUNTING = "PENDING_ACCOUNTING",
  /** Reembolsado */
  FINISHED = "FINISHED",
  /** Em revisão */
  REQUIRE_CHANGES = "REQUIRE_CHANGES",
  /** Reprovado */
  REJECTED = "REJECTED",
}

export enum ExpenseType {
  REIMBURSEMENT = "REIMBURSEMENT",
  CORPORATE_CARD = "CORPORATE_CARD",
}

export enum ExpensePaymentMethod {
  CASH = "CASH",
  PIX = "PIX",
  CREDIT_CARD = "CREDIT_CARD",
  DEBIT_CARD = "DEBIT_CARD",
  FLASH_CARD = "FLASH_CARD",
}

export enum ExpenseTransactionCategory {
  REFEICAO = "refeição",
  ALIMENTACAO = "alimentação",
  MOBILIDADE = "mobilidade",
  SAUDE = "saúde",
  ESTAR = "bem estar",
  EDUCACAO = "educação",
  CULTURA = "cultura",
  CONVENIENCIA = "conveniência",
}

export enum ExpenseTransactionCardType {
  PLASTIC = "PLASTIC",
  VIRTUAL = "VIRTUAL",
}

export type ExpenseTransaction = {
  id: string;
  description: string;
  cardLastDigits?: string;
  category?: ExpenseTransactionCategory;
  cardType?: ExpenseTransactionCardType;
  status: TransactionStatus;
  date: string;
  amount: number;
  currency?: string;
  type: TransactionType;
  movement: TransactionMovement;
  isLegacyTransaction: boolean;
};

export enum ApproverSource {
  EMPLOYEE = "EMPLOYEE",
  PEOPLE_GROUP = "PEOPLE_GROUP",
  DEPARTMENT = "DEPARTMENT",
}

export type ApprovalTarget = {
  source: ApproverSource;
  key: string;
  name: string;
};

export type Approver = {
  id: string;
  name: string;
};

export enum ApprovalStatus {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
}
export type Approval = {
  level: number;
  target: ApprovalTarget;
  status: ApprovalStatus;
  decisionDate?: Date;
  actor?: Approver;
  comments?: string;
};

export type ApprovalFlow = {
  approvals: Approval[];
};

export type Expense = {
  id: string;
  amount: number;
  status: ExpenseStatus;
  attachments?: Attachment[];
  accountingBy?: string;
  category?: ExpenseCategory;
  comments?: string;
  accountingComments?: string;
  companyId: string;
  currency?: string;
  //NOTE: date is string due to JSON serialization
  date: string;
  employeeId: string;
  paymentMethod?: ExpensePaymentMethod;
  referenceId?: string;
  reportId?: string;
  type: ExpenseType;
  transaction?: ExpenseTransaction;
  /** establishment is null when expense type is `CORPORATE_CARD`,
  prioritizing field transaction.description instead. */
  establishment?: Establishment;
  costCenter?: CostCenter | null;
  /** ocrData is null when the company has this feature disabled */
  ocrData?: OcrData;
  approvalFlow?: ApprovalFlow;
};

export type PlaceAddress = {
  street: string;
  number: string;
  complement: string;
  neighborhood: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;
};

export type Establishment = {
  source: "PLACES_API" | "OCR";
  placeId: string;
  name: string;
  description: string;
  address: PlaceAddress;
};

export type CostCenter = {
  id: string;
  code: string;
  name: string;
};

export const getExpensesQuerySchema = z.object({
  nanoId: z.string().optional().describe("Expense nano id"),
  initialDate: z.coerce.string().optional().describe("Initial filter date"),
  endDate: z.coerce.string().optional().describe("End filter date"),
  minAmount: z.number().optional().describe("Minimum amount"),
  maxAmount: z.number().optional().describe("Maximum amount"),
  pageNumber: z.coerce.number().default(1).describe("Page number"),
  pageSize: z.coerce.number().default(10).describe("Page size"),
  status: z.array(z.nativeEnum(ExpenseStatus)).optional().describe("Filter expenses by status"),
  type: z.nativeEnum(ExpenseType).optional().describe("Filter expenses by type"),
  categoryNanoIds: z
    .array(z.string())
    .optional()
    .describe("Filter only expenses that have any of these category nano ids"),
  category: z.string().optional().describe("expense category description"),
  transactionType: z
    .array(z.nativeEnum(TransactionType))
    .optional()
    .describe("Transaction types to filter (if set, then `type` is implied to be CORPORATE_CARD)"),
  transactionStatus: z
    .array(z.nativeEnum(TransactionStatus))
    .optional()
    .describe("Transaction status to filter (if set, then `type` is implied to be CORPORATE_CARD)"),
});

export type GetExpensesQuery = z.infer<typeof getExpensesQuerySchema>;

export type GetExpensesResponse = {
  expenses: Expense[];
  totalCount: number;
};

export const getReimbursementsGroupedSchema = z.object({
  startDate: z.string().optional().describe("Start date"),
  endDate: z.string().optional().describe("End date"),
  minAmount: z.coerce.number().optional().describe("Minimum amount filter"),
  maxAmount: z.coerce.number().optional().describe("Maximum amount filter"),
  status: z.array(z.nativeEnum(ExpenseStatus)).optional(),
  transactionStatus: z.array(z.nativeEnum(TransactionStatus)).optional(),
  employeeIds: z.array(z.string()).optional(),
  employeeName: z.string().optional().describe("Employee name filter"),
  categories: z.array(z.string()).optional(),
  pageNumber: z.coerce.number().optional().default(1).describe("Page number"),
  pageSize: z.coerce.number().optional().default(10).describe("Page size"),
  categoryNanoIds: z
    .array(z.string())
    .optional()
    .describe("Filter only expenses that have any of these category nano ids"),
  type: z.nativeEnum(ExpenseType).optional().describe("type"),
});

export type GetReimbursementsGroupedQuery = z.infer<typeof getReimbursementsGroupedSchema>;

export type GetExpensesGroupedResponse = {
  employeesAndExpenses: ExpensesGroupedEmployeesResponse[];
  totalEmployeesCount: number;
};

export type ExpensesGroupedEmployeesResponse = {
  employeeId: string;
  employeeName: string;
  count: number;
  expenses: Expense[];
  summary: ExpensesGroupedSummary;
};

export type ExpensesGroupedSummary = {
  [status in ExpenseStatus]?: {
    count: number;
    amount: number;
  };
};

export type CreateExpense = Omit<
  Expense,
  "id" | "status" | "accountingBy" | "referenceId" | "companyId" | "employeeId" | "type" | "establishment" | "ocrData"
> & {
  establishment?: Partial<Expense["establishment"]>;
};

export type PatchExpense = Partial<
  Omit<Expense, "id" | "status" | "accountingBy" | "referenceId" | "type" | "establishment" | "ocrData">
> & {
  establishment?: Partial<Expense["establishment"]>;
};

export const getSummaryReimbursementRequestSchema = z.object({
  initialDate: z.coerce.string().optional(),
  endDate: z.coerce.string().optional(),
  status: z.array(z.nativeEnum(ExpenseStatus)).optional(),
  employeeId: z.string().optional(),
  type: z.nativeEnum(ExpenseType).optional(),
});

export const summarySchema = z.object({
  status: z.nativeEnum(ExpenseStatus),
  amount: z.coerce.number(),
  count: z.coerce.number(),
});

export type Summary = z.infer<typeof summarySchema>;

export const getSummaryReimbursementResponseSchema = z.object({
  summaries: z.array(summarySchema).optional().default([]),
});

export type GetSummaryReimbursementRequest = z.infer<typeof getSummaryReimbursementRequestSchema>;

export type GetSummaryReimbursementResponse = z.infer<typeof getSummaryReimbursementResponseSchema>;

export type Movement = {
  transaction: ExpenseTransaction;
  expense?: Expense;
};

export const updateExpensesStatusRequestSchema = z.object({
  expenseIds: z.array(z.string()).describe("Expense nano ids to update the status"),
  accountingComments: z
    .string()
    .optional()
    .describe("Comments to add to the expense, optional for some status to update"),
});

export type UpdateExpensesStatusRequest = z.infer<typeof updateExpensesStatusRequestSchema>;

export const updateExpenseStatusRequestSchema = z.object({
  accountingComments: z
    .string()
    .optional()
    .describe("Comments to add to the expense, optional for some status to update"),
});

export type UpdateExpenseStatusRequest = z.infer<typeof updateExpenseStatusRequestSchema>;

export type CompanyTier = "FREE" | "PAID";

export type CompanyData = {
  createdAt: string;
  nanoId: string;
  tier: CompanyTier;
  legacy: {
    isBlocked: boolean;
    hasData: boolean;
  };
};

export type ProcessApprovalRequest = {
  ids: string[];
  action: "APPROVE" | "REJECT" | "REQUIRE_CHANGES";
  actorId: string;
  comment?: string;
};

export type ProcessApprovalResponse = {
  successCount: number;
  errorCount: number;
};

export type SendToApprovalResponse = {
  success: boolean;
  errorCode: string;
};
