import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100%;
  padding-top: 32px;
  justify-content: center;
`;

export const Content = styled.div`
  max-width: 604px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: stretch;
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${(props) => props.theme.spacings.xs4};
  align-self: stretch;
`;

export const List = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-self: stretch;
`;

export const Card = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 20px ${(props) => props.theme.spacings.xs2} 16px 20px;
  gap: ${(props) => props.theme.spacings.xs3};
  align-self: stretch;

  border-radius: ${(props) => props.theme.spacings.xs3};
  border: 1px solid ${(props) => props.theme.colors.neutral[90]};
  background: ${(props) => props.theme.colors.neutral[100]};
`;

export const Description = styled(Typography)`
  width: 300px;
`;
