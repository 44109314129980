import styled from "styled-components";

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacings.s};
`;

export const TitleWithIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-right: 10px;
  flex-shrink: 0;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: 12px;
  border: solid 1px ${(props) => props.theme.colors.neutral[90]};
  justify-content: space-between;
  height: 100%;
`;

export const CardTile = styled.div<{ bottomBorder?: boolean }>`
  border-bottom-width: ${({ bottomBorder }) => (bottomBorder ? "1px" : "0")};
  border-bottom-style: solid;
  border-bottom-color: ${(props) => props.theme.colors.neutral[90]};
  padding: ${(props) => props.theme.spacings.xs1} ${(props) => props.theme.spacings.xs1}
    ${(props) => props.theme.spacings.xs2};
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
`;

export const Explanation = styled.div`
  margin-top: ${({ theme }) => theme.spacings.xs3};
`;

export const PriceTagContainer = styled.div``;

export const PriceTag = styled.div`
  position: absolute;
  transform: translate(-50%, -50%);

  .shape-icon {
    background-color: transparent;
  }
`;

export const CardChanges = styled.div`
  margin-top: ${({ theme }) => theme.spacings.xs3};
  display: flex;
  gap: 1.5px;
  width: 100%;
  align-items: center;

  @media (min-width: 768px) {
    flex-direction: row;
  }

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

export const CardContainerChanges = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: 12px;
  border: solid 1px ${(props) => props.theme.colors.neutral[90]};
  justify-content: space-between;
  height: 100%;
  gap: ${(props) => props.theme.spacings.xs3};
`;

export const CardTileChanges = styled.div<{ bottomBorder?: boolean }>`
  border-bottom-width: ${({ bottomBorder }) => (bottomBorder ? "1px" : "0")};
  border-bottom-style: solid;
  border-bottom-color: ${(props) => props.theme.colors.neutral[90]};
  padding: ${(props) => props.theme.spacings.xs1} ${(props) => props.theme.spacings.xs1}
    ${(props) => props.theme.spacings.xs2};
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
`;

export const LabelButtonContent = styled.div`
  display: flex;
  gap: ${(props) => props.theme.spacings.xs4};
  align-items: center;
`;
