import { Expense, ExpenseStatus } from "$serverTypes";
import { IconTypes, ShapeIconOptions } from "@flash-tecnologia/hros-web-ui-v2";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";

type Props = {
  color: string;
  iconVariant: ShapeIconOptions;
  icon: IconTypes;
  title: string;
  subtitle: string;
  bottomSubtitle?: string;
};

export function useDetailBox(expense: Expense): Props | undefined {
  const { colors } = useTheme();
  const { t } = useTranslation("translations", { keyPrefix: "molecules.expenseDetails" });
  if (!expense) return undefined;

  switch (expense.status) {
    case ExpenseStatus.REQUIRE_CHANGES:
      return {
        title: t("reviewedBy", { name: expense.accountingBy }),
        iconVariant: "error",
        icon: "IconX",
        color: colors.feedback.error[70],
        subtitle: t("reviewReason", { accountingComments: expense.accountingComments }),
      };
    case ExpenseStatus.REJECTED:
      if (expense.approvalFlow?.approvals.some((a) => a.status === "REJECTED")) {
        const approval = expense.approvalFlow.approvals.find((a) => a.status === "REJECTED");
        if (approval) {
          return {
            title: t("rejectedBy", { name: approval.actor?.name }),
            iconVariant: "negative",
            icon: "IconX",
            color: colors.feedback.negative[70],
            subtitle: t("rejectionReason", {
              accountingComments: approval?.comments,
            }),
          };
        }
      }
      return {
        title: t("rejectedBy", { name: expense.accountingBy || expense.approvalFlow?.approvals[0]?.actor?.name }),
        iconVariant: "negative",
        icon: "IconX",
        color: colors.feedback.negative[70],
        subtitle: t("rejectionReason", {
          accountingComments: expense.accountingComments || expense.approvalFlow?.approvals[0]?.comments,
        }),
      };
    default:
      return undefined;
  }
}
