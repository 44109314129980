import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const OptionContainer = styled.li`
  margin: 6px 0;
`;

export const OptionContent = styled.div`
  color: ${(props) => props.theme.colors.neutral[30]};
  fill: ${(props) => props.theme.colors.neutral[40]};
  display: flex;
  gap: ${(props) => props.theme.spacings.xs4};
  align-items: center;
`;

export const OptionCaption = styled(Typography).attrs({
  weight: 600,
})`
  color: ${(props) => props.theme.colors.neutral[60]};
`;

export const OptionLabel = styled(Typography).attrs({
  weight: 700,
})`
  color: ${(props) => props.theme.colors.neutral[30]};
`;
