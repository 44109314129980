import { Routes } from "$frontend/routes";
import { ExpenseFooterActions, FocusedContainer, ReimbursementForm } from "$molecules";
import { useNavigate } from "react-router-dom";
import { useCreateExpense } from "./data/useCreateExpense";
import { ExpenseSummary } from "./ExpenseSummary";
import { Header } from "./Header";

export const ReimbursementCreatePage = () => {
  const { isLoading, methods, onSubmit, showSummary, expenses } = useCreateExpense();
  const navigate = useNavigate();

  return (
    <FocusedContainer
      header={<Header />}
      footer={
        <ExpenseFooterActions
          onCancelClick={() => navigate(Routes.REIMBURSEMENTS)}
          methods={methods}
          hasExpense={false}
          isLoading={isLoading}
          onAddAnother={(data) => onSubmit(data, true, false)}
          onContinueClick={(data) => onSubmit(data, true, true)}
          onQuitAndSave={(data) => onSubmit(data, false, true)}
          isSummary={showSummary}
          summaryAction={() => navigate(Routes.REIMBURSEMENTS)}
        />
      }
    >
      {showSummary ? <ExpenseSummary expenses={expenses} /> : <ReimbursementForm methods={methods} />}
    </FocusedContainer>
  );
};
