import React from "react";

import { Checkbox as StyledCheckbox } from "./styled";

type CheckboxProps = {
  onChange: (event?: React.ChangeEvent<HTMLInputElement>) => void;
};

export const Checkbox = (props: CheckboxProps) => {
  return <StyledCheckbox onChange={props.onChange} />;
};
