import { Typography } from "@flash-tecnologia/hros-web-ui-v2"; //TODO passar pelo styled ou criar componente
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";

import { ExpenseStatus, ExpenseType } from "$serverTypes";
import { InfoCard } from "../InfoCard/InfoCard";
import { Card, Step, StepperContainer, Subtitle } from "./styled";

type PropsType = {
  status: ExpenseStatus | undefined;
  type: ExpenseType | undefined;
};

const stepSize = 5;

type Features = {
  color: string;
  stepNumber: number;
};

function useFeatures(status: ExpenseStatus | undefined): Features {
  const { colors } = useTheme();
  switch (status) {
    case undefined:
    case ExpenseStatus.DRAFT:
      return { color: colors.neutral[80], stepNumber: 1 };
    case ExpenseStatus.PENDING_APPROVAL:
      return { color: colors.feedback.progress[50], stepNumber: 2 };
    case ExpenseStatus.REQUIRE_CHANGES:
      return { color: colors.feedback.error[50], stepNumber: 3 };
    case ExpenseStatus.PENDING_ACCOUNTING:
      return { color: colors.feedback.info[50], stepNumber: 4 };
    case ExpenseStatus.FINISHED:
      return { color: colors.feedback.success[40], stepNumber: 5 };
    case ExpenseStatus.REJECTED:
      return { color: colors.feedback.negative[70], stepNumber: 5 };
  }
}

export const ExpenseStatusStepperCard = ({ status, type }: PropsType) => {
  const { t } = useTranslation("translations", { keyPrefix: "molecules.expenseStatusStepperCard" });
  const { color, stepNumber } = useFeatures(status);
  return (
    <Card>
      <InfoCard
        fullWidth
        title={
          <div>
            <Typography weight={600} color="neutral.40" variant="caption">
              {t("title")}
            </Typography>
            <Typography weight={700} color="neutral.30" variant="headline9">
              {t(`expenseStatus.${type}.${status}`)}
            </Typography>
          </div>
        }
        subTitle={
          <Subtitle>
            {status !== ExpenseStatus.FINISHED && (
              <Typography weight={400} color="neutral.40" variant="body4">
                {t("subtitle")}
              </Typography>
            )}

            <StepperContainer>
              {[...Array(stepSize)].map((_, index) => (
                <Step filled={stepNumber > index} fillColor={color} />
              ))}
            </StepperContainer>
          </Subtitle>
        }
      />
    </Card>
  );
};
