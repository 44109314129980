import { Expense, ExpenseStatus } from "$serverTypes";
import { Origin } from "../ExpenseDetails";
import { PendingAccountActions } from "./PendingAccountActions";
import { PendingApprovalActions } from "./PendingApprovalActions";
import { PendingReimbursementActions } from "./PendingReimbursementActions";

export function FooterActions({ expense, origin }: { expense?: Expense; origin: Origin }) {
  if (!expense) return null;

  switch (expense.status) {
    case ExpenseStatus.PENDING_ACCOUNTING:
      if (origin === "admin-reimbursement") {
        return <PendingReimbursementActions expense={expense} />;
      }
      if (origin === "admin-movement") {
        return <PendingAccountActions expense={expense} />;
      }

      return null;

    case ExpenseStatus.PENDING_APPROVAL:
      if (origin === "approval-reimbursement" || origin === "approval-movement") {
        return <PendingApprovalActions expense={expense} />;
      }

      return null;
    default:
      return null;
  }
}
