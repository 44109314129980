import { trpc } from "$client";
import { Routes } from "$frontend/routes";
import { dispatchToast } from "$frontend/utils/dispatch-events";
import { ExpenseStatus } from "$serverTypes";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export function useAccountExpense() {
  const navigate = useNavigate();
  const { t } = useTranslation("translations", { keyPrefix: "pages.adminMovement" });
  const { mutate, isLoading: isAccounting } = trpc.expense.updateSingleStatus.useMutation({
    onSuccess: () => {
      dispatchToast({
        type: "success",
        content: t("toasts.approveSuccess.title"),
      });

      navigate(Routes.ADMIN_STATEMENT);
    },
    onError: () => {
      dispatchToast({
        type: "error",
        content: t("toasts.approveError.title"),
        description: t("toasts.approveError.description"),
      });
    },
  });

  function accountMovement(id: string) {
    mutate({
      expenseId: id,
      targetStatus: ExpenseStatus.FINISHED,
    });
  }

  return { accountMovement, isAccounting };
}
