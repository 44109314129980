import { CombinedExpenseStatus, DoughnutChart, getColorFromTheme, unifySummaries } from "$organisms";
import { Summary } from "$serverTypes";
import { ArcElement, Chart } from "chart.js";
import { useMemo } from "react";
import { useTheme } from "styled-components";

Chart.register(ArcElement);

type DoughnutChartWithDataProps = {
  /** Summaries to display */
  summaries: Summary[];

  /**
   * Combined statuses to filter
   * */
  combinedStatuses?: CombinedExpenseStatus[];

  /**
   * The size of the chart.
   */
  size?: number;

  /**
   * The portion of the chart that is cut out of the middle.
   * ('50%' - for doughnut, 0 - for pie) String ending with '%' means percentage, number means pixels.
   */
  cutout?: string;
};

export const DoughnutChartExpenseData = ({
  summaries,
  combinedStatuses = [
    CombinedExpenseStatus.DRAFT_OR_REQUIRE_CHANGES,
    CombinedExpenseStatus.PENDING_ACCOUNTING,
    CombinedExpenseStatus.FINISHED,
    CombinedExpenseStatus.REJECTED,
  ],
  size = 75,
  cutout = "75%",
}: DoughnutChartWithDataProps) => {
  const theme = useTheme();

  const chartData = useMemo(() => {
    if (!summaries || summaries.length === 0) {
      return {
        labels: ["No data"],
        amounts: [1],
        colors: [theme.colors.neutral[90]],
      };
    }

    const unifiedSummaries = unifySummaries(summaries, combinedStatuses);

    const labels = unifiedSummaries.map(({ status }) => status);
    const amounts = unifiedSummaries.map(({ amount }) => amount);

    const isAllZero = amounts.every((amount) => amount === 0);

    if (isAllZero) {
      return {
        labels: ["No data"],
        amounts: [1],
        colors: [theme.colors.neutral[90]],
      };
    }

    return {
      labels,
      amounts,
      colors: getColorFromTheme(combinedStatuses),
    };
  }, [summaries, theme]);

  return (
    <DoughnutChart
      data={{
        labels: chartData.labels,
        datasets: [
          {
            data: chartData.amounts,
            backgroundColor: chartData.colors,
            borderWidth: 2,
          },
        ],
      }}
      label={chartData.labels}
      size={size}
      cutout={cutout}
    />
  );
};
