export enum FooterActions {
  QUIT_AND_SAVE = "QUIT_AND_SAVE",
  PRIMARY_SAVE = "PRIMARY_SAVE",
}

export enum ExtraButtonVariant {
  primary = "primary",
  secondary = "secondary",
}

export enum ExtraButtonVariantType {
  neutral = "neutral",
  error = "error",
  success = "success",
  default = "default",
  info = "info",
}

export enum ExtraButtonSize {
  small = "small",
  medium = "medium",
  large = "large",
}
