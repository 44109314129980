import { useEffect, useMemo, useState } from "react";

import { Skeleton } from "$atoms";
import { trpc } from "$client";
import { useDebounce } from "$frontend/shared/hooks/debounce";
import { Autocomplete, type Option } from "$molecules";
import { AutocompleteInputChangeReason } from "@mui/material";
import { useTranslation } from "react-i18next";
import { CostCenter } from "server/src/services/expense-lifecycle-service/expense-lifecycle-types";

const PAGE_SIZE = 20;
const DEBOUNCE_TIME = 500;
const FIVE_MINUTES = 5 * 60 * 1000;

type CostCenterSelectProps = {
  /* Disables interaction with component*/
  disabled?: boolean;
  /* Default cost center*/
  defaultCostCenter?: CostCenter | null;
  /* Actual value being used in Autocomplete that will be submitted*/
  value?: CostCenter | null;
  /* Callback for when actual value changes*/
  onValueChange: (value: CostCenter | null) => void;
  /* User input*/
  inputValue: string;
  /* Set user input*/
  setInputValue: (value: string) => void;
  /* If the component has invalid input and can't be submitted*/
  error?: boolean;
  /* If the component is loading*/
  isLoading?: boolean;
};

function costCenterToOption(costCenter: CostCenter) {
  return {
    value: costCenter.id,
    label: costCenter.name,
    caption: costCenter.code,
  } satisfies Option;
}

function optionToCostCenter(option: Option | null): CostCenter | null {
  if (!option) return null;

  return {
    id: option.value.toString(),
    name: option.label || "",
    code: option.caption || "",
  } satisfies CostCenter;
}

export const CostCenterSelect = ({
  disabled,
  defaultCostCenter,
  value,
  onValueChange,
  inputValue,
  setInputValue,
  error,
  isLoading,
}: CostCenterSelectProps) => {
  const { t } = useTranslation("translations", { keyPrefix: "molecules.costCenterSelect" });

  // if user has types or selected an option
  const [hasUserTypedOrSelected, setHasUserTypedOrSelected] = useState(false);
  // value to be debounced
  const [searchValue, setSearchValue] = useState("");
  // debounced from searchValue and used in trpc
  const [query, setQuery] = useState("");

  // sync input value and search value with actual value when value changes outside of autocomplete option selection
  useEffect(() => {
    setInputValue(value?.name || "");
    if (value?.id === defaultCostCenter?.id) {
      setSearchValue("");
    } else {
      setSearchValue(value?.name || "");
    }
  }, [defaultCostCenter, setInputValue, value]);

  const { data, isFetching } = trpc.costCenter.search.useQuery(
    {
      pageSize: PAGE_SIZE,
      pageNumber: 1,
      search: query,
    },
    {
      staleTime: FIVE_MINUTES,
      cacheTime: FIVE_MINUTES,
      refetchOnMount: false,
      enabled: (hasUserTypedOrSelected && query === searchValue) || defaultCostCenter === null,
    },
  );

  const options = useMemo(() => {
    // dont change the order of the early returns
    if (isFetching) return [];
    const opts = data?.records.map(costCenterToOption) ?? [];
    if (opts.length === 0 && value) {
      // no results returned by the trpc and value were set
      return [costCenterToOption(value)];
    }
    return opts;
  }, [data?.records, isFetching, value]);

  useDebounce(() => setQuery(searchValue), [searchValue], DEBOUNCE_TIME);

  function getNoOptionDescription() {
    if (query == "") {
      return t("type");
    }
    if (options.length == 0) {
      return t("notFound");
    }
    return "";
  }

  function onSelectChange(option: Option | null): void {
    const costCenterSelected = data?.records.find(({ id }) => id == option?.value) ?? null;
    const optionCostCenterSelected = options.find(({ value }) => value == option?.value) ?? null;

    setInputValue(costCenterSelected?.name ?? "");
    onValueChange(optionToCostCenter(optionCostCenterSelected));
  }

  function onInputValueChange(_: React.SyntheticEvent, inputValue: string, reason: AutocompleteInputChangeReason) {
    switch (reason) {
      case "reset":
        if (inputValue === value?.name && (_?.type === "click" || _?.type === "keydown")) {
          /* for some reason this is triggered when the user clicks or presses the option with the current value
           but costCenterInputValue is different frominputValue, so input has to be set */
          setInputValue(inputValue);
        }
        break;
      case "clear":
        setInputValue(inputValue);
        onSelectChange(null);
        setSearchValue("");
        if (!hasUserTypedOrSelected) {
          setHasUserTypedOrSelected(true);
        }
        break;
      case "input":
        setInputValue(inputValue);
        setSearchValue(inputValue);
        if (!hasUserTypedOrSelected) {
          setHasUserTypedOrSelected(true);
        }
        break;
    }
  }

  if (isLoading) {
    return <Skeleton height={60} />;
  }

  return (
    <Autocomplete
      disabled={disabled}
      label={t("costCenter")}
      loadingText={t("loading")}
      error={error}
      isLoading={isFetching || searchValue !== query}
      noOptionContent={{ title: getNoOptionDescription() }}
      value={value ? costCenterToOption(value) : null}
      options={options}
      onSelectChange={onSelectChange}
      filterOptions={() => options}
      onInputChange={onInputValueChange}
      inputValue={inputValue}
    />
  );
};
