import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";
import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { SkeletonLoader } from "$atoms";
import { trpc } from "$client";
import { useAdminReimbursementsContext } from "$frontend/pages/AdminReimbursements/context/AdminReimbursementsContext";
import { useGetSummaryWithRemainingAwaitingAccountCount } from "$frontend/shared/hooks";
import { DateRangeField, NotificationCard, PageContainer, PageHeader } from "$molecules";
import {
  ExpenseChart,
  ExpenseFilterDrawer,
  ExpenseFiltersFields,
  ExpenseStatusCardList,
  ExpenseUserGroupList,
} from "$organisms";
import { ExpenseType } from "$serverTypes";
import { defaultStatus } from "$utils";

import { ReimbursementEmptyStateSection } from "$frontend/components/organisms/ReimbursementEmptyStateSection";
import { SummaryCards } from "./styled";

export const AdminReimbursementsPage = () => {
  const expenseType = ExpenseType.REIMBURSEMENT;
  const { t } = useTranslation("translations", { keyPrefix: "pages.reimbursementsSummary" });
  const { methods } = useAdminReimbursementsContext();
  const { control } = methods;
  const contextState = methods.watch();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedFiltersCount, setSelectedFiltersCount] = useState(0);
  const { data: companyData, isLoading } = trpc.expense.companyHasAny.useQuery();

  const { data, isFetching, isError, refetch } = trpc.expense.admin.getExpensesGrouped.useQuery({
    employeeName: contextState.search,
    type: expenseType,
    status: contextState.filters.status.length > 0 ? contextState.filters.status : defaultStatus,
    categoryNanoIds: contextState.filters.categoryNanoIds.length > 0 ? contextState.filters.categoryNanoIds : [],
    startDate: contextState.filters.dateRange.from.toISOString(),
    endDate: contextState.filters.dateRange.to.toISOString(),
    minAmount: contextState.filters.valueRange.min,
    maxAmount: contextState.filters.valueRange.max,
    pageNumber: contextState.pagination.pageNumber,
    pageSize: contextState.pagination.pageSize,
  });

  const {
    data: summaryData,
    isFetching: isSummaryLoading,
    refetch: refetchSummary,
    remainingAwaitingAccountExpensesCount,
  } = useGetSummaryWithRemainingAwaitingAccountCount({
    status: defaultStatus,
    type: expenseType,
    dateRange: contextState.filters.dateRange,
  });

  const handleFilterChange = (selectedFilters: ExpenseFiltersFields) => {
    methods.setValue("filters.status", selectedFilters?.expenseStatus || []);
    methods.setValue("filters.categoryNanoIds", selectedFilters?.categoryNanoIds || []);
    methods.setValue("filters.valueRange", selectedFilters?.valueRange || { min: undefined, max: undefined });
    methods.setValue("pagination.pageNumber", 1);
  };

  function refetchAll() {
    refetch();
    refetchSummary();
  }

  const company = useSelectedCompany();
  useEffect(() => {
    refetchAll();
  }, [company.selectedCompany?.id]);

  if (!companyData?.hasExpense && !isLoading) {
    return <ReimbursementEmptyStateSection />;
  }

  return (
    <>
      <PageHeader title={t("title")} subtitle={t("subtitle")}>
        <Controller
          name="filters.dateRange"
          control={control}
          render={({ field }) => (
            <DateRangeField
              {...field}
              showAlertBadge={remainingAwaitingAccountExpensesCount > 0}
              alertBadgeTooltip={t("remainingExpensesWarning", { count: remainingAwaitingAccountExpensesCount })}
              isLoading={isSummaryLoading}
              onChange={(value) => {
                field.onChange(value);
                methods.setValue("pagination.pageNumber", 1);
              }}
            />
          )}
        />
      </PageHeader>
      <PageContainer>
        {isError ? (
          <NotificationCard type="error" variant="contained" iconName="IconExclamationMark">
            {t("failedToGetReimbursements")}
          </NotificationCard>
        ) : (
          <>
            {isSummaryLoading ? (
              <SummaryCards>
                <SkeletonLoader isVisible={true} height={212} />
              </SummaryCards>
            ) : (
              <SummaryCards>
                <ExpenseChart type={expenseType} summaries={summaryData?.summaries} />
                <Controller
                  name="filters.status"
                  control={control}
                  render={({ field }) => (
                    <ExpenseStatusCardList
                      summaries={summaryData?.summaries}
                      status={field.value}
                      onStatusChange={(value) => {
                        field.onChange(value);
                      }}
                      type={expenseType}
                    />
                  )}
                />
              </SummaryCards>
            )}
            <ExpenseUserGroupList
              data={data?.employeesAndExpenses || []}
              total={data?.totalEmployeesCount || 0}
              isLoading={isFetching}
              isSearchLoading={isSummaryLoading}
              selectedFiltersCount={selectedFiltersCount}
              groupPagination={contextState.pagination}
              onGroupPaginationChange={(pagination) => {
                methods.setValue("pagination", pagination, { shouldValidate: true });
              }}
              onActionComplete={refetchAll}
              type={expenseType}
              onFilterClick={() => setDrawerOpen(!drawerOpen)}
              onSearch={(search) => {
                methods.setValue("search", search || "");
              }}
            />
          </>
        )}
      </PageContainer>
      <ExpenseFilterDrawer
        expenseType={ExpenseType.REIMBURSEMENT}
        isOpen={drawerOpen}
        onFilterCountChange={(count) => setSelectedFiltersCount(count)}
        onClose={() => setDrawerOpen(false)}
        onSubmit={(selectedFilters: ExpenseFiltersFields) => {
          handleFilterChange(selectedFilters);
          setDrawerOpen(false);
        }}
        filters={{
          expenseStatus: contextState.filters.status,
          categoryNanoIds: contextState.filters.categoryNanoIds,
          valueRange: contextState.filters.valueRange,
        }}
        config={{
          expenseStatus: true,
          categoryNanoIds: true,
          valueRange: true,
        }}
      />
    </>
  );
};
