import { ShapeIcon } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

const alignmentMap = {
  left: "flex-start",
  right: "flex-end",
  center: "center",
};

export const Container = styled.div<{
  align?: "left" | "right" | "center";
}>`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs3};
  align-items: center;
  white-space: nowrap;
  justify-content: ${(props) => alignmentMap[props.align ?? "left"]};
`;

export const Icon = styled(ShapeIcon)`
  flex-shrink: 0;
`;
