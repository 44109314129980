import { trpc } from "$client";
import { useAcquisitionData } from "$frontend/shared/hooks/acquisition/useAcquisitionData";
import { dispatchToast } from "$frontend/utils/dispatch-events";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Acquisition } from "../form/useAcquisitionForm";

export function useSaveForm() {
  const { t } = useTranslation("translations", {
    keyPrefix: "pages.acquisitionForm.error",
  });
  const navigate = useNavigate();
  const { updateLS } = useAcquisitionData();
  const { mutate, isLoading } = trpc.company.saveAcquisitionForm.useMutation({
    onSuccess: () => {
      updateLS();
      navigate(-1);
    },
    onError: () => {
      dispatchToast({ type: "error", content: t("title"), description: t("description") });
    },
  });

  const save = (data: Acquisition) => {
    const isCurrentUser = data.expense.adminManager.currentUser === "me";
    mutate({
      contracts: data.contracts,
      expense: {
        adminManager: {
          currentUser: isCurrentUser,
          email: data.expense.adminManager.email,
        },
        expectedStarting: data.expense.expectedStarting,
        expectedValue: data.expense.expectedValue,
      },
    });
  };

  return { save, isLoading };
}
