import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { trpc } from "$client";
import { DatePeriodMenu } from "$frontend/components/molecules/DatePeriodMenu/DatePeriodMenu";
import { useStatementContext } from "$frontend/pages/Statement/context/StatementContext";
import { useGetSummaryWithOutsideDateRangeCount } from "$frontend/shared/hooks/trpc-utils/use-get-summary-with-outside-date-range-count";
import { PageContainer, PageHeader } from "$molecules";
import { ExpenseStatus, ExpenseType } from "server/src/services/expense-lifecycle-service/expense-lifecycle-types";
import { KnowMore } from "./KnowMore";
import { StatementContent } from "./StatementContent";

export const StatementPage = () => {
  const expenseType = ExpenseType.CORPORATE_CARD;
  const { methods } = useStatementContext();
  const { control } = methods;
  const contextState = methods.watch();

  const { t } = useTranslation("translations", { keyPrefix: "pages.statement" });
  const { data: hasAny, isLoading } = trpc.corporateCard.me.hasAny.useQuery();
  const { isFetching: isSummaryLoading, remainingAwaitingAccountExpensesCount } =
    useGetSummaryWithOutsideDateRangeCount({
      status: [ExpenseStatus.DRAFT, ExpenseStatus.REQUIRE_CHANGES],
      targetStatus: [ExpenseStatus.DRAFT, ExpenseStatus.REQUIRE_CHANGES],
      type: expenseType,
      dateRange: { from: contextState.filters.datePeriod.startDate, to: contextState.filters.datePeriod.endDate },
      isAdmin: false,
    });

  return (
    <>
      <PageHeader title={t("title")}>
        <Controller
          name="filters.datePeriod"
          control={control}
          render={({ field }) => (
            <DatePeriodMenu
              value={field.value}
              showAlertBadge={remainingAwaitingAccountExpensesCount > 0}
              alertBadgeTooltip={t("remainingExpensesWarning", { count: remainingAwaitingAccountExpensesCount })}
              isLoading={isSummaryLoading}
              handleDateChange={(value) => {
                field.onChange(value);
                methods.setValue("pagination.pageNumber", 1);
              }}
            />
          )}
        />
      </PageHeader>
      <PageContainer isLoading={isLoading}>{hasAny ? <StatementContent /> : <KnowMore />}</PageContainer>
    </>
  );
};
