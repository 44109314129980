import { ShapeIcon, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Icons } from "$atoms";
import { Routes } from "$frontend/routes";
import { getValueWithCurrency } from "$frontend/utils";
import { ExpenseAmountTrailingContainer, InfoCard, InfoCardStatus } from "$molecules";
import { Expense } from "$serverTypes";
import { AccountabilitySurvey } from "../AccountabilitySurvey/AccountabilitySurvey";
import { Card, Container, Content, Description, List, Title } from "./styled";

type FinishedPendingItemsListProps = {
  expenses: Expense[];
  isLoading: boolean;
};

export const FinishedPendingItemsList = (props: FinishedPendingItemsListProps) => {
  const { t } = useTranslation("translations", { keyPrefix: "molecules.finishedPendingItemsList" });

  const navigate = useNavigate();
  return (
    <Container>
      <Content>
        <Title>
          <ShapeIcon name="IconCircleCheck" variant="success" stroke="success" size={48} />
          <Typography variant="headline8" weight={700} color="neutral.10">
            {t("title")}
          </Typography>
          <Typography variant="body4" color="neutral.40">
            {t("subTitle")}
          </Typography>
        </Title>

        {props.isLoading ? (
          <Skeleton width="100%" height={"400px"} />
        ) : (
          <List>
            {props.expenses.map((expense) => (
              <Card onClick={() => navigate(Routes.MOVEMENT.replace(":expenseId", expense?.id ?? ""))}>
                <InfoCard
                  fullWidth={true}
                  leading={<ShapeIcon variant="default" stroke="default" name="IconReceipt" size={40} />}
                  title={
                    <Typography variant="caption" color="neutral.40">
                      {t("cardTitle")}
                    </Typography>
                  }
                  subTitle={
                    <Description weight={700} variant="headline8" color="neutral.30">
                      {expense.transaction?.description ?? ""}
                    </Description>
                  }
                  trailing={
                    <ExpenseAmountTrailingContainer>
                      <InfoCard
                        alignItems="flex-end"
                        title={
                          <Typography variant="caption" color="neutral.40">
                            {t("expenseAmount")}
                          </Typography>
                        }
                        subTitle={
                          <Typography weight={700} variant="headline8" color="neutral.30">
                            {getValueWithCurrency({
                              value: expense.amount,
                              currencyPrefix: expense.currency,
                            })}
                          </Typography>
                        }
                      />
                      <Icons name="IconChevronRight" size={20} stroke="neutral.40" />
                    </ExpenseAmountTrailingContainer>
                  }
                />
                <InfoCardStatus type={expense.type} status={expense.status} />
              </Card>
            ))}
          </List>
        )}
        <AccountabilitySurvey />
      </Content>
    </Container>
  );
};
