import { LinkButton } from "@flash-tecnologia/hros-web-ui-v2";
import { useTranslation } from "react-i18next";

import { Icons, Skeleton } from "$atoms";
import { trpc } from "$client";
import { InfoCard } from "$molecules";
import { getValueWithCurrency } from "$utils";
import {
  CalendarIcon,
  Card,
  CardContent,
  CardHeaderBigNumber,
  CardHeaderText,
  Cards,
  CardText,
  Container,
  Content,
  ContentFirstColumn,
  ContentSecondColumn,
  Header,
  HeaderIcon,
  HeaderText,
  HeaderTitle,
  Row,
} from "./styled";

export type AccountBalanceCardProps = {
  /** Date range to query for*/
  dateRange: {
    initialDate: Date;
    endDate: Date;
  };
};

export const AccountBalanceCard = ({ dateRange }: AccountBalanceCardProps) => {
  const { data, isFetching } = trpc.corporateCard.getAccountBalance.useQuery(dateRange, {
    initialData: {
      initial: { corporateCard: 0, total: 0, wallet: 0 },
      final: { corporateCard: 0, total: 0, wallet: 0 },
    },
  });
  const { t } = useTranslation("translations", { keyPrefix: "molecules.accountBalanceCard" });

  if (isFetching) {
    return <Skeleton height={314} />;
  }

  return (
    <Container>
      <Header>
        <HeaderTitle>
          <HeaderIcon /> <HeaderText>{t("title")}</HeaderText>
        </HeaderTitle>
        <LinkButton variant="default" size="small" onClick={() => alert("TODO: Accessar extratos")}>
          {t("linkButtonText")}
          <Icons name="IconArrowRight" size={16} />
        </LinkButton>
      </Header>
      <Content>
        <Cards>
          {Object.entries(data).map(([period, { total, corporateCard, wallet }]) => {
            return (
              <Row>
                <ContentFirstColumn>
                  <CalendarIcon />
                </ContentFirstColumn>
                <ContentSecondColumn>
                  <Card>
                    <InfoCard
                      fullWidth={true}
                      title={
                        <CardHeaderText>
                          {t(`${period}DateTitle`, {
                            dateValue: period == "initial" ? dateRange.initialDate : dateRange.endDate,
                            formatParams: {
                              dateValue: {
                                month: "2-digit",
                                day: "2-digit",
                              },
                            },
                          })}
                        </CardHeaderText>
                      }
                      trailing={<CardHeaderBigNumber>{getValueWithCurrency({ value: total })}</CardHeaderBigNumber>}
                    />
                    <CardContent>
                      <InfoCard
                        fullWidth={true}
                        leading={<Icons name="IconCornerDownRight" size={12} />}
                        title={<CardText>{t("cards")}</CardText>}
                        trailing={<CardText>{getValueWithCurrency({ value: corporateCard })}</CardText>}
                      />
                      <InfoCard
                        fullWidth={true}
                        leading={<Icons name="IconCornerDownRight" size={12} />}
                        title={<CardText>{t("wallet")}</CardText>}
                        trailing={<CardText>{getValueWithCurrency({ value: wallet })}</CardText>}
                      />
                    </CardContent>
                  </Card>
                </ContentSecondColumn>
              </Row>
            );
          })}
        </Cards>
      </Content>
    </Container>
  );
};
