import { trpc } from "$client";
import { ExpenseStatus } from "$serverTypes";

export function useBalanceChangeEnabled(status: ExpenseStatus) {
  const response = trpc.corporateCard.checkBalanceChangeEnabled.useQuery(undefined, {
    cacheTime: Infinity,
    staleTime: Infinity,
  });

  switch (status) {
    case ExpenseStatus.DRAFT:
      return true;
    case ExpenseStatus.REQUIRE_CHANGES:
      return response?.data?.isEnabled || false;

    default:
      break;
  }
}
