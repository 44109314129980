import { trpc } from "$client";
import { dispatchToast } from "$frontend/utils/dispatch-events";
import { ExpenseStatus, ExpenseType } from "$serverTypes";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export function useReopenExpense(type: ExpenseType) {
  const [openReopenModal, setOpenReopenModal] = useState(false);
  const { t } = useTranslation("translations", {
    keyPrefix: type === ExpenseType.CORPORATE_CARD ? "pages.adminMovement" : "pages.adminReimbursement",
  });
  const context = trpc.useUtils();
  const { mutate, isLoading: isReopening } = trpc.expense.updateSingleStatus.useMutation({
    onSuccess: () => {
      dispatchToast({
        type: "success",
        content: t("toasts.reopenSuccess.title"),
      });
      setOpenReopenModal(false);
      context.expense.getExpenseById.invalidate();
    },
    onError: () => {
      dispatchToast({
        type: "error",
        content: t("toasts.editError.title"),
        description: t("toasts.editError.description"),
      });
    },
  });

  function reopenExpense(id: string) {
    mutate({
      expenseId: id,
      targetStatus: ExpenseStatus.PENDING_ACCOUNTING,
    });
  }

  return { reopenExpense, isReopening, openReopenModal, setOpenReopenModal };
}
