import { Button, Icons } from "@flash-tecnologia/hros-web-ui-v2";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { trpc } from "$client";
import { CategoriesList } from "$frontend/containers/category/CategoriesList/CategoriesList";
import { CategorySettingsProvider } from "$frontend/containers/category/context/category-settings";
import { CategoryFormModal } from "$molecules";
import { PageContainer } from "./styled";

const AdminCategoriesPage = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const { mutateAsync: createCategory, isLoading: createCatLoading } = trpc.category.createCategory.useMutation();

  return (
    <CategorySettingsProvider>
      <PageContainer
        header={{
          title: t("pages.categoriesList.title"),
          subTitle: t("pages.categoriesList.subtitle"),
          action: (
            <Button variant="primary" size="medium" onClick={() => setIsOpen(true)} loading={createCatLoading}>
              <Icons name="Add" />
              {t("pages.categoriesList.headerAction")}
            </Button>
          ),
        }}
        size="sm"
      >
        <CategoriesList />
        <CategoryFormModal isOpen={isOpen} onClose={() => setIsOpen(false)} onDone={createCategory} />
      </PageContainer>
    </CategorySettingsProvider>
  );
};

export default AdminCategoriesPage;
