import { Icons, ShapeIcon } from "$atoms";
import Flex from "$frontend/components/atoms/Flex/Flex";
import Grid from "$frontend/components/atoms/Grid/Grid";
import { Button, colors, LinkButton, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export function KnowMore() {
  const { t } = useTranslation("translations", { keyPrefix: "pages.reimbursements" });
  const navigate = useNavigate();

  const box = [
    {
      title: t("knowMore.box.first.title"),
      description: t("knowMore.box.first.description"),
      image: "https://images.flashapp.com.br/expense/expense-lifecycle/reimbursement-know-more-(1).svg",
      alt: "Formulário de solicitação de reembolso",
    },
    {
      title: t("knowMore.box.second.title"),
      description: t("knowMore.box.second.description"),
      image: "https://images.flashapp.com.br/expense/expense-lifecycle/reimbursement-know-more-(2).svg",
      alt: "Lista de status da solicitação de reembolso",
    },
  ];
  return (
    <Grid templateColumns="1fr" padding="s" gap="s">
      <Typography.Headline6>{t("title")}</Typography.Headline6>
      <Grid templateColumns="50%" justify="center">
        <Flex
          borderWidth="xs2"
          borderColor="neutral.80"
          radius="m"
          padding="xs"
          align="center"
          direction="column"
          gap="xs"
        >
          <Flex align="center" gap="xs5">
            <Icons name="IconSparkles" stroke={colors.get("flamingo-pink")} size={16} />
            <Typography.Body4 color="flamingo-pink" weight={700}>
              {t("knowMore.title")}
            </Typography.Body4>
          </Flex>
          <Typography.Headline8 style={{ textAlign: "center" }}>{t("knowMore.subtitle")}</Typography.Headline8>
          <Grid templateColumns="140%" justify="center">
            <Grid templateColumns="1fr 1fr" gap="xs">
              {box.map((item, index) => {
                return (
                  <Flex
                    key={index}
                    borderWidth="xs2"
                    borderColor="secondary.95"
                    radius="m"
                    direction="column"
                    backgroundColor="neutral.100"
                  >
                    <img src={item.image} alt={item.alt} />
                    <Flex direction="column" gap="xs4" padding="xs2">
                      <Typography.Headline10 color="flamingo-pink">{item.title}</Typography.Headline10>
                      <Typography.Caption>{item.description}</Typography.Caption>
                    </Flex>
                  </Flex>
                );
              })}
            </Grid>
          </Grid>
          <div>
            <Button variant="primary" onClick={() => navigate("/expense-lifecycle/reimbursement/create")}>
              {t("addReimbursement")} <Icons name="IconArrowRight" size={16} />
            </Button>
          </div>
          <Flex width="100%" radius="m" backgroundColor="secondary.99" padding="xs2" align="center" gap="xs2">
            <ShapeIcon variant="secondary" icon="IconMessageDots" size="extra_small" />
            <Typography.Caption weight={700}>{t("knowMore.doubt")}</Typography.Caption>
            <LinkButton
              size="small"
              variant="default"
              target="_blank"
              href="https://drive.google.com/file/d/1ZhPHyiVCfQMvOc1hKYn5aw4x8uJ9NkF4/view"
            >
              {t("knowMore.faq")}
            </LinkButton>
          </Flex>
        </Flex>
      </Grid>
    </Grid>
  );
}
