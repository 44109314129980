import { Icons } from "$atoms";
import Grid from "$frontend/components/atoms/Grid/Grid";
import styled from "styled-components";

export const Container = styled.div<{ fullWidth: boolean }>(({ theme, fullWidth }) => ({
  marginBottom: theme.spacings.s,
  maxWidth: fullWidth ? "fit-content" : "auto",
  overflow: "hidden",
  borderRadius: theme.borders.radius.s,
  border: `${theme.borders.width.xs2} solid ${theme.colors.neutral[90]}`,
  paddingLeft: theme.spacings.m,
  paddingRight: theme.spacings.m,
  paddingTop: theme.spacings.l,
  paddingBottom: theme.spacings.l,
}));

export const Box = styled(Grid)`
  border-top: ${({ theme }) => theme.borders.width.xs2} solid ${({ theme }) => theme.colors.neutral[90]};
`;

export const IconButton = styled(Icons)`
  cursor: pointer;
`;
