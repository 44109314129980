import { ShapeIcon, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import React from "react";
import { Container } from "./styled";

type EmptyGroupListProps = {
  title: string;
  description: string;
};

export const EmptyGroupList: React.FC<EmptyGroupListProps> = ({ title, description }) => {
  return (
    <Container>
      <ShapeIcon variant="neutral" name="IconZoomCancel" size={56} stroke="neutral" />
      <Typography variant="headline7" color="neutral.40">
        {title}
      </Typography>
      <Typography variant="body4" color="neutral.40">
        {description}
      </Typography>
    </Container>
  );
};
