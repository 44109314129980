import styled from "styled-components";

export const ModalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: ${(props) => props.theme.spacings.xs2};
  padding-bottom: ${(props) => props.theme.spacings.xs};
`;

export const MessageContainer = styled.div(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  backgroundColor: theme.colors.neutral[95],
  padding: `${theme.spacings.xs4} ${theme.spacings.xs3}`,
  borderRadius: theme.borders.radius.m,
  gap: theme.spacings.xs4,
}));

export const IconContainer = styled.div`
  display: flex;
`;
