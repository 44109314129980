import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { ExpenseHeader } from "$frontend/components/molecules/ExpenseHeader";
import { Routes } from "$frontend/routes";
import { ExpenseFooterActions, FocusedContainer, ReimbursementForm } from "$molecules";
import { useCreateExpense } from "./data/useCreateExpense";
import { ExpenseSummary } from "./ExpenseSummary";

export const ReimbursementCreatePage = () => {
  const { isLoading, methods, onSubmit, showSummary, expenses, defaultCostCenter, setOcrData } = useCreateExpense();
  const navigate = useNavigate();
  const { t } = useTranslation("translations", {
    keyPrefix: "pages.createReimbursement",
  });

  const header = (
    <ExpenseHeader onClick={() => navigate(Routes.REIMBURSEMENTS)} backDescription={t("back")} title={t("title")} />
  );

  return (
    <FocusedContainer
      header={header}
      footer={
        <ExpenseFooterActions
          onCancelClick={() => navigate(Routes.REIMBURSEMENTS)}
          methods={methods}
          hasExpense={false}
          isLoading={isLoading}
          onAddAnother={(data) => onSubmit(data, true, false, false)}
          onContinueClick={(data) => onSubmit(data, true, false, true)}
          onQuitAndSave={(data) => onSubmit(data, false, true, false)}
          isSummary={showSummary}
          summaryAction={() => navigate(Routes.REIMBURSEMENTS)}
        />
      }
    >
      {showSummary ? (
        <ExpenseSummary expenses={expenses} />
      ) : (
        <ReimbursementForm methods={methods} defaultCostCenter={defaultCostCenter} setOcrData={setOcrData} />
      )}
    </FocusedContainer>
  );
};
