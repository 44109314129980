import {
  Checkbox,
  Dot,
  LinkButton,
  PillButton,
  Tab,
  Table,
  tableControllers,
  Tooltip,
} from "@flash-tecnologia/hros-web-ui-v2"; //TODO passar pelo styled ou criar componente
import { PaginationState } from "@flash-tecnologia/hros-web-ui-v2/dist/components/Table/components/Pagination";
import { forwardRef, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";

import { Skeleton, TagButton } from "$atoms";
import { FilterEmptyState } from "$frontend/components/molecules/FilterEmptyState/FilterEmptyState";
import { useStatementContext } from "$frontend/pages/Statement/context/StatementContext";
import { useStatementQuery } from "$frontend/pages/Statement/hooks/useStatementQuery";
import { ButtonGroup, ButtonGroupOption, ExpenseStatusTag, FilterButton, NotificationCard } from "$molecules";
import { ExpenseReceipts, MainRowColumn } from "$organisms";
import {
  Expense,
  ExpenseStatus,
  ExpenseTransactionCardType,
  Movement,
  TransactionMovement,
  TransactionStatus,
} from "$serverTypes";
import { DateRange, getValueWithCurrency } from "$utils";
import { CSSProperties } from "styled-components";
import { MainRowColumnProps } from "../MainRowColumn";
import {
  Caption,
  Container,
  DescriptionCaptionContainer,
  EmptyAction,
  EmptyCell,
  Toolbar,
  ToolbarButtonGroup,
} from "./styled";

type MovementTableProps = {
  dateRange: DateRange;
  selectedFiltersCount?: number;
  onFilterClick?: () => void;
  onAddReceipt: (expense: Expense) => void;
  onSendToAccount: (movement: Movement) => void;
  onVisualize: (movement: Movement) => void;
  onReclassify: (movement: Movement) => void;
};

export interface MovementTableHandles {
  resetSelection: () => void;
  refetch: () => Promise<void>;
}

export const MovementTable = forwardRef<MovementTableHandles, MovementTableProps>((props, ref) => {
  const { methods } = useStatementContext();
  const { movements, totalCount, isFetching, isError, refetch } = useStatementQuery();
  const { t } = useTranslation("translations", { keyPrefix: "organisms.movementTable" });

  const contextState = methods.watch();
  const pageSizes = [5, 10, 15, 25, 50];

  function onPaginationChange(pagination: PaginationState) {
    methods.setValue("pagination", pagination, { shouldValidate: true });
  }

  function getDescriptionColumnTransactionStatus(status: TransactionStatus): string {
    const showStatuses = [TransactionStatus.REVERTED, TransactionStatus.AUTHORIZED];
    return showStatuses.includes(status) ? t(`transactionStatus.${status}`) : "";
  }

  function getIconParamsByTransactionMovement(transactionMovement: TransactionMovement): MainRowColumnProps["icon"] {
    switch (transactionMovement) {
      case TransactionMovement.DEPOSIT:
        return { name: "IconTransferIn", variant: "success" };
      case TransactionMovement.COMPANY_WITHDRAW:
        return { name: "IconArrowBackUp", variant: "neutral" };
      case TransactionMovement.EMPLOYEE_WITHDRAW:
        return { name: "IconCash", variant: "neutral" };
      case TransactionMovement.BILLET_PAYMENT:
        return { name: "IconFileDescription", variant: "neutral" };
      case TransactionMovement.CASH_IN_PIX:
        return { name: "Pix", variant: "success" };
      case TransactionMovement.CASH_OUT_PIX:
        return { name: "Pix", variant: "neutral" };
      case TransactionMovement.CASH_IN_TED:
        return { name: "IconCash", variant: "success" };
      case TransactionMovement.CASH_OUT_TED:
        return { name: "IconCash", variant: "neutral" };
      default:
        return { name: "IconCreditCard", variant: "neutral" };
    }
  }

  function getStyleByTransactionStatus(status: TransactionStatus): CSSProperties {
    return {
      textDecoration: status == TransactionStatus.CANCELED ? "line-through" : "initial",
    };
  }

  function getAmountParamsByTransactionMovement(
    value: string,
    transactionMovement: TransactionMovement,
    status: TransactionStatus,
  ): MainRowColumnProps["title"] {
    const positiveMovements = [
      TransactionMovement.CASH_IN_PIX,
      TransactionMovement.CASH_IN_TED,
      TransactionMovement.DEPOSIT,
    ];
    const negativeMovements = [
      TransactionMovement.OPEN_LOOP_PAYMENT,
      TransactionMovement.CLOSED_LOOP_PAYMENT,
      TransactionMovement.BILLET_PAYMENT,
      TransactionMovement.CASH_OUT_PIX,
      TransactionMovement.CASH_OUT_TED,
      TransactionMovement.COMPANY_WITHDRAW,
      TransactionMovement.EMPLOYEE_WITHDRAW,
    ];

    const style = getStyleByTransactionStatus(status);

    if (positiveMovements.includes(transactionMovement)) {
      return {
        description: "+ " + value,
        color: transactionMovement === TransactionMovement.DEPOSIT ? "feedback.success.40" : undefined,
        style,
      };
    } else if (negativeMovements.includes(transactionMovement)) {
      return {
        description: "- " + value,
        style,
      };
    }
    return {
      description: value,
      style,
    };
  }

  const table = tableControllers.useTableColumns<Movement>({
    defaultColumn: {
      minSize: 200,
    },
    total: totalCount,
    options: {
      selectable: true,
    },
    columns: [
      {
        header: t("columns.date"),
        id: "date",
        minSize: 120,
        cell: ({ row }) => {
          const date = new Date(row.original.transaction.date);

          return (
            <MainRowColumn
              title={{ description: date.toLocaleDateString("pt-BR") }}
              caption={{ description: date.toLocaleTimeString("pt-BR", { hour: "2-digit", minute: "2-digit" }) }}
            />
          );
        },
      },
      {
        header: t("columns.description"),
        id: "description",
        cell: ({ row }) => {
          const { transaction, expense } = row.original;
          const showStatusDescription = getDescriptionColumnTransactionStatus(transaction.status);
          const showDot = expense && showStatusDescription;

          return (
            <MainRowColumn
              title={{
                description: transaction.description,
                style: getStyleByTransactionStatus(transaction.status),
              }}
              caption={{
                custom: (
                  <DescriptionCaptionContainer>
                    <Caption>
                      {transaction?.status == TransactionStatus.CANCELED
                        ? t(`transactionStatus.${transaction.status}`)
                        : expense && (expense.category?.description ?? t("noCategory"))}
                    </Caption>
                    {showDot && <Dot variant="gray" />}
                    <Caption>{showStatusDescription}</Caption>
                  </DescriptionCaptionContainer>
                ),
              }}
              icon={getIconParamsByTransactionMovement(transaction.movement)}
            />
          );
        },
      },
      {
        header: t("columns.amount"),
        minSize: 150,
        id: "amount",
        cell: ({ row }) => {
          const { amount, currency, movement, status } = row.original.transaction;
          const value = getValueWithCurrency({ value: amount, currencyPrefix: currency });
          const title = getAmountParamsByTransactionMovement(value, movement, status);
          return <MainRowColumn title={title} />;
        },
      },

      {
        header: t("columns.payment"),
        minSize: 130,
        id: "payment",
        cell: ({ row }) => {
          const { cardType, cardLastDigits } = row.original.transaction;
          return (
            <MainRowColumn
              title={{ description: t(`cardType.${cardType ?? ExpenseTransactionCardType.PLASTIC}`) }}
              caption={{ description: cardLastDigits ? t("cardLastDigits", { cardLastDigits }) : "" }}
              icon={{ name: "IconCreditCard", variant: "neutral" }}
            />
          );
        },
      },
      {
        header: t("columns.status"),
        id: "status",
        minSize: 200,
        cell: ({ row }) => {
          const { expense, transaction } = row.original;
          if (!expense || transaction?.status == TransactionStatus.CANCELED) {
            return <EmptyCell />;
          }

          return <ExpenseStatusTag type={row.original.expense?.type} status={row.original.expense?.status} />;
        },
      },
      {
        header: t("columns.receipt"),
        id: "receipt",
        minSize: 160,
        cell: ({ row }) => {
          const { expense, transaction } = row.original;
          if (!expense || transaction?.status == TransactionStatus.CANCELED) {
            return <EmptyCell />;
          }

          if ((expense.attachments?.length ?? 0) === 0) {
            return (
              <LinkButton variant="default" onClick={() => props.onAddReceipt(expense)}>
                {t(`addReceipt`)}
              </LinkButton>
            );
          }
          return <ExpenseReceipts key={`movements-receipts-${expense.id}`} attachments={expense.attachments ?? []} />;
        },
      },
      {
        header: t("columns.actions"),
        size: 60,
        //Note: using underscore to avoid this column to be named "Ações"
        id: "_actions",
        sticky: "right",
        cell: ({ row }) => renderActions(row.original),
      },
    ],
    data: movements,
    pagination: contextState.pagination,
    onPaginationChange: onPaginationChange,
  });

  useImperativeHandle(ref, () => ({
    resetSelection() {
      table.resetSelected();
    },
    async refetch() {
      await refetch();
    },
  }));

  function renderActions(movement: Movement) {
    const { expense, transaction } = movement;

    if (!expense || transaction?.status === TransactionStatus.CANCELED) {
      const translationKey =
        transaction?.status === TransactionStatus.CANCELED
          ? "actions.canceledTransaction.tooltip"
          : "actions.empty.tooltip";

      return (
        <EmptyAction>
          <Tooltip title={t(translationKey)}>
            <div>
              <PillButton size="extra-small" variant="default" icon="IconInfoCircle" />
            </div>
          </Tooltip>
        </EmptyAction>
      );
    }

    const options: ButtonGroupOption[] = [];
    if (expense.status === ExpenseStatus.DRAFT) {
      options.push({
        icon: "IconFileExport",
        label: t("actions.sendToAccount.enabled"),
        onClick: () => props.onSendToAccount(movement),
      });
    } else {
      options.push({
        icon: "IconFileDescription",
        label: t("actions.visualize"),
        onClick: () => props.onVisualize(movement),
      });
    }

    if ([ExpenseStatus.DRAFT, ExpenseStatus.REQUIRE_CHANGES].includes(expense.status)) {
      options.push({
        icon: "IconRefreshAlert",
        label: t("actions.reclassify"),
        onClick: () => props.onReclassify(movement),
      });
    }

    return <ButtonGroup options={options} />;
  }

  if (isError) {
    return (
      <NotificationCard type="error" variant="contained" iconName="IconExclamationMark">
        {t("failedToGetMovements")}
      </NotificationCard>
    );
  }

  if (isFetching) {
    return (
      <Container>
        <Skeleton width="100%" height="280px" />
      </Container>
    );
  }

  return (
    <>
      <Container>
        <Table.Root variant="soft">
          <Table.Content>
            <Toolbar>
              <Tab tabItens={[{ label: t("tabs.allMovements") }]} />
              <ToolbarButtonGroup>
                <FilterButton
                  onFilterClick={props.onFilterClick}
                  alertCountFilters={props.selectedFiltersCount ?? undefined}
                />
              </ToolbarButtonGroup>
            </Toolbar>
            {isFetching || table.rows.length > 0 ? (
              <>
                <Table.Grid.Root loading={isFetching} empty={{ message: t("emptyState") }}>
                  {table.selected.allSelected || table.selected.selected.length > 0 ? (
                    <tr className="data-grid-table-header-bulk-actions-container" role="row">
                      <th className="data-grid-table-header-bulk-actions">
                        <Checkbox
                          onChange={() => table.setAllSelected(!table.selected.allSelected)}
                          indeterminate={table.selected.selected.length > 0}
                          checked={table.selected.allSelected}
                          aria-label="column row checkbox"
                        />
                      </th>
                      <th className="data-grid-table-header-bulk-actions">
                        <TagButton status="active" label="" onClick={() => table.selectionHandler(console.log)} />
                      </th>
                    </tr>
                  ) : (
                    <Table.Grid.Header
                      getHeaderGroups={table.getHeaderGroups}
                      toggleAllRowsExpanded={table.toggleAllRowsExpanded}
                    />
                  )}
                  {table.rows.map((row, index) => (
                    <Table.Grid.Row key={`movement-row-${index}-${row.id}`} row={row} />
                  ))}
                </Table.Grid.Root>
                <Table.Pagination
                  count={totalCount}
                  onPaginationChange={onPaginationChange}
                  pagination={contextState.pagination}
                  pageSizeOptions={pageSizes.map((value) => ({
                    label: t("pageSizeFormat", { value }),
                    value,
                  }))}
                />
              </>
            ) : (
              <FilterEmptyState />
            )}
          </Table.Content>
        </Table.Root>
      </Container>
    </>
  );
});
