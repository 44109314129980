import { PaginationState } from "@flash-tecnologia/hros-web-ui-v2/dist/components/Table/components/Pagination";
import { createContext, useContext } from "react";
import { useForm, UseFormReturn } from "react-hook-form";

import { DatePeriodState, lastDay, mappedDays } from "$frontend/components/molecules/DatePeriodMenu/DatePeriodMenu";
import { ExpenseStatus } from "$serverTypes";

export type ReimbursementsState = {
  filters: {
    status: ExpenseStatus[];
    categoryNanoIds: string[];
    datePeriod: DatePeriodState;
    valueRange: { max?: number; min?: number };
  };
  search: string;
  pagination: PaginationState;
};

const defaultValues: ReimbursementsState = {
  filters: {
    status: [],
    categoryNanoIds: [],
    datePeriod: {
      selected: "LAST_NINETY",
      startDate: mappedDays.LAST_NINETY.toDate(),
      endDate: lastDay,
    },
    valueRange: { min: undefined, max: undefined },
  },
  search: "",
  pagination: { pageNumber: 1, pageSize: 10 },
};

export type ISessionProviderProps = {
  children: React.ReactNode;
};

type ReimbursementsContextProps = {
  methods: UseFormReturn<ReimbursementsState>;
};

const ReimbursementsContext = createContext<ReimbursementsContextProps | undefined>(undefined);

export const ReimbursementsProvider = ({ children }: ISessionProviderProps) => {
  const methods = useForm<ReimbursementsState>({ defaultValues });

  return <ReimbursementsContext.Provider value={{ methods }}>{children}</ReimbursementsContext.Provider>;
};

export const useReimbursementsContext = () => {
  const context = useContext(ReimbursementsContext);
  if (!context) {
    throw new Error("useReimbursementsContext must be used within a ReimbursementsProvider");
  }
  return context;
};
