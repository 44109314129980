import { Navigate, Outlet } from "react-router-dom";

import { useAcquisitionData } from "$frontend/shared/hooks/acquisition/useAcquisitionData";
import { Routes } from "./routes";

export type THintData = {
  isAnswered: boolean;
  contracts?: {
    id: string;
    dealId: string;
    externalDealId: string;
    isSelfAcquisition: boolean;
  }[];
};

export const AcquisitionGuard = () => {
  const { data } = useAcquisitionData();

  if (data?.isAnswered) {
    return <Outlet />;
  }

  const shouldRedirect = data?.contracts?.some((contract) => contract.isSelfAcquisition) && !data?.isAnswered;

  return shouldRedirect ? <Navigate to={Routes.ADMIN_ACQUISITION_FORM} replace={false} /> : <Outlet />;
};
