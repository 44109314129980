import { ButtonProps, Icons, LinkButton } from "@flash-tecnologia/hros-web-ui-v2";
import React from "react";
import { useTranslation } from "react-i18next";

import { Alignment } from "$frontend/utils/enum";
import {
  CheckboxCheckedIcon,
  IconActionButtonContainer,
  IconClose,
  IconHeaderContainer,
  IconLabelContainer,
  ModalBodyContainer,
  ModalContainer,
  ModalFooterContainer,
  ModalHeaderContainer,
  StyledActionModal,
  Subtitle,
  SuccessActionButton,
  SuccessIconLabel,
  Title,
} from "./styled";

export interface SuccessActionModalProps {
  /**
   * if 'true' open the modal
   */
  open: boolean;

  /**
   * if 'true' the button shows loading status
   */
  isLoading?: boolean;

  /**
   * Modal content
   */
  children?: React.ReactNode;

  /**
   * Icon Label header
   *
   */
  headerIconLabel?: string;

  /**
   * Title header
   *
   */
  headerTitle?: string;

  /**
   * Subtitle header
   *
   */
  headerSubtitle?: string;

  /**
   * Text or custom item in header
   *
   */
  headerCustom?: React.ReactNode;

  /**
   * Text or custom item in footer
   *
   */
  footerCustom?: React.ReactNode;

  /**
   * Confirmation button custom text or item
   *
   */
  labelButtonAction?: React.ReactNode;

  /**
   * Icon Action Typography
   *
   */
  iconActionButton?: React.ReactNode;

  /**
   * Cancel button custom text or item
   *
   */
  labelButtonCancel?: React.ReactNode;

  /**
   * if 'true' disable button action
   */
  isActionDisabled?: boolean;

  /**
   * Header icon position
   *
   */
  iconPosition?: Alignment;

  /**
   * Show Cancel Typography
   *
   */
  showCancelButton?: boolean;

  /**
   * Custom header icon component
   */
  headerIcon?: React.ReactNode;

  /**
   * If header icon is a small or shape icon
   */
  headerIconMode?: "shape" | "small";

  /**
   * Custom action button properties
   */
  actionButtonProps?: ButtonProps;

  /**
   * Cancel event
   *
   */
  onCancelClick?(): void;

  /**
   * Close event
   *
   */
  onCloseClick?(): void;

  /**
   * Action event
   *
   */
  onActionClick?(): void;

  /**
   * Class name
   */
  className?: string;
}

export const SuccessActionModal = ({
  open,
  isLoading,
  children,
  headerIconLabel,
  headerTitle,
  headerSubtitle,
  headerCustom,
  footerCustom,
  labelButtonAction,
  iconActionButton,
  labelButtonCancel,
  isActionDisabled,
  iconPosition = Alignment.center,
  showCancelButton = true,
  onCancelClick,
  onCloseClick,
  onActionClick,
  headerIcon,
  headerIconMode = "shape",
  actionButtonProps,
  className,
}: SuccessActionModalProps) => {
  const { t } = useTranslation();

  return (
    <StyledActionModal open={open} onClose={onCloseClick} className={className}>
      <ModalContainer>
        <ModalHeaderContainer iconPosition={iconPosition}>
          {Boolean(headerCustom) ? (
            headerCustom
          ) : (
            <>
              <IconClose onClick={onCloseClick || onCancelClick} />

              {headerIconMode === "shape" && (
                <IconHeaderContainer iconPosition={iconPosition}>
                  {headerIcon || <CheckboxCheckedIcon />}
                </IconHeaderContainer>
              )}

              <IconLabelContainer>
                {Boolean(headerIconLabel) && (
                  <SuccessIconLabel>
                    {headerIconMode === "small" && (headerIcon || <Icons name="IconCircleCheck" size={16} />)}

                    {headerIconLabel}
                  </SuccessIconLabel>
                )}
              </IconLabelContainer>

              {Boolean(headerTitle) && <Title>{headerTitle}</Title>}
              {Boolean(headerSubtitle) && <Subtitle>{headerSubtitle}</Subtitle>}
            </>
          )}
        </ModalHeaderContainer>
        {Boolean(children) && <ModalBodyContainer className="success-action-modal-body">{children}</ModalBodyContainer>}
        {footerCustom !== null && (
          <ModalFooterContainer showCancelButton={showCancelButton}>
            {Boolean(footerCustom) ? (
              footerCustom
            ) : (
              <>
                {showCancelButton && (
                  <div>
                    <LinkButton variant="neutral" onClick={onCancelClick || onCloseClick}>
                      {labelButtonCancel || t("molecules.defaultModal.cancel")}
                    </LinkButton>
                  </div>
                )}
                <div>
                  <SuccessActionButton
                    loading={isLoading}
                    disabled={isActionDisabled}
                    onClick={onActionClick}
                    variantType="success"
                    size="large"
                    variant="primary"
                    {...actionButtonProps}
                  >
                    {labelButtonAction || t("molecules.defaultModal.confirm")}
                    {Boolean(iconActionButton) && (
                      <IconActionButtonContainer>{iconActionButton}</IconActionButtonContainer>
                    )}
                  </SuccessActionButton>
                </div>
              </>
            )}
          </ModalFooterContainer>
        )}
      </ModalContainer>
    </StyledActionModal>
  );
};
