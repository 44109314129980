import { useCheckCompanyTier } from "@flash-tecnologia/expense-web-activation-utils";
import { Dot, Table, tableControllers } from "@flash-tecnologia/hros-web-ui-v2";
import { PaginationState } from "@flash-tecnologia/hros-web-ui-v2/dist/components/Table/components/Pagination";
import { Row } from "@flash-tecnologia/hros-web-ui-v2/dist/components/Table/shared/table.types";
import { Box } from "@mui/material";
import { Row as TanstackRow } from "@tanstack/table-core";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";

import { MenuOption, SoftTable } from "$atoms";
import { FilterEmptyState } from "$frontend/components/molecules/FilterEmptyState/FilterEmptyState";
import { useDebounce } from "$frontend/shared/hooks/debounce";
import { useValueFormatter } from "$frontend/shared/hooks/formatters/useValueFormatter";
import { useDisplayToast } from "$frontend/utils";
import { ButtonGroup, ButtonGroupOption, ExpenseStatusTag, FilterButton } from "$molecules";
import {
  ExpenseReceipts,
  ExpensesNotifyModal,
  ExpensesStatusUpdateModal,
  MainRowColumn,
  UpdatableExpenseStatus,
} from "$organisms";
import { Expense, ExpenseStatus, ExpenseType, TransactionMovement, TransactionStatus } from "$serverTypes";
import { MainRowColumnProps } from "../MainRowColumn";
import { EmptyCell } from "../MovementTable/styled";
import { Caption, DescriptionCaptionContainer } from "./styled";

type ExpenseTableProps = {
  /**
   * array of expenses to be displayed in the table
   */
  expenses: Expense[];

  /**
   * pagination state, including current page and page size
   */
  pagination: PaginationState;

  /**
   * optional list of page size options
   */
  pageSizes?: Readonly<number[]>;

  /**
   * total count of items for pagination
   */
  count: number;

  /**
   * loading state indicator for the table
   */
  isLoading: boolean;

  /**
   * loading state indicator for the search component in table
   */
  isSearchLoading?: boolean;

  /**
   * indicates whether the display will be for administrative purposes
   */
  isAdminPage?: boolean;

  /** expense type */
  type: ExpenseType;

  /**
   * callback function for deleting selected expenses
   */
  onDelete: (expense: Expense[]) => void;

  /**
   * callback function for editing a specific expense
   */
  onEdit: (expense: Expense) => void;

  /**
   * callback for pagination state changes
   */
  onPaginationChange: (state: PaginationState) => void;

  /**
   * callback for action is complete
   */
  onActionComplete?: () => void;

  /**
   * callback function for viewing a specific expense
   */
  onVisualize: (expense: Expense) => void;

  /**
   * Optional callback when rows are selected
   */
  onSelectedChange?: (expenses: Expense[]) => void;

  /**
  Current selected expenses (useful when applying actions cross tables components)
  */
  selectedExpenses?: Expense[];

  /**
   Current selected filters count
   */
  selectedFiltersCount?: number;

  /**
   * Callback triggered when the filter button is clicked
   */
  onFilterClick?: () => void;

  /**
   * callback triggered when the input search has changed
   */
  onSearch?: (state: string) => void;
};

export interface ExpenseTableHandles {
  /** resets all table selection */
  resetSelection: () => void;
}

type ActionOptions =
  | "EDIT"
  | "EDIT_PINK"
  | "VIEW"
  | "DELETE"
  | "REQUEST_REVIEW"
  | "RINGING"
  | "CHECKMARK"
  | "REOPEN"
  | "VERTICAL_DOTS";

export const ExpenseTable = forwardRef<ExpenseTableHandles, ExpenseTableProps>((props, ref) => {
  const loadedExpenses = props.isLoading ? [] : props.expenses;
  const expenses = props.isAdminPage
    ? paginateExpenses(loadedExpenses, props.pagination.pageNumber, props.pagination.pageSize)
    : props.expenses;

  const expenseType = props.type;
  const { isAdminPage = false } = props;
  const { t } = useTranslation("translations", { keyPrefix: "organisms.expenseTable" });
  const { displayToast } = useDisplayToast();
  const { colors } = useTheme();
  const { getValueWithCurrency } = useValueFormatter();

  const [searchValue, setSearchValue] = useState("");
  const searchRef = useRef("");

  const isPaidPlan = useCheckCompanyTier(["PAID"]);

  useDebounce(
    () => {
      if (props.onSearch) {
        props.onSearch(searchValue);
      }
    },
    [searchValue],
    500,
  );

  const [statusModalProps, setStatusModalProps] = useState<{
    targetStatus: UpdatableExpenseStatus;
    expensesToUpdate: Expense[];
  } | null>(null);

  const [notifyModalProps, setNotifyModalProps] = useState<{
    expensesToNotify: Expense[];
  } | null>(null);

  function paginateExpenses(data: Expense[], pageNumber: number, pageSize: number) {
    const startIndex = (pageNumber - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return data.slice(startIndex, endIndex);
  }

  function getDescriptionColumnTransactionStatus(status: TransactionStatus): string {
    const showStatuses = [TransactionStatus.CANCELED, TransactionStatus.REVERTED, TransactionStatus.AUTHORIZED];
    return showStatuses.includes(status) ? t(`transactionStatus.${status}`) : "";
  }

  function getIconParamsByTransactionMovement(transactionMovement: TransactionMovement): MainRowColumnProps["icon"] {
    switch (transactionMovement) {
      case TransactionMovement.DEPOSIT:
        return { name: "IconTransferIn", variant: "success" };
      case TransactionMovement.COMPANY_WITHDRAW:
        return { name: "IconArrowBackUp", variant: "neutral" };
      case TransactionMovement.EMPLOYEE_WITHDRAW:
        return { name: "IconCash", variant: "neutral" };
      case TransactionMovement.BILLET_PAYMENT:
        return { name: "IconFileDescription", variant: "neutral" };
      case TransactionMovement.CASH_IN_PIX:
        return { name: "Pix", variant: "neutral" };
      case TransactionMovement.CASH_OUT_PIX:
        return { name: "Pix", variant: "neutral" };
      default:
        return { name: "IconCreditCard", variant: "neutral" };
    }
  }

  function validateCategoryNames(description: string | undefined) {
    if (description) return description;

    return t("noCategory");
  }

  const table = tableControllers.useTableColumns<Expense>({
    total: props.count,
    options: {
      selectable: true,
    },
    columns: [
      {
        header: t("columns.date"),
        id: "date",
        cell: ({ row }) => {
          const date = new Date(row.original.date);
          return <MainRowColumn title={{ description: date.toLocaleDateString("pt-BR") }} />;
        },
      },
      {
        header: t(`columns.description.${expenseType}`),
        id: "description",
        cell: ({ row }) => {
          if (expenseType === ExpenseType.REIMBURSEMENT) {
            return (
              <MainRowColumn
                title={{ description: row.original.establishment?.name ?? row.original.referenceId ?? "" }}
                caption={{ description: validateCategoryNames(row.original.category?.description) }}
                icon={{ name: "IconReceipt", variant: "neutral" }}
              />
            );
          } else if (expenseType === ExpenseType.CORPORATE_CARD && row.original.transaction) {
            const showStatusDescription = getDescriptionColumnTransactionStatus(row.original.transaction.status);
            return (
              <MainRowColumn
                title={{ description: row.original.transaction.description }}
                caption={{
                  custom: (
                    <DescriptionCaptionContainer>
                      <Caption>{row.original.category?.description ?? t("noCategory")} </Caption>
                      {showStatusDescription && <Dot variant="gray" />}
                      <Caption>{showStatusDescription}</Caption>
                    </DescriptionCaptionContainer>
                  ),
                }}
                icon={getIconParamsByTransactionMovement(row.original.transaction.movement)}
              />
            );
          }
        },
      },
      {
        header: () => <Box textAlign="right">{t("columns.amount")}</Box>,
        id: "amount",
        cell: ({ row }) => {
          const { amount, currency } = row.original;
          return (
            <MainRowColumn
              title={{ description: getValueWithCurrency({ value: amount, currencyPrefix: currency }) }}
              align="right"
            />
          );
        },
      },
      {
        header: t("columns.receipt"),
        id: "receipt",
        size: 100,
        cell: ({ row }) => (
          <ExpenseReceipts key={`receipts-${row.original.id}`} attachments={row.original.attachments ?? []} />
        ),
      },
      {
        header: t("columns.status"),
        id: "status",
        cell: ({ row }) => <ExpenseStatusTag status={row.original.status} type={row.original.type} />,
      },
      ...(isPaidPlan
        ? [
            {
              header: () => (
                <Box textAlign="left" whiteSpace="nowrap">
                  {t("columns.costCenter")}
                </Box>
              ),
              id: "costCenter",
              size: 100,
              cell: ({ row }: { row: TanstackRow<Expense> }) => {
                if (!row.original?.costCenter) return <EmptyCell />;
                return <MainRowColumn align="left" title={{ description: row.original.costCenter.name }} />;
              },
            },
          ]
        : []),
      {
        header: () => <Box textAlign="center">{t("columns.comments")}</Box>,
        id: "comments",
        cell: ({ row }) => {
          if (!row.original?.comments) return <EmptyCell />;
          return <MainRowColumn align="center" title={{ description: row.original.comments || "-" }} />;
        },
      },
      {
        header: t("columns.actions"),
        size: 60,
        id: "actions",
        sticky: "right",
        cell: ({ row }) => renderActions(row as Row<Expense>),
      },
    ],
    data: expenses,
    pagination: props.pagination,
    onPaginationChange: props.onPaginationChange,
  });

  function getSelected() {
    if (table.selected.selected.length > 0) {
      return table.selected.selected.map((row) => row.original);
    }

    if (table.selected.allSelected) {
      return table.rows.map((row) => row.original);
    }

    return [];
  }

  useEffect(() => {
    if (props.onSelectedChange) {
      props.onSelectedChange(getSelected());
    }
  }, [table.selected.selected, table.selected.allSelected]);

  useImperativeHandle(ref, () => ({
    resetSelection() {
      table.resetSelected();
    },
  }));

  function OPTIONS(row: Row<Expense>): Record<ActionOptions, ButtonGroupOption> {
    const expense = row.original;
    const disabled = table.selected.selected.length > 0 && !table.selected.selected.some(({ id }) => id == row.id);
    return {
      EDIT: {
        icon: "IconPencil",
        label: t("actions.edit"),
        onClick: () => props.onEdit(expense),
        iconColor: colors.neutral[40],
      },
      EDIT_PINK: {
        icon: "IconPencil",
        label: t("actions.edit"),
        onClick: () => props.onEdit(expense),
        iconColor: colors.secondary[50],
      },
      VIEW: {
        icon: "IconFileDescription",
        label: t("actions.visualize"),
        onClick: () => props.onVisualize(expense),
        iconColor: colors.neutral[40],
      },
      DELETE: {
        icon: "IconTrash",
        label: t("actions.delete"),
        onClick: () => {
          const tableSelected = getSelected();
          const selected = tableSelected.length > 0 ? tableSelected : [expense];
          if (selected.some((expense) => !isExpenseMutable(expense))) {
            displayToast({
              type: "error",
              title: t("toasts.undeletableExpensesSelected.title"),
              description: t("toasts.undeletableExpensesSelected.description"),
            });
            return;
          }
          props.onDelete(selected);
        },
        iconColor: colors.neutral[40],
      },
      REQUEST_REVIEW: {
        icon: "IconAlertTriangle",
        label: t("actions.requestReview"),
        onClick: () => openStatusModal(ExpenseStatus.REQUIRE_CHANGES, expense),
        iconColor: colors.feedback.error[40],
        disabled,
      },
      CHECKMARK: {
        icon: "IconCheck",
        label: t(`actions.checkmark.${expense.type}`),
        onClick: () => openStatusModal(ExpenseStatus.FINISHED, expense),
        iconColor: colors.feedback.success[40],
        disabled,
      },
      REOPEN: {
        icon: "IconRotateClockwise",
        label: t("actions.reopen"),
        onClick: () => openStatusModal(ExpenseStatus.PENDING_ACCOUNTING, expense),
        iconColor: colors.primary,
        disabled,
      },
      RINGING: {
        icon: "IconBellRinging",
        label: t("actions.notify"),
        onClick: () => openNotifyModal(expense),
        iconColor: colors.primary,
      },
      VERTICAL_DOTS: {
        icon: "IconDotsVertical",
        label: t("actions.verticalDotsDropdown.tooltip"),
        menuOptions: [
          {
            onClick: () => props.onVisualize(expense),
            children: (
              <MenuOption
                label={t(`actions.verticalDotsDropdown.visualize.${expense.type}`)}
                icon="IconFileDescription"
              />
            ),
          },
          {
            onClick: () => props.onEdit(expense),
            children: <MenuOption label={t(`actions.verticalDotsDropdown.edit.${expense.type}`)} icon="IconPencil" />,
          },
          {
            onClick: () => openStatusModal(ExpenseStatus.REJECTED, expense),
            children: (
              <MenuOption
                icon="IconX"
                iconColor={colors.feedback.negative[50]}
                label={t(`actions.verticalDotsDropdown.reject.${expense.type}`)}
                labelColor={"status.negative.50"}
                onClick={() => openStatusModal(ExpenseStatus.REJECTED, expense)}
              />
            ),
          },
        ],
        iconColor: colors.neutral[40],
        disabled,
      },
    };
  }

  /** Opens modal that changes one or multiple expense (from table selection or expense clicked) status to targetStatus */
  function openStatusModal(targetStatus: UpdatableExpenseStatus, expenseClicked: Expense) {
    const selected = getSelected();
    if (selected.length > 0) {
      setStatusModalProps({
        targetStatus,
        expensesToUpdate: selected,
      });
      return;
    }

    if (props.selectedExpenses && props.selectedExpenses.length > 0) {
      setStatusModalProps({
        targetStatus,
        expensesToUpdate: props.selectedExpenses,
      });
      return;
    }

    setStatusModalProps({
      targetStatus,
      expensesToUpdate: [expenseClicked],
    });
  }

  /** Opens modal that notify one or multiple expense (from table selection or expense clicked) */
  function openNotifyModal(expenseClicked: Expense) {
    const selected = getSelected();
    if (selected.length > 0) {
      setNotifyModalProps({ expensesToNotify: selected });
      return;
    }

    if (props.selectedExpenses && props.selectedExpenses.length > 0) {
      setNotifyModalProps({ expensesToNotify: props.selectedExpenses });
      return;
    }

    setNotifyModalProps({ expensesToNotify: [expenseClicked] });
  }

  const getOptions = (row: Row<Expense>): ButtonGroupOption[] => {
    const action = OPTIONS(row);
    switch (row.original.status) {
      case ExpenseStatus.DRAFT:
        return [action.EDIT_PINK, action.DELETE];

      case ExpenseStatus.REJECTED:
        return [action.VIEW];

      case ExpenseStatus.REQUIRE_CHANGES:
        return [action.VIEW, action.EDIT, action.DELETE];

      case ExpenseStatus.PENDING_APPROVAL:
        return [action.VIEW, action.EDIT, action.DELETE];

      case ExpenseStatus.PENDING_ACCOUNTING:
        return [action.VIEW];

      case ExpenseStatus.FINISHED:
        return [action.VIEW];

      default:
        return [];
    }
  };

  const getOptionsAdminPage = (row: Row<Expense>): ButtonGroupOption[] => {
    const action = OPTIONS(row);
    switch (row.original.status) {
      case ExpenseStatus.DRAFT:
        return [action.RINGING, action.EDIT];

      case ExpenseStatus.PENDING_APPROVAL:
        return [action.RINGING, action.EDIT, action.VIEW];

      case ExpenseStatus.PENDING_ACCOUNTING:
        return [action.CHECKMARK, action.REQUEST_REVIEW, action.VERTICAL_DOTS];

      case ExpenseStatus.REQUIRE_CHANGES:
        return [action.RINGING, action.EDIT, action.VIEW];

      case ExpenseStatus.REJECTED:
        return [action.REOPEN, action.VIEW];

      case ExpenseStatus.FINISHED:
        return [action.REOPEN, action.VIEW];

      default:
        return [];
    }
  };

  function renderActions(row: Row<Expense>) {
    const options = isAdminPage ? getOptionsAdminPage(row) : getOptions(row);
    return <ButtonGroup options={options} />;
  }

  function isExpenseMutable(expense: Expense) {
    if (!expense.status) {
      return false;
    }

    return [ExpenseStatus.DRAFT, ExpenseStatus.REQUIRE_CHANGES, ExpenseStatus.PENDING_APPROVAL].includes(
      expense.status,
    );
  }

  return (
    <>
      <SoftTable>
        {props.isAdminPage ? (
          <>
            <Table.Grid.Root loading={props.isLoading} empty={{ message: t("emptyState") }}>
              <Table.Grid.Header
                getHeaderGroups={table.getHeaderGroups}
                toggleAllRowsExpanded={table.toggleAllRowsExpanded}
              />
              {table.rows.map((row, index) => (
                <Table.Grid.Row key={index + row.id} row={row} />
              ))}
            </Table.Grid.Root>
            <Table.Pagination
              style={{ paddingRight: "20px", paddingLeft: "20px" }}
              showItemRange
              count={props.count}
              onPaginationChange={props.onPaginationChange}
              pagination={props.pagination}
              pageSizeOptions={props.pageSizes?.map((value) => ({
                label: t("pageSizeFormat", { value }),
                value,
              }))}
            />
          </>
        ) : (
          <Table.Content>
            <Table.FilterSearch
              labelSearch={t("searchByCategory")}
              searchDefaultValue={searchValue}
              onSearch={(search) => {
                const value = search?.target?.value;
                searchRef.current = value;
                setSearchValue(value);
              }}
              onClear={() => {
                searchRef.current = "";
                setSearchValue("");
              }}
            >
              <FilterButton
                onFilterClick={props.onFilterClick}
                alertCountFilters={props.selectedFiltersCount ?? undefined}
              />
            </Table.FilterSearch>

            {props.isLoading || table.rows.length > 0 ? (
              <>
                <Table.Grid.Root loading={props.isLoading} empty={{ message: t("emptyState") }}>
                  <Table.Grid.Header
                    getHeaderGroups={table.getHeaderGroups}
                    toggleAllRowsExpanded={table.toggleAllRowsExpanded}
                  />
                  {table.rows.map((row, index) => (
                    <Table.Grid.Row key={index + row.id} row={row} />
                  ))}
                </Table.Grid.Root>
                <Table.Pagination
                  showItemRange
                  count={props.count}
                  onPaginationChange={props.onPaginationChange}
                  pagination={props.pagination}
                  pageSizeOptions={props.pageSizes?.map((value) => ({
                    label: t("pageSizeFormat", { value }),
                    value,
                  }))}
                />
              </>
            ) : (
              <FilterEmptyState />
            )}
          </Table.Content>
        )}
      </SoftTable>

      {statusModalProps && (
        <ExpensesStatusUpdateModal
          targetStatus={statusModalProps.targetStatus}
          expensesToUpdate={statusModalProps.expensesToUpdate}
          onClose={() => setStatusModalProps(null)}
          onUpdateFinished={() => {
            table.resetSelected();
            props.onActionComplete?.();
          }}
        />
      )}

      {notifyModalProps && (
        <ExpensesNotifyModal
          expensesToNotify={notifyModalProps.expensesToNotify}
          onClose={() => setNotifyModalProps(null)}
          onNotifyFinished={() => {
            table.resetSelected();
            props.onActionComplete?.();
            setNotifyModalProps(null);
          }}
        />
      )}
    </>
  );
});
