import { useCheckAcquisition } from "@flash-tecnologia/expense-web-activation-utils";
import { useForm } from "react-hook-form";

export type Acquisition = {
  contracts: {
    id: string;
  }[];
  expense: {
    expectedValue: number;
    expectedStarting: number;
    adminManager: {
      currentUser: string;
      email?: string;
    };
  };
};

export function useAcquisitionForm() {
  const { data } = useCheckAcquisition();
  const ids = data?.contracts?.map((contractId) => ({ id: contractId }));
  const form = useForm<Acquisition>({
    defaultValues: {
      contracts: ids,
    },
  });

  return form;
}
