export enum Alignment {
  left = "left",
  center = "center",
  right = "right",
}

export enum TypeVariant {
  DEFAULT = "default",
  CUSTOM = "custom",
}

export enum CategoryStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}
