import { Routes } from "$frontend/routes";
import { ExternalRoutes } from "$frontend/routes/routes";
import { useTranslation } from "react-i18next";
import { Origin } from "../ExpenseDetails";

export function useBreadCrumb(origin: Origin) {
  const { t } = useTranslation("translations", { keyPrefix: "pages.visualizeReimbursement" });
  const { t: tMovement } = useTranslation("translations", { keyPrefix: "pages.visualizeMovement" });
  const { t: tApproval } = useTranslation("translations", { keyPrefix: "pages.visualizeApproval.breadcrumb" });
  switch (origin) {
    case "user-reimbursement":
      return [
        {
          to: Routes.REIMBURSEMENTS,
          label: t("breadcrumbs.previous"),
        },
        {
          to: "",
          label: t("breadcrumbs.current"),
        },
      ];

    case "admin-reimbursement":
      return [
        {
          to: Routes.ADMIN_REIMBURSEMENTS,
          label: t("breadcrumbs.previous"),
        },
        {
          to: "",
          label: t("breadcrumbs.current"),
        },
      ];
    case "user-movement":
      return [
        {
          to: Routes.STATEMENT,
          label: tMovement("previous"),
        },
        {
          to: "",
          label: tMovement("title"),
        },
      ];
    case "admin-movement":
      return [
        {
          to: Routes.ADMIN_STATEMENT,
          label: tMovement("adminPrevious"),
        },
        {
          to: "",
          label: tMovement("title"),
        },
      ];
    case "approval-movement":
      return [
        {
          to: ExternalRoutes.PENDING_ACCOUNT_APPROVALS,
          label: tApproval("previous"),
        },
        {
          to: "",
          label: tApproval("pendingAccount"),
        },
      ];
    case "approval-reimbursement":
      return [
        {
          to: ExternalRoutes.PENDING_REIMBURSEMENT_APPROVALS,
          label: tApproval("previous"),
        },
        {
          to: "",
          label: tApproval("pendingReimbursement"),
        },
      ];

    default:
      break;
  }
}
