import { BasePage } from "$frontend/components/organisms";
import styled from "styled-components";

export const PageContainer = styled(BasePage)`
  .base-page-body {
    overflow: visible;
  }

  .base-page-content {
    border-color: ${({ theme }) => theme.colors.neutral[80]};
    border-width: ${({ theme }) => theme.borders.width.xs2};
    border-radius: ${({ theme }) => theme.borders.radius.m};
    padding: ${({ theme }) => theme.spacings.s};
  }
`;
