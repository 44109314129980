import { ShapeIcon } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const WarningBadgeContainer = styled.div`
  position: absolute;
  top: -${(props) => props.theme.spacings.xs4};
  right: -${(props) => props.theme.spacings.xs4};
`;

export const WarningBadge = styled(ShapeIcon).attrs({
  name: "IconAlertTriangle",
  size: 24,
  variant: "error",
  stroke: "error",
})``;

export const Container = styled.div`
  position: relative;
`;
