import React from "react";
import { SearchField as StyledSearchField } from "./styled";

type SearchFieldProps = {
  error?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const SearchField = (props: SearchFieldProps) => {
  return <StyledSearchField onChange={props.onChange} error={props.error} label="Buscar" />; //TODO i18n label
};
