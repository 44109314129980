import { trpc } from "$client";
import { ExpenseStatus, ExpenseType } from "$serverTypes";
import { DateRange, getDateRange } from "$utils";

const yearAgoRange = getDateRange(12);

/**
 * Performs `trpc.expense.getSummary` with given parameters and
 * computes remaining expenses count there are in status `ExpenseStatus.AWAIT_ACCOUNTING`
 * by performing another getSummary query in a year-long range from now and comparing with the
 * first query.
 *
 * This hook is useful when using a FilterButton in conjunction with alertBadgeTooltip prop so that
 * the user can be alerted when there is expenses in AWAIT_ACCOUNT status **outside** of the range that he
 * is querying for.
 * */
export function useGetSummaryWithRemainingAwaitingAccountCount({
  employeeId,
  type,
  status,
  dateRange,
}: {
  employeeId?: string;
  status: ExpenseStatus[];
  type: ExpenseType;
  dateRange: DateRange;
}) {
  const {
    data: summaryData,
    isFetching: isSummaryLoading,
    refetch: refetchSummary,
  } = trpc.expense.getSummary.useQuery(
    {
      employeeId,
      status,
      type,
      initialDate: dateRange.from.toISOString(),
      endDate: dateRange.to.toISOString(),
    },
    { initialData: { summaries: [] } },
  );

  const { data: yearLongData, isFetching: yearLongDataIsFetching } = trpc.expense.getSummary.useQuery(
    {
      employeeId,
      status: [ExpenseStatus.PENDING_ACCOUNTING],
      type,
      initialDate: yearAgoRange.from.toISOString(),
      endDate: yearAgoRange.to.toISOString(),
    },
    {
      initialData: { summaries: [] },
    },
  );

  const dataAwaitingAccountCount =
    summaryData.summaries.find(({ status }) => status == ExpenseStatus.PENDING_ACCOUNTING)?.count ?? 0;
  const yearLongAwaitingAccountCount =
    yearLongData.summaries.find(({ status }) => status == ExpenseStatus.PENDING_ACCOUNTING)?.count ?? 0;

  const remainingAwaitingAccountExpensesCount = yearLongAwaitingAccountCount - dataAwaitingAccountCount;

  return {
    data: summaryData,
    isFetching: yearLongDataIsFetching || isSummaryLoading,
    refetch: refetchSummary,
    remainingAwaitingAccountExpensesCount,
  };
}
