import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  min-width: 900px;
  padding: ${({ theme }) => theme.spacings.xs5};
`;

export const ScrollBox = styled.div`
  display: grid;
  overflow: scroll;
`;
