// Adapted from https://github.com/flash-tecnologia/hros-web-expense-management/tree/03163e336fbab26e418863ef24f5cd62887ca47e/src/components/organisms/Establishments/Establishments.tsx
import { Icons, TextField } from "@flash-tecnologia/hros-web-ui-v2";
import {
  Autocomplete as MuiAutocomplete,
  AutocompleteInputChangeReason,
  FilterOptionsState,
  Popper,
} from "@mui/material";

import { AutocompleteLoader } from "./AutocompleteLoader";
import { AutocompleteMessageOptions, MessageOptionsProps } from "./AutocompleteMessageOptions";
import { RenderOption } from "./RenderOption";
import { Container } from "./styled";

export type Option = {
  label?: string;
  caption?: string;
  value: string | number;
};

export type AutocompleteProps = {
  value?: string;
  options: Option[];
  label: string;
  noOptionContent?: MessageOptionsProps;
  isLoading?: boolean;
  error?: boolean;
  inputValue?: string;
  loadingText?: string;
  onSelectChange?: (value: Option | null) => void;
  onInputChange?: (event: React.SyntheticEvent, value: string, reason: AutocompleteInputChangeReason) => void;
  renderIcon?: (option: Option) => JSX.Element;
  filterOptions?: (options: Option[], state: FilterOptionsState<Option>) => Option[];
};

export const Autocomplete = ({
  value,
  options,
  label,
  noOptionContent,
  isLoading,
  error,
  inputValue,
  loadingText,
  onSelectChange,
  onInputChange,
  renderIcon,
  filterOptions,
}: AutocompleteProps) => {
  const autocompleteValue = options.find((o) => o.value == value) ?? null;
  return (
    <Container className={`${error && "warning"}`}>
      <MuiAutocomplete
        inputValue={inputValue}
        key={value}
        className="autocomplete-custom-field"
        onChange={(_, data) => onSelectChange?.(data)}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        getOptionLabel={(option: Option) => option.label ?? ""}
        onInputChange={onInputChange}
        multiple={false}
        value={autocompleteValue}
        noOptionsText={Boolean(noOptionContent) && !isLoading && <AutocompleteMessageOptions {...noOptionContent} />}
        loadingText={<AutocompleteLoader description={loadingText} />}
        loading={isLoading}
        filterOptions={filterOptions}
        renderInput={(params) => (
          <TextField
            variant="filled"
            value={value}
            {...params}
            label={label}
            InputProps={{
              ...params.InputProps,
            }}
          />
        )}
        options={options}
        renderOption={(props, option) => (
          <RenderOption key={option?.value} renderOptionProps={props} option={option} icon={renderIcon?.(option)} />
        )}
        popupIcon={<Icons name="IconChevronDown" fill="transparent" />}
        PopperComponent={(props) => <Popper {...props} className={"select-field-popper-custom"} />}
      />
    </Container>
  );
};
