import { FlexContainer as StyledFlexContainer } from "./styled";

type FlexContainerProps = {
  children: React.ReactNode;
  orientation: "horizontal" | "vertical";
};

export const FlexContainer = (props: FlexContainerProps) => {
  return (
    <StyledFlexContainer orientation={props.orientation} className="FlexContainer">
      {props.children}
    </StyledFlexContainer>
  );
};
