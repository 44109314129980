import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const Container = styled.div<{ formToggle?: boolean }>`
  height: 100%;
  padding-left: ${(props) => (props.formToggle ? "83px" : "0")};
  padding-right: ${(props) => (props.formToggle ? "83px" : "0")};
`;

export const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: ${(props) => props.theme.spacings.s};
`;

export const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacings.xs};
  width: 100%;
`;

export const Card = styled.div`
  border: 1px solid ${(props) => props.theme.colors.neutral[90]};
  border-radius: 12px;
`;

export const WarningCard = styled.div<{ color: string }>`
  display: flex;
  padding: ${(props) => props.theme.spacings.xs2};
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;

  border-radius: ${(props) => props.theme.spacings.xs4};
  border: 1px solid ${({ color }) => color};
`;

export const CardTile = styled.div<{ bottomBorder?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacings.xs3};
  border-bottom-width: ${({ bottomBorder }) => (bottomBorder ? "1px" : undefined)};
  border-bottom-style: solid;
  border-bottom-color: ${(props) => props.theme.colors.neutral[90]};
  padding-top: 20px;
  padding-bottom: ${(props) => props.theme.spacings.xs2};
  padding-left: 20px;
  padding-right: 20px;

  @media (max-width: 768px) {
    flex-direction: row;
    flex-wrap: wrap;
  }

  @media (max-width: 1150px) {
    overflow: scroll;
  }
`;

export const Form = styled.div`
  width: 100%;
  padding: 20px 16px 20px 16px;
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacings.xs2};
`;

export const InfoCardCaption = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${(props) => props.theme.spacings.xs4};
  align-items: center;
`;

export const FormToggleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  width: 100%;
`;

export const ReadonlyTextInput = styled.div`
  width: 100%;
  cursor: not-allowed;
  user-select: none;
  display: flex;
  padding: ${(props) => props.theme.spacings.xs3};
  align-items: center;
  gap: ${(props) => props.theme.spacings.xs4};
  flex: 1 0 0;
  align-self: stretch;

  border-radius: ${(props) => props.theme.spacings.xs3};
  border: 1px solid ${(props) => props.theme.colors.neutral[90]};
`;

export const CardTitle = styled(Typography)`
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  @media (max-width: 1550px) {
    width: 300px;
  }

  @media (max-width: 1500px) {
    width: 250px;
  }

  @media (max-width: 1400px) {
    width: 200px;
  }

  @media (max-width: 1300px) {
    width: 150px;
  }

  @media (max-width: 1200px) {
    width: 100px;
  }
`;

export const StepperCard = styled.div``;

export const WarningCardSubtitle = styled.div`
  max-width: 450px;
`;
