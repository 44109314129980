import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    max-width: 1440px;
    display: grid;
    grid-template-columns: 560px auto;
    gap: ${({ theme }) => theme.spacings.xs4};
    border-radius: ${({ theme }) => theme.borders.radius.l};
    background-color:  ${({ theme }) => theme.colors.secondary[99]};
    margin ${({ theme }) => theme.spacings.xs2} 0;
    align-items: center;

    @media screen and (max-width: 1200px) {
        display: flex;
        flex-direction: column-reverse;z
    }
`;

export const Content = styled.div`
  padding: ${({ theme }) => theme.spacings.s};
  width: 100%;
`;

export const HeaderContainer = styled.div`
    border-bottom: ${({ theme }) => `${theme.borders.width.xs2} solid ${theme.colors.secondary[95]}`};
    padding-bottom: ${({ theme }) => theme.spacings.xs};
    margin-bottom: ${({ theme }) => theme.spacings.xs};
    gap: ${({ theme }) => theme.spacings.xs5}; 0;
    flex-wrap: wrap;
`;

export const BodyContainer = styled.div`
  gap: ${({ theme }) => theme.spacings.xs5} 0;
  flex-wrap: wrap;

  button {
    padding-top: ${({ theme }) => theme.spacings.xs2};
  }
`;

export const BodyContainerTitle = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs5};
`;

export const BodyContainerSubtitle = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs2};
  flex-wrap: wrap;

  p {
    width: 100%;
  }
`;

export const FeatureHighlightContainer = styled.div`
  display: flex;
  gap: 6px;
  margin: ${({ theme }) => `${theme.spacings.xs1} 0`};
`;

export const ImageContainer = styled.div`
  display: flex;
  width: 100%;
  max-height: 370px;
  padding: 60px;
  align-items: center;
`;

export const Image = styled.img`
  width: 100%;
  height: auto;
  margin: 0 auto;
`;
