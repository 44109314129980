// Adapted from https://github.com/flash-tecnologia/hros-web-expense-management/tree/03163e336fbab26e418863ef24f5cd62887ca47e/src/components/organisms/Establishments/Establishments.tsx
import { Icons, TextField } from "@flash-tecnologia/hros-web-ui-v2";
import {
  AutocompleteInputChangeReason,
  FilterOptionsState,
  Autocomplete as MuiAutocomplete,
  Popper,
} from "@mui/material";

import { AutocompleteLoader } from "./AutocompleteLoader";
import { AutocompleteMessageOptions, MessageOptionsProps } from "./AutocompleteMessageOptions";
import { RenderOption } from "./RenderOption";
import { Container } from "./styled";

export type Option = {
  caption?: string;
  label?: string;
  value: string | number;
};

export type AutocompleteProps = {
  disabled?: boolean;
  error?: boolean;
  filterOptions?: (options: Option[], state: FilterOptionsState<Option>) => Option[];
  inputValue?: string;
  isLoading?: boolean;
  label: string;
  loadingText?: string;
  noOptionContent?: MessageOptionsProps;
  onInputChange?: (event: React.SyntheticEvent, value: string, reason: AutocompleteInputChangeReason) => void;
  onSelectChange?: (value: Option | null) => void;
  options: Option[];
  renderIcon?: (option: Option) => JSX.Element;
  value?: Option | null;
};

export const Autocomplete = ({
  disabled,
  error,
  filterOptions,
  inputValue,
  isLoading,
  label,
  loadingText,
  noOptionContent,
  onInputChange,
  onSelectChange,
  options,
  renderIcon,
  value,
}: AutocompleteProps) => {
  return (
    <Container className={`${error && "warning"}`}>
      <MuiAutocomplete
        disabled={disabled}
        className="autocomplete-custom-field"
        inputValue={inputValue}
        onInputChange={onInputChange}
        value={value}
        onChange={(_, data) => onSelectChange?.(data)}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        getOptionLabel={(option: Option) => option.label ?? ""}
        multiple={false}
        noOptionsText={Boolean(noOptionContent) && !isLoading && <AutocompleteMessageOptions {...noOptionContent} />}
        loadingText={<AutocompleteLoader description={loadingText} />}
        loading={isLoading}
        filterOptions={filterOptions}
        renderInput={(params) => (
          <TextField
            variant="filled"
            value={value}
            {...params}
            label={label}
            InputProps={{
              ...params.InputProps,
            }}
          />
        )}
        options={options}
        renderOption={(props, option) => (
          <RenderOption key={option?.value} renderOptionProps={props} option={option} icon={renderIcon?.(option)} />
        )}
        popupIcon={<Icons name="IconChevronDown" fill="transparent" />}
        PopperComponent={(props) => <Popper {...props} className={"select-field-popper-custom"} />}
      />
    </Container>
  );
};
