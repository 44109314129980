import { AuthService } from "$frontend/services";
import { TrackingService } from "$frontend/services/TrackingService";
import { SurveyStore } from "@flash-tecnologia/hros-web-ui-v2";
import { useTranslation } from "react-i18next";

type FeedbackData = {
  rating: number;
  acceptedTeamEvaluation: boolean | undefined;
  message: string;
  answers: Record<"AccountabilityEasiness" | "AccountabilityAttendance", number>;
};

export function AccountabilitySurvey() {
  const company = AuthService.useCompany();
  const employeeId = AuthService.useEmployeeId();

  const { t } = useTranslation("translations", {
    keyPrefix: "molecules.accountabilitySurvey",
  });

  function handleFeedback(data: FeedbackData) {
    if (company?.id && employeeId) {
      TrackingService.track(
        "survey_expense_accountability_submitted",
        {
          experience_feedback: data.message,
          flow_easy_to_use: String(data.answers.AccountabilityEasiness),
          flow_meets_needs: String(data.answers.AccountabilityAttendance),
          conversation_invitation: String(data.acceptedTeamEvaluation),
        },
        {
          employeeId,
          companyId: company.id,
        },
      );

      return true;
    }

    return false;
  }
  return (
    <SurveyStore
      questions={[
        {
          id: "AccountabilityEasiness",
          title: t("AccountabilityEasiness"),
          scores: [t("rates.terrible"), t("rates.bad"), t("rates.regular"), t("rates.good"), t("rates.great")],
        },
        {
          id: "AccountabilityAttendance",
          title: t("AccountabilityAttendance"),
          scores: [t("rates.notRespond"), null, t("rates.partiallyRespond"), null, t("rates.totallyRespond")],
        },
      ]}
      onSubmit={handleFeedback}
    />
  );
}
