import { Button, Icons, LinkButton } from "@flash-tecnologia/hros-web-ui-v2";
import { useEffect, useState } from "react";
import { FieldErrors, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

import { trpc } from "$client";
import { useDefaultCostCenter } from "$containers/costCenter/hooks/useDefaultCostCenter";
import { ExpenseHeader } from "$frontend/components/molecules/ExpenseHeader";
import { Routes } from "$frontend/routes";
import { isExpenseSubmitted } from "$frontend/shared/functions";
import { handleTrcpError, useDisplayToast } from "$frontend/utils";
import { FinishedPendingItemsList, FocusedContainer, FocusedFooter, PendingItemsForm } from "$molecules";
import { Expense, ExpenseStatus, ExpenseType } from "$serverTypes";
import { useCheckActiveModule } from "@flash-tecnologia/expense-web-activation-utils";
import { ExpenseFormDto } from "../ReimbursementCreate";

const useCurrentExpenseId = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentExpenseId, setCurrentExpenseId] = useState(searchParams.get("expenseId"));
  useEffect(() => {
    if (currentExpenseId != null) {
      setSearchParams({ ...searchParams, expenseId: currentExpenseId });
    }
  }, [currentExpenseId]);
  return [currentExpenseId, setCurrentExpenseId] as const;
};

export const StatementPendingItemsPage = () => {
  const query = {
    status: [ExpenseStatus.DRAFT, ExpenseStatus.REQUIRE_CHANGES],
    type: ExpenseType.CORPORATE_CARD,
  };
  const { data: listExpensesResponse, isFetching: isFetchingExpenses } = trpc.expense.listMe.useQuery(query, {
    initialData: {
      expenses: [],
      totalCount: 0,
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
  const [updatedExpenses, setUpdatedExpenses] = useState<Record<string, Expense>>({});
  const expenses = listExpensesResponse.expenses.map((expense) => updatedExpenses[expense.id] ?? expense);
  const { mutateAsync: patchExpense, isLoading: isLoadingPatchExpense } = trpc.expense.patchExpense.useMutation();
  const { mutateAsync: updateExpenseStatus, isLoading: isSendingExpenseToAccount } =
    trpc.expense.updateSingleStatus.useMutation();
  const navigate = useNavigate();
  const { t } = useTranslation("translations", { keyPrefix: "pages.pendingsItems" });
  const { displayToast } = useDisplayToast();

  const [currentExpenseId, setCurrentExpenseId] = useCurrentExpenseId();
  const currentExpense = expenses.find(({ id }) => id == currentExpenseId);
  const isCurrentExpenseSubmitted = currentExpense && isExpenseSubmitted(currentExpense);
  const submittedCount = expenses.reduce((acc, expense) => (isExpenseSubmitted(expense) ? acc + 1 : acc), 0);
  const { mutateAsync: sendToApproval, isLoading: isLoadingSendToApproval } =
    trpc.expense.approvals.sendToApproval.useMutation();
  const hasApprovalModule = useCheckActiveModule("approval");

  const isLoading = isFetchingExpenses || isLoadingPatchExpense || isSendingExpenseToAccount || isLoadingSendToApproval;

  if (!isLoading && !expenses?.length) {
    navigate(Routes.STATEMENT);
  }

  const { data: defaultCostCenter } = useDefaultCostCenter();

  const allExpensesAreSubmitted = !isLoading && expenses.length == submittedCount;
  const currentExpenseFormData = currentExpense && {
    attachments: currentExpense.attachments ?? [],
    category: currentExpense.category,
    comments: currentExpense.comments ?? "",
    costCenter: currentExpense.costCenter ?? defaultCostCenter,
    amount: currentExpense.amount,
    date: new Date(currentExpense.date),
  };
  const methods = useForm<ExpenseFormDto>({
    defaultValues: currentExpenseFormData,
    values: currentExpenseFormData,
  });
  const unchagedForm = !methods.formState.isDirty;

  if (!currentExpense && expenses.length > 0) {
    const nextExpense = expenses.find((expense) => !isExpenseSubmitted(expense));
    if (nextExpense) {
      onExpenseChange(nextExpense);
    }
  }

  async function checkUnsavedChanges() {
    if (unchagedForm || !currentExpense) {
      return true;
    }

    await onSubmit(methods.getValues(), false);

    return true;
  }

  function goToNextExpense() {
    if (allExpensesAreSubmitted) {
      return;
    }

    const currentExpenseIndex = currentExpense ? expenses.indexOf(currentExpense) : 0;
    const startIndex = currentExpenseIndex === expenses.length - 1 ? 0 : currentExpenseIndex + 1;

    const nextExpense = expenses.slice(startIndex).find((expense) => !isExpenseSubmitted(expense));
    if (nextExpense) {
      setCurrentExpenseId(nextExpense.id);
    }
  }

  async function onSubmit(formData: ExpenseFormDto, shouldSubmit: boolean) {
    if ((unchagedForm && !shouldSubmit) || !currentExpenseId || !currentExpense) {
      return;
    }

    const fieldsToPatch = {
      attachments: formData.attachments,
      comments: formData.comments,
      category: formData.category,
      costCenter: formData.costCenter,
    };

    try {
      await patchExpense([currentExpenseId, fieldsToPatch]);
      if (shouldSubmit && !hasApprovalModule) {
        await updateExpenseStatus({
          expenseId: currentExpenseId,
          targetStatus: ExpenseStatus.PENDING_ACCOUNTING,
        });
        goToNextExpense();
      }
      if (shouldSubmit && hasApprovalModule) {
        const approvalData = await sendToApproval({ ids: [currentExpenseId] });
        if (!approvalData.success) {
          displayToast({
            type: "error",
            title: t("toasts.failedSubmit.title"),
            description: t("toasts.failedSubmit.description"),
          });
        } else {
          displayToast({
            title: t("toasts.successSubmit.title"),
            description: t("toasts.successSubmit.description"),
          });
        }
        goToNextExpense();
      } else {
        displayToast({
          title: t(shouldSubmit ? "toasts.successSubmit.title" : "toasts.successEdit.title"),
          description: t(shouldSubmit ? "toasts.successSubmit.description" : "toasts.successEdit.description"),
        });
      }

      const nextStatus = hasApprovalModule ? ExpenseStatus.PENDING_APPROVAL : ExpenseStatus.PENDING_ACCOUNTING;

      const updatedExpense = {
        ...currentExpense,
        ...fieldsToPatch,
        status: shouldSubmit ? nextStatus : currentExpense.status,
      };
      setUpdatedExpenses({ ...updatedExpenses, [currentExpenseId]: updatedExpense });
    } catch (e) {
      const description = handleTrcpError(e, (errorCode) =>
        t(`toasts.failedEdit.messages.${errorCode}`, {
          defaultValue: null,
        }),
      );

      displayToast({
        type: "error",
        title: t("toasts.failedEdit.title"),
        description,
      });
    }
  }

  function onInvalidFields(errors: FieldErrors<ExpenseFormDto>) {
    const fields = Object.keys(errors).map((field) => t(`fields.${field}`));
    displayToast({
      type: "error",
      title: t("toasts.requiredField.title"),
      description: t("toasts.requiredField.description", { fields, count: fields.length }),
    });
  }

  async function onExpenseChange(expense: Expense) {
    if (await checkUnsavedChanges()) {
      setCurrentExpenseId(expense.id);
    }
  }

  async function onExitAndSave() {
    methods.handleSubmit(async (formData) => {
      await onSubmit(formData, false);
      navigate(Routes.STATEMENT);
    }, onInvalidFields)();
  }

  const header = (
    <ExpenseHeader onClick={() => navigate(Routes.STATEMENT)} backDescription={t("back")} title={t("title")} />
  );

  const footer = (
    <FocusedFooter
      start={[
        <LinkButton variant="neutral" key={2} onClick={() => onExitAndSave()}>
          {t("exitAndSave")}
        </LinkButton>,
      ]}
      end={[
        <Button
          variant="secondary"
          size="small"
          key={1}
          onClick={async () => {
            if (await checkUnsavedChanges()) {
              goToNextExpense();
            }
          }}
        >
          {t("skip")}
        </Button>,
        <Button
          loading={isFetchingExpenses}
          variant="primary"
          size="small"
          key={2}
          onClick={methods.handleSubmit((formData) => onSubmit(formData, true), onInvalidFields)}
          disabled={isCurrentExpenseSubmitted}
        >
          {isFetchingExpenses
            ? t("submit.onLoading")
            : t("submit.description", { submittedCount, totalExpenses: expenses.length })}
          <Icons name="IconCheck" />
        </Button>,
      ]}
    />
  );

  if (allExpensesAreSubmitted) {
    return (
      <FocusedContainer
        header={header}
        footer={
          <FocusedFooter
            middle={[
              <Button
                style={{ width: "128px" }}
                variant="secondary"
                size="medium"
                key={1}
                variantType="neutral"
                onClick={() => navigate(Routes.STATEMENT)}
              >
                {t("close")}
                <Icons name="IconCheck" />
              </Button>,
            ]}
            start={[]}
            end={[]}
          />
        }
      >
        <FinishedPendingItemsList isLoading={isLoading} expenses={expenses} />
      </FocusedContainer>
    );
  }

  return (
    <FocusedContainer header={header} footer={footer}>
      <PendingItemsForm
        expenses={expenses}
        isLoading={isLoading}
        methods={methods}
        onExpenseChange={onExpenseChange}
        submittedCount={submittedCount}
        currentExpense={currentExpense}
      />
    </FocusedContainer>
  );
};
