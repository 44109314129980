import { SurveyStore } from "@flash-tecnologia/hros-web-ui-v2";
import { getFromLS, setInLS } from "@flash-tecnologia/hros-web-utility";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { AuthService } from "$frontend/services";
import { TrackingService } from "$frontend/services/TrackingService";
import { Alignment } from "$frontend/utils";
import { ExpenseType } from "$serverTypes";

import { SurveyModal } from "./styled";

interface Props {
  /**
   * On close modal
   */
  onClose?: () => void;

  /**
   * Specified expense type
   */
  expenseType: ExpenseType;
}

type FeedbackData = {
  rating: number;
  acceptedTeamEvaluation: boolean | undefined;
  message: string;
  answers: Record<"FinishEasiness", number>;
};

export const FinishExpenseSurveyModal = ({ onClose, expenseType }: Props) => {
  const { t } = useTranslation("translations", {
    keyPrefix: `organisms.expensesStatusUpdateModal.survey.${expenseType}`,
  });

  const getKey = useCallback(() => {
    const type = expenseType === ExpenseType.REIMBURSEMENT ? "reimbursement" : "accountability";

    return `survey_expense_finish_${type}_submitted`;
  }, [expenseType]);

  const company = AuthService.useCompany();
  const employeeId = AuthService.useEmployeeId();

  useEffect(() => {
    const isAnswered = getFromLS(getKey());

    if (isAnswered) {
      onClose?.();
    }
  }, [getKey, onClose]);

  function handleFeedback(data: FeedbackData) {
    if (company?.id && employeeId) {
      TrackingService.track(
        getKey(),
        {
          experience_feedback: data.message,
          flow_easy_to_use: String(data.answers.FinishEasiness),
          conversation_invitation: String(data.acceptedTeamEvaluation),
        },
        {
          employeeId,
          companyId: company.id,
        },
      );

      handleClose();

      return true;
    }

    handleClose();

    return false;
  }

  function handleClose() {
    setInLS({ key: getKey(), value: true });
    onClose?.();
  }

  return (
    <SurveyModal
      open
      iconPosition={Alignment.left}
      headerIconLabel={t("headerSubtitle")}
      headerTitle={t("headerTitle")}
      labelButtonAction={t("close")}
      actionButtonProps={{ variantType: "neutral", variant: "secondary" }}
      showCancelButton={false}
      headerIconMode="small"
      onCloseClick={handleClose}
      onCancelClick={handleClose}
      footerCustom={null}
      onActionClick={handleClose}
    >
      <SurveyStore
        questions={[
          {
            id: "FinishEasiness",
            title: t("easiness"),
          },
        ]}
        onSubmit={handleFeedback}
      />
    </SurveyModal>
  );
};
