import { trpc } from "$client";
import { Routes } from "$frontend/routes";
import { dispatchToast } from "$frontend/utils/dispatch-events";
import { ExpenseStatus, ExpenseType } from "$serverTypes";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export function useRejectExpense(type: ExpenseType) {
  const { t } = useTranslation("translations", {
    keyPrefix: type === ExpenseType.CORPORATE_CARD ? "pages.adminMovement" : "pages.adminReimbursement",
  });
  const navigate = useNavigate();
  const { mutate, isLoading: isRejecting } = trpc.expense.updateSingleStatus.useMutation({
    onSuccess: () => {
      dispatchToast({
        type: "success",
        content: t("toasts.rejectSuccess.title"),
      });

      if (type === ExpenseType.CORPORATE_CARD) {
        navigate(Routes.ADMIN_STATEMENT);
      } else {
        navigate(Routes.ADMIN_REIMBURSEMENTS);
      }
    },
    onError: () => {
      dispatchToast({
        type: "error",
        content: t("toasts.rejectError.title"),
        description: t("toasts.rejectError.description"),
      });
    },
  });

  function rejectExpense(id: string, reason: string) {
    if (!reason) {
      dispatchToast({
        type: "error",
        content: t(`toasts.rejectCommentRequired.title`),
        description: t(`toasts.rejectCommentRequired.description`),
      });
      return;
    }
    mutate({
      expenseId: id,
      targetStatus: ExpenseStatus.REJECTED,
      accountingComments: reason,
    });
  }

  return { rejectExpense, isRejecting };
}
