import { IconsProps, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { useTranslation } from "react-i18next";
import { HighlightCard } from "./HighlightCard";
import { HighlightCardProps } from "./HighlightCard/HighlightCard";
import { GridContainer } from "./styled";

export const ReimbursementHighlightCards = () => {
  const { t } = useTranslation("translations", {
    keyPrefix: "pages.reimbursements.enableReimbursementSection",
  });

  const highlightCards: Array<Omit<HighlightCardProps, "iconName"> & { iconName: IconsProps["name"] }> = [
    {
      title: t("cards.trackReimbursements.title"),
      description: t("cards.trackReimbursements.description"),
      iconName: "IconRotateClockwise2",
    },
    {
      title: t("cards.signalInconsistencies.title"),
      description: t("cards.signalInconsistencies.description"),
      iconName: "IconEyeCheck",
    },
    {
      title: t("cards.easyControl.title"),
      description: t("cards.easyControl.description"),
      iconName: "IconReceipt2",
    },
  ];

  return (
    <>
      <Typography variant="headline8" color="neutral.30">
        {t("cards.title")}
      </Typography>
      <GridContainer>
        {highlightCards.map((card, index) => (
          <HighlightCard
            key={index}
            title={card.title}
            description={card.description}
            iconName={card.iconName as "IconReceipt2"}
          />
        ))}
      </GridContainer>
    </>
  );
};
