import { IconsProps, ShapeIcon, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { Container } from "./styled";

type FeatureHighlightProps = {
  iconName: IconsProps["name"];
  children: string;
};

export function FeatureHighlight({ iconName, children }: FeatureHighlightProps) {
  return (
    <Container>
      <ShapeIcon name={iconName} size={24} variant="default" stroke="default" />
      <Typography variant="caption" color="neutral.40" weight={400}>
        {children}
      </Typography>
    </Container>
  );
}
