import { trpc } from "$client";
import { useParams } from "react-router-dom";

export function useExpense() {
  const { expenseId } = useParams();

  const {
    data: expense,
    isFetching,
    refetch,
  } = trpc.expense.getExpenseById.useQuery(expenseId || "", { enabled: !!expenseId });

  return {
    expense,
    isLoading: isFetching,
    refetch,
  };
}
