import { trpc } from "$client";
import { ExpenseStatus, ExpenseType } from "$serverTypes";
import { DateRange, getDateRange } from "$utils";

const yearAgoRange = getDateRange(12);

/**
 * Performs either `trpc.expense.getSummary` or `trpc.expense.getSummaryMe` based on isAdmin flag
 * and computes remaining expenses count for the specified target statuses by performing
 * another summary query in a year-long range from now and comparing with the first query.

 *
 * This hook is useful when using a DatePeriodMenu in conjunction with alertBadgeTooltip prop so that
 * the user can be alerted when there are expenses in any of the target statuses **outside** of the
 * date range they are querying for.
 * */
export function useGetSummaryWithOutsideDateRangeCount({
  employeeId,
  type,
  status,
  targetStatus,
  dateRange,
  isAdmin,
}: {
  employeeId?: string;
  status: ExpenseStatus[];
  targetStatus: ExpenseStatus[];
  type: ExpenseType;
  dateRange: DateRange;
  isAdmin: boolean;
}) {
  const queryParams = {
    employeeId,
    status,
    type,
    initialDate: dateRange.from.toISOString(),
    endDate: dateRange.to.toISOString(),
  };

  const yearLongQueryParams = {
    employeeId,
    status: targetStatus,
    type,
    initialDate: yearAgoRange.from.toISOString(),
    endDate: yearAgoRange.to.toISOString(),
  };

  const summaryQuery = isAdmin
    ? trpc.expense.getSummary.useQuery(queryParams, { initialData: { summaries: [] } })
    : trpc.expense.getSummaryMe.useQuery(queryParams, { initialData: { summaries: [] } });

  const yearLongQuery = isAdmin
    ? trpc.expense.getSummary.useQuery(yearLongQueryParams, { initialData: { summaries: [] } })
    : trpc.expense.getSummaryMe.useQuery(yearLongQueryParams, { initialData: { summaries: [] } });

  const { data: summaryData, isFetching: isSummaryLoading, refetch: refetchSummary } = summaryQuery;

  const { data: yearLongData, isFetching: yearLongDataIsFetching } = yearLongQuery;

  const currentTargetStatusCount = targetStatus.reduce((total, targetStat) => {
    const count = summaryData.summaries.find(({ status }) => status === targetStat)?.count ?? 0;
    return total + count;
  }, 0);

  const yearLongTargetStatusCount = targetStatus.reduce((total, targetStat) => {
    const count = yearLongData.summaries.find(({ status }) => status === targetStat)?.count ?? 0;
    return total + count;
  }, 0);
  const remainingAwaitingAccountExpensesCount = yearLongTargetStatusCount - currentTargetStatusCount;

  return {
    data: summaryData,
    isFetching: yearLongDataIsFetching || isSummaryLoading,
    refetch: refetchSummary,
    remainingAwaitingAccountExpensesCount,
  };
}
