import { Spinner } from "@flash-tecnologia/hros-web-ui-v2"; // TODO passar pelo styled ou criar componente
import { useEffect, useState } from "react";
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";

import { useTranslation } from "react-i18next";
import { ReceiptApiClient } from "$frontend/services/receipt-api-client";
import { useDisplayToast } from "$utils";
import { Carousel, PdfReader } from "$molecules";
import { Attachment } from "$serverTypes";
import {
  DescriptionLoading,
  Image,
  ImageContainer,
  LoaderContainer,
  LoaderContent,
  ModalBackgroundMask,
  ModalButtonClose,
  ModalContainer,
  ModalContent,
} from "./styled";

export type ImagesModalProps = {
  /** Attachments to load if receiptsSrc is not provided */
  attachments?: Attachment[];
  /** Preloaded receipt sources */
  receiptsSrc?: string[];
  /** Whether to show dots for carousel navigation */
  showDots?: boolean;
  /** Loading description text */
  loadingDescription?: string;
  /** Callback for closing the modal */
  onCloseModalClick: () => void;
  /** Initial index for the carousel */
  initialIndex?: number;
};

export const ImagesModal = ({
  attachments = [],
  loadingDescription,
  showDots = false,
  onCloseModalClick,
  initialIndex,
  receiptsSrc: propsReceiptsData,
}: ImagesModalProps) => {
  const { selectedCompany } = useSelectedCompany();

  const [loading, setLoading] = useState(false);
  const [receiptsSrc, setReceiptsSrc] = useState<Array<{ fileName: string; url: string }>>(
    (propsReceiptsData ?? []).map((url, index) => ({ fileName: `file-${index}`, url })),
  );
  const { displayToast } = useDisplayToast();
  const { t } = useTranslation("translations", { keyPrefix: "molecules.imagesModal" });

  useEffect(() => {
    onInit();
  }, []);

  const isPdf = (fileName: string) => fileName.endsWith(".pdf");

  async function onInit() {
    const noAttachments = attachments.length === 0;
    const alreadyHasData = receiptsSrc.length > 0;
    if (noAttachments || alreadyHasData) {
      return;
    }

    setLoading(true);
    try {
      const loadedSrc = await Promise.all(
        attachments.map(async (attachment) => ({
          fileName: attachment.fileName,
          url: URL.createObjectURL(await ReceiptApiClient.getFile(attachment, selectedCompany.id)),
        })),
      );
      setReceiptsSrc(loadedSrc);
    } catch (e) {
      displayToast({
        type: "error",
        title: t("toasts.failedLoadingReceipts.title"),
        description: t("toasts.failedLoadingReceipts.description"),
      });
      onCloseModalClick();
    } finally {
      setLoading(false);
    }
  }

  return (
    <ModalContainer>
      <ModalBackgroundMask />
      <ModalContent>
        <ModalButtonClose
          key="close-button-modal-images"
          onClick={onCloseModalClick}
          icon="IconX"
          variant="filled"
          size="small"
        />
        {loading ? (
          <LoaderContainer>
            <LoaderContent>
              <Spinner size={48} variant="primary" />
              {loadingDescription && <DescriptionLoading variant="body3">{loadingDescription}</DescriptionLoading>}
            </LoaderContent>
          </LoaderContainer>
        ) : (
          <Carousel
            items={receiptsSrc.map(({ fileName, url }, index) => (
              <ImageContainer key={`image-receipt-${index}`}>
                {isPdf(fileName) ? <PdfReader filename={url} title={t("pdfAlt")} /> : <Image src={url} />}
              </ImageContainer>
            ))}
            showDots={showDots}
            initialIndex={initialIndex}
          />
        )}
      </ModalContent>
    </ModalContainer>
  );
};
