import Flex from "$frontend/components/atoms/Flex/Flex";
import Grid from "$frontend/components/atoms/Grid/Grid";
import styled from "styled-components";

export const StyledFlex = styled(Flex)`
  border-top: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  padding-top: ${({ theme }) => theme.spacings.xs2};
`;

export const Content = styled(Grid)`
  padding-bottom: ${({ theme }) => theme.spacings.l};
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${(props) => props.theme.colors.neutral[100]};
  width: fill-available !important;
  position: fixed;
  bottom: 0;
  z-index: 1300;
  box-shadow: 20px 4px 26px rgba(0, 0, 0, 0.1);
`;

export const Banner = styled(Grid)`
  background-image: url("https://images.flashapp.com.br/expense/expense-lifecycle/plg-banner.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: right;
`;
