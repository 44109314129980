import imageCompression from "browser-image-compression";

/**
 * ImageCompressionService - A service to handle image compression.
 * This abstracts the compression logic, allowing easy replacement of libraries in the future.
 */
export class FileCompressionService {
  /**
   * Checks if a file is an image based on its MIME type.
   *
   * @param file The file to check
   * @returns True if the file is an image, false otherwise
   */
  static isImage(file: File) {
    return file.type.startsWith("image/");
  }

  /**
   * Compress an image using the configured compression algorithm.
   * If compression fails, it returns the original file.
   *
   * @param file The file to process
   * @returns A new compressed File object or the original file if compression fails
   */
  static async compressImage(file: File) {
    if (!this.isImage(file)) {
      throw new Error("Only image files can be compressed.");
    }

    try {
      const options = {
        maxSizeMB: 5,
        maxWidthOrHeight: 1024,
        initialQuality: 0.7,
        useWebWorker: true,
      };

      return await imageCompression(file, options);
    } catch (error) {
      console.error(`Image Compression Error: ${error instanceof Error ? error.message : "Unknown error occurred."}`);
      return file;
    }
  }
}
