import { Typography } from "$atoms";
import styled from "styled-components";

export const Toolbar = styled.div`
  display: flex;
  padding: ${(props) => props.theme.spacings.xs};
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

export const ToolbarButtonGroup = styled.div`
  gap: ${(props) => props.theme.spacings.s};
`;

export const Container = styled.div`
  margin-top: ${(props) => props.theme.spacings.xs};
  .custom-table > div {
    border-radius: 0px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
`;

//TODO remover? não utilizado no MovementTable.tsx
export const ActionContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
`;

//TODO remover? não utilizado no MovementTable.tsx
export const ActionButton = styled.div<{ show?: boolean }>`
  flex: float;
  display: ${({ show }) => ((show ?? true) ? "block" : "none")};
`;

//TODO remover? não utilizado no MovementTable.tsx
export const Header = styled.thead`
  width: 100%;
`;

//TODO remover? não utilizado no MovementTable.tsx
export const TableHeader = styled.th<{
  sticky?: boolean;
  width?: number;
  maxWidth?: number;
  minWidth?: number;
  align?: string;
}>`
  position: ${({ sticky }) => (sticky ? "sticky" : "unset")};
  background-color: ${(p) => p.theme.colors.neutral[95]};
  width: ${({ width }) => (width ? `${width}px` : "unset")};
  max-width: ${({ maxWidth, width }) => (maxWidth && !width ? `${maxWidth}px` : "unset")};
  min-width: ${({ minWidth, width }) => (minWidth && !width ? `${minWidth}px` : "unset")};
  padding: 24px;
  text-align: ${({ align }) => align ?? "left"};
  &:after {
    top: 0;
    left: -30px;
    width: 30px;
    height: 100%;
    content: "";
    position: absolute;
    box-shadow: inset -10px 0 8px -8px rgba(5, 5, 5, 0.06);
  }
`;

export const DescriptionCaptionContainer = styled.div`
  display: flex;
  gap: ${(props) => props.theme.spacings.xs4};
  align-items: center;
`;

export const Caption = styled(Typography).attrs({
  variant: "body4",
  weight: 400,
})`
  color: ${(props) => props.theme.colors.neutral[40]};
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: anywhere;
`;

export const EmptyCell = styled(Typography).attrs({
  weight: 600,
  variant: "body4",
  children: "_",
})`
  color: ${(props) => props.theme.colors.neutral[60]};
  text-align: center;
`;

export const EmptyAction = styled.div`
  display: flex;
  justify-content: center;
`;
