import { Button, Icons, IconTypes, LinkButton } from "@flash-tecnologia/hros-web-ui-v2";
import { FieldErrors, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Loader } from "$atoms";
import { useDisplayToast } from "$frontend/utils";
import { FocusedFooter } from "$molecules";
import { ExpenseFormDto } from "$pages";
import { ExpenseType } from "$serverTypes";
import { ExtraButtonSize, ExtraButtonVariant, ExtraButtonVariantType, FooterActions } from "./types";

type ExtraButtonsConfig = {
  id: string;
  text: string;
  icon?: IconTypes;
  variant?: ExtraButtonVariant;
  variantType?: ExtraButtonVariantType;
  size?: ExtraButtonSize;
  minWidth?: string;
  onClick: () => void;
  loading?: boolean;
  disabled?: boolean;
};

type ReimbursementFooterProps = {
  methods: UseFormReturn<ExpenseFormDto, Record<string, unknown>>;
  hasExpense: boolean;
  isLoading: boolean;
  onAddAnother?: (data: ExpenseFormDto) => void;
  onContinueClick?: (data: ExpenseFormDto) => void;
  onQuitAndSave?: (data: ExpenseFormDto) => void;
  extraButtonsConfig?: ExtraButtonsConfig[];
  /** Expense type (default REIMBURSEMENT) */
  type?: ExpenseType;
  isSummary?: boolean;
  summaryAction?: () => void;
  onCancelClick: () => void;
};

export function ExpenseFooterActions({
  methods,
  isLoading,
  hasExpense,
  onAddAnother,
  onContinueClick,
  onQuitAndSave,
  extraButtonsConfig,
  type = ExpenseType.REIMBURSEMENT,
  isSummary,
  summaryAction,
  onCancelClick,
}: ReimbursementFooterProps) {
  const { t } = useTranslation("translations", {
    keyPrefix: `molecules.expenseFooterActions.${type}`,
  });
  const { t: tAllTypes } = useTranslation("translations", {
    keyPrefix: `molecules.expenseFooterActions`,
  });

  const { displayToast } = useDisplayToast();

  function onInvalidFields(errors: FieldErrors<ExpenseFormDto>, action: FooterActions) {
    const fields = Object.keys(errors).map((field) => tAllTypes(`fields.${field}`));

    let descriptionKey: string;
    switch (action) {
      case FooterActions.QUIT_AND_SAVE:
        descriptionKey = "toasts.requiredField.quitAndSaveDescription";
        break;
      case FooterActions.PRIMARY_SAVE:
        descriptionKey = "toasts.requiredField.primarySaveDescription";
        break;
      default:
        return;
    }

    displayToast({
      type: "error",
      title: t("toasts.requiredField.title"),
      description: t(descriptionKey, { fields, count: fields.length }),
    });
  }

  if (isSummary) {
    return (
      <FocusedFooter
        middle={[
          <Button variant="secondary" variantType="neutral" onClick={summaryAction}>
            Fechar <Icons name="IconCheck" />
          </Button>,
        ]}
      />
    );
  }

  return (
    <>
      <FocusedFooter
        start={[
          <>
            {onQuitAndSave ? (
              <LinkButton
                style={{ minWidth: "100px" }}
                variant="neutral"
                onClick={methods.handleSubmit(onQuitAndSave, (errors) =>
                  onInvalidFields(errors, FooterActions.QUIT_AND_SAVE),
                )}
              >
                {t("exitAndSave")}
              </LinkButton>
            ) : (
              <LinkButton style={{ minWidth: "100px" }} variant="neutral" onClick={onCancelClick}>
                {t("cancel")}
              </LinkButton>
            )}
          </>,
        ]}
        end={[
          <>
            {onAddAnother && (
              <Button
                minWidth="175px"
                variant="secondary"
                size="small"
                onClick={methods.handleSubmit(onAddAnother, (errors) =>
                  onInvalidFields(errors, FooterActions.PRIMARY_SAVE),
                )}
                disabled={isLoading}
              >
                {t("addAnother")}
              </Button>
            )}
          </>,
          <div style={{ display: "flex", gap: "8px" }}>
            {extraButtonsConfig &&
              extraButtonsConfig.length > 0 &&
              extraButtonsConfig.map(({ id, text, icon, ...props }) => (
                <Button key={id} {...props}>
                  {text}
                  {icon && <Icons name={icon} fill="transparent" size={24} />}
                </Button>
              ))}
          </div>,
          <>
            {onContinueClick && (
              <Button
                variant="primary"
                size="small"
                type="submit"
                onClick={methods.handleSubmit(onContinueClick, (errors) =>
                  onInvalidFields(errors, FooterActions.PRIMARY_SAVE),
                )}
                loading={isLoading}
                disabled={isLoading}
                minWidth={hasExpense ? undefined : "200px"}
              >
                {hasExpense ? t("save") : t("requestReimbursement")}
              </Button>
            )}
          </>,
        ]}
      />
      <Loader show={isLoading} description={hasExpense ? t("updating") : t("creating")} />
    </>
  );
}
