import { useState } from "react";
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";

import { Attachment } from "$serverTypes";
import { ReceiptApiClient, ReceiptApiException, FileCompressionService } from "$frontend/services";
import { useDisplayToast } from "$utils";

type UseAttachmentsProps = {
  value: Attachment[];
  onChange: (attachments: Attachment[]) => void;
  applyOcr?: boolean;
  t: (key: string, options?: any) => string;
};

export const useAttachments = ({ value, onChange, applyOcr, t }: UseAttachmentsProps) => {
  const { selectedCompany } = useSelectedCompany();

  const { displayToast } = useDisplayToast();
  const [attachmentsSrc, setAttachmentsSrc] = useState<Record<string, string>>({});
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isReading, setIsReading] = useState(false);
  const [isWriting, setIsWriting] = useState(false);

  const updateAttachmentsSrc = (newBlobs: Record<string, string>) => {
    setAttachmentsSrc((prev) => ({ ...prev, ...newBlobs }));
  };

  async function onValuesUpdated() {
    setIsReading(true);
    const attachmentsToProcess = value.filter((attachment) => !attachmentsSrc[attachment.fileName]);
    try {
      const newBlobs = Object.fromEntries(
        await Promise.all(
          attachmentsToProcess.map(async (attachment) => {
            const fileBlob = await ReceiptApiClient.getFile(attachment, selectedCompany.id);
            return [attachment.fileName, URL.createObjectURL(fileBlob)];
          }),
        ),
      );
      updateAttachmentsSrc(newBlobs);
    } finally {
      setIsReading(false);
    }
  }

  function onDelete(indexToDelete: number) {
    onChange(value.filter((_, index) => index !== indexToDelete));
    setCurrentIndex(indexToDelete === 0 ? 0 : indexToDelete - 1);
  }

  async function onFilesChange(incomingFiles: File[]): Promise<{ attachment?: Attachment; originalFile?: File }> {
    setIsWriting(true);
    let incomingAttachments: Attachment[] = [];
    const shouldApplyOcr = applyOcr && value.length === 0;
    try {
      const compressedFiles = await Promise.all(
        incomingFiles.map(async (file) => {
          try {
            return FileCompressionService.isImage(file) ? await FileCompressionService.compressImage(file) : file;
          } catch (error) {
            return file;
          }
        }),
      );

      incomingAttachments = await Promise.all(
        compressedFiles.map((file, index) =>
          ReceiptApiClient.uploadFile(file, selectedCompany.id, shouldApplyOcr && index === 0),
        ),
      );
    } catch (e) {
      const description =
        e instanceof ReceiptApiException
          ? t(`toasts.uploadError.messages.${e.code}`, { defaultValue: null })
          : undefined;
      displayToast({
        type: "error",
        title: t("toasts.uploadError.title"),
        description,
      });
    } finally {
      setIsWriting(false);
    }

    if (incomingAttachments.length > 0) {
      const newValues = [...incomingAttachments, ...value];
      onChange(newValues);
      setCurrentIndex(newValues.length - 1);
      if (shouldApplyOcr) {
        return { attachment: incomingAttachments[0], originalFile: incomingFiles[0] };
      } else {
        updateAttachmentsSrc(
          Object.fromEntries(
            incomingAttachments.map((attachment, i) => [attachment.fileName, URL.createObjectURL(incomingFiles[i])]),
          ),
        );
      }
    }
    return {};
  }

  return {
    attachmentsSrc,
    currentIndex,
    isReading,
    isWriting,
    updateAttachmentsSrc,
    onValuesUpdated,
    onDelete,
    onFilesChange,
    setCurrentIndex,
  };
};
