import Flex from "$frontend/components/atoms/Flex/Flex";
import Grid from "$frontend/components/atoms/Grid/Grid";
import { FocusedFooter } from "$frontend/components/molecules";
import { Button, colors, SelectField, TextField, Typography } from "@flash-tecnologia/hros-web-ui-v2";

import { Icons } from "$atoms";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useFormOptions } from "./data/useFormOptions";
import { useSaveForm } from "./data/useSaveForm";
import { useAcquisitionForm } from "./form/useAcquisitionForm";
import { Banner, Content, Footer, StyledFlex } from "./styled";

export function AcquisitionFormPage() {
  const { control, watch, handleSubmit } = useAcquisitionForm();
  const { budgetOptions, deadlineExpectation, personOptions } = useFormOptions();
  const { save, isLoading } = useSaveForm();
  const { t } = useTranslation("translations", {
    keyPrefix: "pages.acquisitionForm",
  });

  const showEmailField = watch("expense.adminManager.currentUser") === "other";
  return (
    <Content templateColumns="80%" padding="xs" justify="center" gap="xs">
      <Banner
        templateColumns="2fr 1fr"
        gap="xs2"
        radius="m"
        borderColor="neutral.90"
        borderWidth="xs2"
        backgroundColor="secondary.99"
      >
        <Grid templateColumns="1fr" gap="xs4" padding="xs">
          <Flex gap="xs5">
            <Icons name="IconSparkles" size={20} stroke={colors.get("flamingo-pink")} />
            <Typography.Body4 color="brand.50" weight={700}>
              {t("banner.tag")}
            </Typography.Body4>
          </Flex>
          <Typography.Headline7>{t("banner.title")}</Typography.Headline7>
          <Typography.Body3 color="neutral.40">{t("banner.description")}</Typography.Body3>
        </Grid>
      </Banner>
      <Grid templateColumns="1fr" gap="xs2" padding="xs" radius="m" borderColor="neutral.90" borderWidth="xs2">
        <Flex direction="column" gap="xs2">
          <Flex direction="column" gap="xs5">
            <Typography.Headline8 color="neutral.30">{t("cardQuestion.title")}</Typography.Headline8>
            <Typography.Body4 color="neutral.40">{t("cardQuestion.description")}</Typography.Body4>
          </Flex>
          <Controller
            control={control}
            name={"expense.expectedValue"}
            rules={{ required: true }}
            render={({ field: { value, onChange }, fieldState: { invalid } }) => (
              <SelectField
                label={t("cardQuestion.label")}
                required
                value={value}
                onSelectChange={(_, option) => {
                  onChange(option?.value);
                }}
                fullWidth
                options={budgetOptions}
                error={invalid}
              />
            )}
          />
        </Flex>
        <StyledFlex direction="column" gap="xs2">
          <Flex direction="column" gap="xs5">
            <Typography.Headline8 color="neutral.30">{t("userQuestion.title")}</Typography.Headline8>
            <Typography.Body4 color="neutral.40">{t("userQuestion.description")}</Typography.Body4>
          </Flex>
          <Controller
            control={control}
            name={"expense.adminManager.currentUser"}
            rules={{ required: true }}
            render={({ field: { value, onChange }, fieldState: { invalid } }) => (
              <SelectField
                required
                label={t("userQuestion.label")}
                value={value}
                onSelectChange={(_, option) => {
                  onChange(option?.value);
                }}
                fullWidth
                options={personOptions}
                error={invalid}
              />
            )}
          />
          {showEmailField && (
            <Controller
              control={control}
              name={"expense.adminManager.email"}
              rules={{ required: true }}
              render={({ field: { value, onChange }, fieldState: { invalid } }) => (
                <TextField
                  required
                  label={t("userQuestion.emailLabel")}
                  value={value}
                  onChange={onChange}
                  error={invalid}
                />
              )}
            />
          )}
        </StyledFlex>
        <StyledFlex direction="column" gap="xs2">
          <Flex direction="column" gap="xs5">
            <Typography.Headline8 color="neutral.30">{t("implementationQuestion.title")}</Typography.Headline8>
            <Typography.Body4 color="neutral.40">{t("implementationQuestion.description")}</Typography.Body4>
          </Flex>
          <Controller
            control={control}
            name={"expense.expectedStarting"}
            rules={{ required: true }}
            render={({ field: { value, onChange }, fieldState: { invalid } }) => (
              <SelectField
                label={t("implementationQuestion.label")}
                required
                value={value}
                onSelectChange={(_, option) => {
                  onChange(option?.value);
                }}
                fullWidth
                options={deadlineExpectation}
                error={invalid}
              />
            )}
          />
        </StyledFlex>
      </Grid>
      <Footer>
        <FocusedFooter
          end={[
            <Button
              loading={isLoading}
              variant="primary"
              size="medium"
              onClick={handleSubmit((data) => {
                save(data);
              })}
            >
              {t("button")}
              <Icons name="IconArrowRight" />
            </Button>,
          ]}
        />
      </Footer>
    </Content>
  );
}
