import { trpc } from "$client";
import { KnowMore } from "./KnowMore";
import { ReimbursementsContent } from "./ReimbursementsContent";
import { ReimbursementsPageContainer } from "./styled";

export const ReimbursementsPage = () => {
  const { data, isLoading } = trpc.expense.meHasAny.useQuery();
  return (
    <ReimbursementsPageContainer>
      {!isLoading ? data && data.hasExpense ? <ReimbursementsContent /> : <KnowMore /> : null}
    </ReimbursementsPageContainer>
  );
};
