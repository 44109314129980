import styled from "styled-components";

export const HeaderActions = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${(props) => props.theme.spacings.xs};

  @media (min-width: 900px) {
    &:nth-child(2) {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  min-height: 40px;
  padding: ${(props) => props.theme.spacings.xs4} ${(props) => props.theme.spacings.m};
  justify-content: space-between;
  align-items: center;

  border-bottom: 1px solid ${(props) => props.theme.colors.neutral[90]};
  background: ${(props) => props.theme.colors.neutral[100]};
`;
