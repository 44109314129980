import { Icons, LinkButton, Typography } from "@flash-tecnologia/hros-web-ui-v2";

import { FocusedHeader } from "$molecules";

interface ExpenseHeaderProps {
  onClick?: () => void;
  title?: string;
  backDescription?: string;
}

export function ExpenseHeader({ onClick, title, backDescription }: ExpenseHeaderProps) {
  return (
    <FocusedHeader
      start={[
        <LinkButton variant="neutral" key={1} onClick={onClick}>
          <Icons name="IconChevronLeft" />
          {backDescription}
        </LinkButton>,
      ]}
      middle={[
        <Typography variant="headline9" weight={700} color="neutral.30">
          {title}
        </Typography>,
      ]}
    />
  );
}
