import { useTranslation } from "react-i18next";

import { trpc } from "$client";
import { Expense } from "$serverTypes";
import { useDisplayToast } from "$utils";

export function useSendNotification() {
  const { mutateAsync: notifyExpenses, isLoading } = trpc.notification.notifyManyExpenses.useMutation();
  const { t } = useTranslation("translations", { keyPrefix: "organisms.expensesNotifyModal" });
  const { displayToast } = useDisplayToast();

  async function sendNotification(expensesToNotify: Expense[], onSuccess?: () => void, onError?: () => void) {
    const count = expensesToNotify.length;

    try {
      await notifyExpenses({
        expenseIds: expensesToNotify.map(({ id }) => id),
      });

      displayToast({
        type: "success",
        title: t("toasts.notifySuccess", { count }),
      });

      onSuccess?.();
    } catch (e) {
      displayToast({
        type: "error",
        title: t("toasts.failed.title", { count }),
        description: t("toasts.pleaseTryAgainLater"),
      });

      onError?.();
    }
  }

  return { sendNotification, isLoading };
}
