import { Table } from "@flash-tecnologia/hros-web-ui-v2";
import { PaginationState } from "@flash-tecnologia/hros-web-ui-v2/dist/components/Table/components/Pagination";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { SkeletonLoader } from "$atoms";
import { useDebounce } from "$frontend/shared/hooks/debounce";
import { FilterButton } from "$molecules";

type Props = {
  /**
   * indicates if the table is loading
   */
  loading?: boolean;

  /**
   * loading state indicator for the search component in table
   */
  isSearchLoading?: boolean;

  /**
   * total number of items for pagination
   */
  total: number;

  /**
   * optional filter element to apply on the table
   */
  filter?: React.ReactElement<typeof Table.Pagination>;

  /**
   * children nodes to be displayed in the accordion list
   */
  children: React.ReactNode[];

  /**
   * optional element for bulk actions
   */
  bulkActions?: React.ReactNode;

  /**
   * pagination configuration, including page number, size, and change handler
   */
  pagination: {
    pageNumber: number;
    pageSize: number;
    onChange: (e: PaginationState) => void;
  };

  /**
   Current selected filters count
   */
  selectedFiltersCount?: number;

  /**
   * total number of selected items, used for bulk actions
   */
  totalSelected?: number;

  /**
   * Callback triggered when the filter button is clicked
   */
  onFilterClick?: () => void;

  /**
   * callback triggered when the input search has changed
   */
  onSearch?: (state: string) => void;
};

export function ListAccordions(props: Props) {
  const pageSizes = [5, 10, 15] as const;
  const { t } = useTranslation("translations", { keyPrefix: "organisms.listAccordions" });

  const [searchValue, setSearchValue] = useState("");
  const searchRef = useRef("");

  useDebounce(
    () => {
      if (props.onSearch) {
        props.onSearch(searchValue);
      }
    },
    [searchValue],
    500,
  );

  return (
    <Table.Root variant="soft">
      <>
        {props.isSearchLoading ? (
          <SkeletonLoader isVisible={true} height={40} />
        ) : (
          <Table.FilterSearch
            labelSearch={t("searchByTeamMember")}
            searchDefaultValue={searchValue}
            onSearch={(search) => {
              const value = search?.target?.value;
              searchRef.current = value;
              setSearchValue(value);
            }}
            onClear={() => {
              searchRef.current = "";
              setSearchValue("");
            }}
          >
            <FilterButton
              onFilterClick={props.onFilterClick}
              alertCountFilters={props.selectedFiltersCount ?? undefined}
            />
          </Table.FilterSearch>
        )}
      </>

      <Table.List.Root loading={props.loading} skeletonSize={props.pagination.pageSize}>
        {props.children}
      </Table.List.Root>

      {props.pagination && (
        <Table.Pagination
          showItemRange
          count={props.total}
          onPaginationChange={props.pagination.onChange}
          pagination={props.pagination}
          pageSizeOptions={pageSizes?.map((value) => ({
            label: `${value} items`,
            value,
          }))}
        />
      )}

      {!!props.totalSelected && props.bulkActions && (
        <Table.BulkActions open={props.totalSelected > 0} total={props.total} totalSelected={props.totalSelected}>
          {props.bulkActions}
        </Table.BulkActions>
      )}
    </Table.Root>
  );
}
