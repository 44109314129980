import { HeaderActions, HeaderContainer } from "./styled";

type FooterProps = {
  start?: JSX.Element[];
  middle?: JSX.Element[];
};

export const FocusedHeader = ({ start, middle }: FooterProps) => {
  return (
    <HeaderContainer>
      <HeaderActions>
        {start && start.map((element, index) => <div key={`start-${index}`}>{element}</div>)}
      </HeaderActions>
      <HeaderActions>
        {middle && middle.map((element, index) => <div key={`middle-${index}`}>{element}</div>)}
      </HeaderActions>
    </HeaderContainer>
  );
};
