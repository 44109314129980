import { PillButton, Tooltip } from "@flash-tecnologia/hros-web-ui-v2";

import Flex from "$frontend/components/atoms/Flex/Flex";
import { DangerActionModal } from "$frontend/components/molecules";
import { ConfirmationModalDeleteExpense } from "$frontend/components/organisms/ConfirmationModalDeleteExpense/ConfirmationModalDeleteExpense";
import { Routes } from "$frontend/routes";
import { Alignment } from "$frontend/utils";
import { Expense, ExpenseStatus, ExpenseType } from "$serverTypes";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDeleteExpense } from "../data/useDeleteExpense";
import { useReopenExpense } from "../data/useReopenExpense";
import { Origin } from "../ExpenseDetails";

export function HeaderActions({ expense, origin }: { expense?: Expense; origin: Origin }) {
  const { t } = useTranslation("translations", { keyPrefix: "pages.visualizeReimbursement" });
  const { t: tAdminReimbursement } = useTranslation("translations", { keyPrefix: "pages.adminReimbursement" });
  const { t: adminMovement } = useTranslation("translations", { keyPrefix: "pages.adminMovement" });

  const navigate = useNavigate();
  const { deleteExpense, isDeleting } = useDeleteExpense(origin);
  const { reopenExpense, isReopening, openReopenModal, setOpenReopenModal } = useReopenExpense(
    expense?.type || ExpenseType.REIMBURSEMENT,
  );

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openEditMovementModal, setOpenEditMovementModal] = useState(false);

  if (!expense) {
    return null;
  }

  const actions = resolveActions(expense.status, origin, expense.type);

  if (!actions) {
    return null;
  }

  function editAction(id: string) {
    if (origin === "user-reimbursement") {
      navigate(Routes.REIMBURSEMENT_EDIT.replace(":expenseId", id));
      return;
    }
    if (origin === "user-movement") {
      navigate(Routes.MOVEMENT_EDIT.replace(":expenseId", id));
      return;
    }
    if (origin === "admin-reimbursement") {
      setOpenEditModal(true);
    }

    if (origin === "admin-movement") {
      setOpenEditMovementModal(true);
    }
  }

  const tReopen = expense.type === ExpenseType.REIMBURSEMENT ? tAdminReimbursement : adminMovement;

  return (
    <Flex direction="row" gap="xs2">
      {!!actions.allowEdit && (
        <Tooltip title={t("edit")}>
          <div>
            <PillButton icon="IconPencil" size="small" variant="default" onClick={() => editAction(expense.id)} />
          </div>
        </Tooltip>
      )}
      {!!actions.alowDelete && (
        <Tooltip title={t("delete")}>
          <div>
            <PillButton icon="IconTrash" size="small" variant="default" onClick={() => setOpenDeleteModal(true)} />
          </div>
        </Tooltip>
      )}
      {!!actions.allowReopen && (
        <Tooltip title={tAdminReimbursement("reopen")}>
          <div>
            <PillButton
              icon="IconRotateClockwise"
              size="small"
              variant="default"
              onClick={() => setOpenReopenModal(true)}
            />
          </div>
        </Tooltip>
      )}
      <ConfirmationModalDeleteExpense
        isOpen={openDeleteModal}
        expensesCount={1}
        onActionClick={() => deleteExpense([expense.id])}
        isLoading={isDeleting}
        onCloseClick={() => setOpenDeleteModal(false)}
      />
      <DangerActionModal
        iconPosition={Alignment.left}
        isLoading={isReopening}
        headerIconLabel={tReopen("modals.confirmationModalReopenExpense.attention")}
        headerTitle={tReopen("modals.confirmationModalReopenExpense.popupTitle")}
        headerSubtitle={tReopen("modals.confirmationModalReopenExpense.popupSubtitle")}
        labelButtonAction={tReopen("modals.confirmationModalReopenExpense.confirm")}
        onActionClick={() => reopenExpense(expense.id)}
        onCancelClick={() => setOpenReopenModal(false)}
        onCloseClick={() => setOpenReopenModal(false)}
        open={openReopenModal}
      />
      <DangerActionModal
        iconPosition={Alignment.left}
        headerIconLabel={adminMovement("modals.confirmationModalEditExpense.attention")}
        headerTitle={adminMovement("modals.confirmationModalEditExpense.popupTitle")}
        labelButtonAction={adminMovement("modals.confirmationModalEditExpense.confirm")}
        onActionClick={() => navigate(Routes.ADMIN_MOVEMENT_EDIT.replace(":expenseId", expense.id))}
        onCancelClick={() => setOpenEditMovementModal(false)}
        onCloseClick={() => setOpenEditMovementModal(false)}
        open={openEditMovementModal}
      />
      <DangerActionModal
        isLoading={false}
        iconPosition={Alignment.left}
        headerIconLabel={tAdminReimbursement("modals.confirmationModalEditExpense.attention")}
        headerTitle={tAdminReimbursement("modals.confirmationModalEditExpense.popupTitle")}
        labelButtonAction={tAdminReimbursement("modals.confirmationModalEditExpense.confirm")}
        onActionClick={() => navigate(Routes.ADMIN_REIMBURSEMENT_EDIT.replace(":expenseId", expense.id))}
        onCancelClick={() => setOpenEditMovementModal(false)}
        onCloseClick={() => setOpenEditMovementModal(false)}
        open={openEditModal}
      />
    </Flex>
  );
}

function resolveActions(status: ExpenseStatus, origin: Origin, type: ExpenseType) {
  const isAdmin = ["admin-reimbursement", "admin-movement"].includes(origin);
  const isUser = ["user-reimbursement", "user-movement"].includes(origin);

  if (isUser) {
    switch (status) {
      case ExpenseStatus.PENDING_APPROVAL:
      case ExpenseStatus.REQUIRE_CHANGES:
        return { allowEdit: true, alowDelete: type === ExpenseType.REIMBURSEMENT };
    }
  }

  if (isAdmin) {
    switch (status) {
      case ExpenseStatus.PENDING_ACCOUNTING:
      case ExpenseStatus.PENDING_APPROVAL:
      case ExpenseStatus.REQUIRE_CHANGES:
        return { allowEdit: true };
      case ExpenseStatus.FINISHED:
      case ExpenseStatus.REJECTED:
        return {
          allowReopen: true,
        };
    }
  }

  return {};
}
