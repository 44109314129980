import { useCheckAcquisition } from "@flash-tecnologia/expense-web-activation-utils";
import { Navigate, Outlet } from "react-router-dom";

import { Routes } from "./routes";

export type THintData = {
  isAnswered: boolean;
  contracts?: {
    id: string;
    dealId: string;
    externalDealId: string;
    isSelfAcquisition: boolean;
  }[];
};

export const AcquisitionGuard = () => {
  const { data } = useCheckAcquisition();

  if (data?.isPlgAnswered) {
    return <Outlet />;
  }

  const shouldRedirect = data?.isSelfAcquisition && !data?.isPlgAnswered;

  return shouldRedirect ? <Navigate to={Routes.ADMIN_ACQUISITION_FORM} replace={false} /> : <Outlet />;
};
