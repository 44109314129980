import styled from "styled-components";

//TODO remover? não usado no ReceiptDropzone.tsx
export const Container = styled.div`
  width: 100%;
  min-height: 55vh;
  position: relative;
`;

//TODO remover? não usado no ReceiptDropzone.tsx
export const SpinnerContainer = styled.div<{ show: boolean }>(({ show }) => ({
  "border-radius": "10px",
  display: show ? "flex" : "none",
  "justify-content": "center",
  "align-items": "center",
  position: "absolute",
  width: "100%",
  height: "100%",
  "background-color": "rgba(0, 0, 0, 0.33)",
  "z-index": 5,
}));

export const EmptyDropzone = styled.div<{ isDragging: boolean }>`
  height: auto;
  width: 100%;
  display: flex;

  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${(props) => props.theme.spacings.xs3};
  align-self: stretch;

  border-radius: ${(props) => props.theme.spacings.xs3};
  border: 1px dashed
    ${(props) => (props.isDragging ? props.theme.colors.primary[50] : props.theme.colors.secondary[70])};
  background: ${(props) => props.theme.colors.neutral[100]};
`;

export const Gallery = styled.div<{ isDragging: boolean }>`
  height: auto;
  max-height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  border-radius: ${(props) => props.theme.spacings.xs3};
  border: 1px solid ${(props) => (props.isDragging ? props.theme.colors.primary[50] : props.theme.colors.neutral[90])};
  background-color: ${(props) => props.theme.colors.neutral[95]};
`;

export const Header = styled.div`
  display: flex;
  padding: ${(props) => props.theme.spacings.xs3} 24px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  border-radius: ${(props) => props.theme.spacings.xs3} ${(props) => props.theme.spacings.xs3} 0 0;

  background: ${(props) => props.theme.colors.neutral[95]};

  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.06);
`;

export const Footer = styled.div`
  display: flex;
  padding: ${(props) => props.theme.spacings.xs3} 24px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  border-radius: 0 0 ${(props) => props.theme.spacings.xs3} ${(props) => props.theme.spacings.xs3};

  background: ${(props) => props.theme.colors.neutral[95]};

  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.06);
`;

export const HeaderEnd = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${(props) => props.theme.spacings.xs4};
  align-items: center;
`;
