import { Attachment } from "$serverTypes";
import { Skeleton } from "@flash-tecnologia/hros-web-ui-v2";
import { lazy, Suspense } from "react";
import { AttachmentContainerContent } from "./styled";

const PdfReader = lazy(() => import("$molecules").then((m) => ({ default: m.PdfReader })));
const ImageZoom = lazy(() => import("$atoms").then((m) => ({ default: m.ImageZoom })));

export type AttachmentContainerProps = {
  isLoading?: boolean;
  currentAttachment?: Attachment;
  attachmentsSrc?: Record<string, string>;
};

export const AttachmentContainer = ({
  isLoading,
  currentAttachment,
  attachmentsSrc = {},
}: AttachmentContainerProps) => {
  const isPdf = (fileName: string) => fileName.endsWith(".pdf");
  const isImage = (fileName: string) =>
    fileName.endsWith(".png") || fileName.endsWith(".jpeg") || fileName.endsWith(".jpg");

  return (
    <AttachmentContainerContent>
      {isLoading ? (
        <Skeleton width="80%" height={"800px"} />
      ) : (
        <Suspense fallback={<Skeleton width="100%" height={"800px"} />}>
          {currentAttachment ? (
            (() => {
              const { fileName } = currentAttachment;
              const attachmentSrc = attachmentsSrc[fileName];

              if (!attachmentSrc) return <Skeleton width="80%" height={"800px"} />;

              if (isPdf(fileName)) {
                return <PdfReader title={fileName} filename={attachmentSrc} />;
              }

              if (isImage(fileName)) {
                return <ImageZoom src={attachmentSrc} alt="Attachment" />;
              }

              return <Skeleton width="80%" height={"800px"} />;
            })()
          ) : (
            <Skeleton width="80%" height={"800px"} />
          )}
        </Suspense>
      )}
    </AttachmentContainerContent>
  );
};
