import styled from "styled-components";

import { Icons, Typography } from "$atoms";
import { Dot } from "@flash-tecnologia/hros-web-ui-v2";

export const Header = styled.div`
  display: flex;
  height: 48px;
  padding: ${(props) => props.theme.spacings.xs3} 20px;
  align-items: center;
  gap: ${(props) => props.theme.spacings.xs4};
  align-self: stretch;

  border-radius: ${(props) => props.theme.spacings.xs4} ${(props) => props.theme.spacings.xs4} 0px 0px;
  border-bottom: 1px solid ${(props) => props.theme.colors.neutral[90]};
`;

export const HeaderIcon = styled(Icons).attrs((props) => ({
  name: "IconReceipt",
  size: 16,
  stroke: props.theme.colors.neutral[60],
  color: props.theme.colors.neutral[60],
}))``;

export const HeaderText = styled(Typography).attrs({
  variant: "headline10",
  weight: 700,
})`
  color: ${(props) => props.theme.colors.neutral[50]};
`;

export const Content = styled.div`
  display: flex;
  padding: ${(props) => props.theme.spacings.xs} ${(props) => props.theme.spacings.xs1};
  gap: ${(props) => props.theme.spacings.xs2};
  align-items: center;
`;

export const Container = styled.div<{ fullWidth: boolean | undefined }>(({ fullWidth, theme }) => ({
  width: fullWidth ? "100%" : undefined,
  display: "flex",
  flexDirection: "column",
  minHeight: "106px",
  alignItems: "left",
  gap: theme.spacings.xs2,
  border: `1px solid ${theme.colors.neutral[90]}`,
  borderBottom: "none",
  borderRadius: `${theme.spacings.xs4} ${theme.spacings.xs4} 0 0`,
  padding: undefined,
  flexWrap: "wrap",
}));

export const ChartSubtitle = styled.div`
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacings.xs4};
  flex-wrap: wrap;
`;

export const Circle = styled(Dot)<{ margin?: string; size?: number }>`
  margin: ${(props) => props.margin || 0};
  width: ${(props) => (props.size ? `${props.size}px` : "auto")};
  height: ${(props) => (props.size ? `${props.size}px` : "auto")};
`;
