import { Loader } from "@flash-tecnologia/hros-web-ui-v2";
import React from "react";
import { useTranslation } from "react-i18next";

import { Container, Description } from "./styled";

export interface LoaderProps {
  loader?: React.ReactNode;
  description?: string;
}

export const AutocompleteLoader = ({ loader, description }: LoaderProps) => {
  const { t } = useTranslation();
  return (
    <Container>
      {Boolean(loader) ? loader : <Loader variant="primary" size="extraSmall" />}
      <Description variant="caption">{description || t("organisms.establishmentSelect.loading")}</Description>
    </Container>
  );
};
