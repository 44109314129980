import { getFromLS, setInLS, useSelectedCompany } from "@flash-tecnologia/hros-web-utility";

import { trpc } from "$client";
import { useEffect, useState } from "react";

export type AcquisitionData = {
  isAnswered: boolean;
  contracts?: {
    id: string;
    dealId: string;
    externalDealId: string;
    isSelfAcquisition: boolean;
  }[];
};

export function useAcquisitionData() {
  const company = useSelectedCompany();
  const keyLS = `selfAcquisitionData-${company.selectedCompany?.id}`;
  const [data, setData] = useState<AcquisitionData>(getFromLS(keyLS));
  const { refetch } = trpc.company.checkAcquisitionForm.useQuery(undefined, {
    enabled: false,
    onSuccess: (response) => {
      setInLS({
        key: keyLS,
        value: response,
      });
      setData(response);
    },
  });

  useEffect(() => {
    if (!data) {
      refetch();
    }
  }, [company.selectedCompany?.id]);

  const updateLS = () => {
    setInLS({
      key: keyLS,
      value: { isAnswered: true },
    });
  };

  return { data, updateLS };
}
