import { EmptyState, ShapeIcon, Table } from "@flash-tecnologia/hros-web-ui-v2";
import { Row, Table as TableType } from "@flash-tecnologia/hros-web-ui-v2/dist/components/Table/shared/table.types";
import { ComponentProps, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useTheme } from "styled-components";

import { Typography } from "$atoms";
import { trpc } from "$client";
import Flex from "$frontend/components/atoms/Flex/Flex";
import Grid from "$frontend/components/atoms/Grid/Grid";
import { Routes } from "$frontend/routes";
import { useAdminPagesContext } from "$frontend/shared/context/AdminPagesContext";
import { getValueWithCurrency } from "$frontend/utils";
import { ButtonGroup, ButtonGroupOption, InfoCard } from "$molecules";
import {
  ConfirmationModalDeleteExpense,
  ConfirmationModalEditExpense,
  ExpenseTable,
  UpdatableExpenseStatus,
} from "$organisms";
import { Expense, ExpensesGroupedEmployeesResponse, ExpenseStatus, ExpenseType } from "$serverTypes";
import { useDisplayToast } from "$utils";
import { PaginationState } from "@flash-tecnologia/hros-web-ui-v2/dist/components/Table/components/Pagination";
import { AccordionTotals, EmployeeNameContainer, SummaryItem } from "./styled";

type ExpenseUserGroupRowProps = {
  /**
   * unique key for each row, typically the employee ID
   */
  key: string;

  /**
   * row data for an individual employee's expenses
   */
  row: Row<ExpensesGroupedEmployeesResponse>;

  /** table associated with this component */
  table: TableType<ExpensesGroupedEmployeesResponse>;

  /**
   * callback for action is complete
   */
  onActionComplete?: () => void;

  /** expense type */
  type: ExpenseType;

  /** Optional callback when rows are selected */
  onSelectedChange?: (expenses: Expense[]) => void;

  /** Current selected expenses (useful when applying actions cross tables components) */
  selectedExpenses?: Expense[];

  /** called when all current user expenses were requested to update to targetStatus  */
  onUpdateStatusAll: (targetStatus: UpdatableExpenseStatus) => void;

  /** called when all current user expenses were requested to notify  */
  onNotifyAll: () => void;
};

export function ExpenseUserGroupRow({
  row,
  onActionComplete,
  type,
  onSelectedChange,
  selectedExpenses,
  table,
  onUpdateStatusAll,
  onNotifyAll,
}: ExpenseUserGroupRowProps) {
  const { expandedRows, setExpandedRows } = useAdminPagesContext();
  const isInitialMount = useRef(true);
  const isExpanded = row.getIsExpanded();
  const rowId = row.id;

  useEffect(() => {
    if (isInitialMount.current) {
      const isExpanded = expandedRows[rowId];
      if (isExpanded) {
        row.toggleExpanded(true);
      }
      isInitialMount.current = false;
    }
  }, [expandedRows, row, rowId]);

  useEffect(() => {
    setExpandedRows((prev: Record<string, boolean>) => ({
      ...prev,
      [rowId]: isExpanded,
    }));
  }, [rowId, isExpanded, setExpandedRows]);

  const { t } = useTranslation("translations", { keyPrefix: `organisms.expenseUserGroupRow.${type}` });
  const { colors } = useTheme();
  const navigate = useNavigate();
  const { displayToast } = useDisplayToast();

  const pageSizes = [5, 10, 15] as const;
  const [pagination, setPagination] = useState<PaginationState>({
    pageNumber: 1,
    pageSize: 10,
  });
  const [expensesToDelete, setExpensesToDelete] = useState<Expense[]>([]);
  const [expenseToEdit, setExpenseToEdit] = useState<Expense>();
  const { mutateAsync: deleteExpense, isLoading: isDeleting } = trpc.expense.deleteExpense.useMutation();

  const summaryItemConfig: {
    title: string;
    icon: Pick<ComponentProps<typeof ShapeIcon>, "variant" | "stroke" | "name">;
    status: ExpenseStatus[];
  }[] = [
    {
      title: t("infoCard.totalOpen"),
      status: Object.values(ExpenseStatus),
      icon: {
        variant: "neutral",
        stroke: "neutral",
        name: "IconReceipt",
      },
    },
    {
      title: t("infoCard.awaitAccounting"),
      status: [ExpenseStatus.PENDING_ACCOUNTING],
      icon: {
        variant: "info",
        stroke: "info",
        name: "IconCurrencyDollar",
      },
    },
    {
      title: t("infoCard.awaitApproval"),
      status: [ExpenseStatus.DRAFT, ExpenseStatus.REQUIRE_CHANGES],
      icon: {
        variant: "error",
        stroke: "error",
        name: "IconAlertTriangle",
      },
    },
  ];

  async function onDelete(): Promise<void> {
    if (!expensesToDelete.length) return;

    try {
      await deleteExpense(expensesToDelete.map((expense) => expense.id));
      displayToast({
        title: t("toasts.deleteSuccess.title"),
      });
      if (onActionComplete) onActionComplete();
    } catch (e) {
      displayToast({
        type: "error",
        title: t("toasts.deleteError.title"),
        description: t("toasts.deleteError.description"),
      });
    }

    setExpensesToDelete([]);
  }

  const handleNavigate = (expense: Expense, isEdit: boolean) => {
    const isReimbursement = expense.type === "REIMBURSEMENT";
    const route = isEdit
      ? isReimbursement
        ? Routes.ADMIN_REIMBURSEMENT_EDIT
        : Routes.ADMIN_MOVEMENT_EDIT
      : isReimbursement
        ? Routes.ADMIN_REIMBURSEMENT
        : Routes.ADMIN_MOVEMENT;

    navigate(route.replace(":expenseId", expense.id));
  };

  function renderExpenses() {
    if (!row.getIsExpanded()) {
      return null;
    }

    if (row.original.expenses.length > 0) {
      return (
        <ExpenseTable
          expenses={row.original.expenses}
          pageSizes={pageSizes}
          pagination={pagination}
          count={row.original.expenses.length}
          isLoading={isDeleting}
          isAdminPage={true}
          type={type}
          onDelete={setExpensesToDelete}
          onEdit={setExpenseToEdit}
          onVisualize={(expense) => handleNavigate(expense, false)}
          onPaginationChange={setPagination}
          onActionComplete={onActionComplete}
          onSelectedChange={onSelectedChange}
          selectedExpenses={selectedExpenses}
        />
      );
    }

    return (
      <EmptyState
        buttonProps={{
          onClick: () => console.log("Create new expense"),
        }}
        type="empty"
        withoutButton
        buttonText={t("emptyState.buttonText")}
        description={t("emptyState.description")}
      />
    );
  }

  function canNotifyAll(): boolean {
    return row.original.expenses.some((expense) =>
      [ExpenseStatus.DRAFT, ExpenseStatus.REQUIRE_CHANGES].includes(expense.status),
    );
  }

  function renderActions() {
    const options = [
      {
        icon: "IconCheck",
        iconColor: colors.feedback.success[40],
        label: t("actions.check"),
        onClick: () => onUpdateStatusAll(ExpenseStatus.FINISHED),
        disabled: table.selected.selected.length > 0 && !table.selected.selected.some(({ id }) => id == row.id),
      },
      {
        icon: "IconBellRinging",
        iconColor: colors.primary,
        label: t("actions.notify"),
        onClick: () => onNotifyAll(),
        disabled: !canNotifyAll(),
      },
    ] satisfies ButtonGroupOption[];
    return <ButtonGroup options={options} />;
  }

  return (
    <Table.List.AccordionBaseRow
      key={row.id}
      row={row}
      summary={
        <Grid templateColumns="1fr 2fr">
          <EmployeeNameContainer>
            <Typography variant="headline9" weight={700} color="neutral.20">
              {row.original.employeeName ?? ""}
            </Typography>
          </EmployeeNameContainer>
          <Grid templateColumns="repeat(3, 1fr) auto" justify="space-between" align="center" gap="xs2">
            {summaryItemConfig.map(({ title, icon, status }) => {
              const summaryItemValue = Object.entries(row.original.summary).reduce(
                (total, [summaryStatus, { amount }]) => {
                  if (status.includes(summaryStatus as ExpenseStatus)) {
                    total += amount;
                  }

                  return total;
                },
                0,
              );

              return (
                <SummaryItem>
                  <InfoCard
                    fullWidth={true}
                    leading={<ShapeIcon variant={icon.variant} stroke={icon.stroke} name={icon.name} size={24} />}
                    title={<Typography variant="caption">{title}</Typography>}
                    subTitle={
                      <AccordionTotals>
                        <Typography weight={600} color="neutral.20" variant="body4">
                          {getValueWithCurrency({
                            value: summaryItemValue,
                            currencyPrefix: "BRL",
                          })}
                        </Typography>
                      </AccordionTotals>
                    }
                  />
                </SummaryItem>
              );
            })}

            <Flex align="center">{renderActions()}</Flex>
          </Grid>
        </Grid>
      }
    >
      {renderExpenses()}

      <ConfirmationModalDeleteExpense
        isOpen={Boolean(expensesToDelete.length)}
        expensesCount={expensesToDelete.length}
        onActionClick={onDelete}
        isLoading={isDeleting}
        onCloseClick={() => setExpensesToDelete([])}
      />

      <ConfirmationModalEditExpense
        isOpen={expenseToEdit !== undefined}
        onActionClick={() => handleNavigate(expenseToEdit!, true)}
        onCloseClick={() => setExpenseToEdit(undefined)}
        type={type}
        hideSubtitle={
          expenseToEdit?.status &&
          ![ExpenseStatus.DRAFT, ExpenseStatus.REQUIRE_CHANGES, ExpenseStatus.PENDING_ACCOUNTING].includes(
            expenseToEdit.status,
          )
        }
        isAdmin
      />
    </Table.List.AccordionBaseRow>
  );
}
