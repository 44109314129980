import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const ContainerInfos = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
`;

export const ContainerDescription = styled.div<{ noCaption: boolean }>`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs5};
  align-items: center;
  height: ${({ noCaption }) => (noCaption ? "100%" : undefined)};
`;

export const ContainerCaption = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacings.xs5};
  align-items: center;
`;

export const Description = styled(Typography).attrs(({ color, style }) => ({
  variant: "body4",
  weight: 600,
  color: color ?? "neutral.20",
  style: style,
}))`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-decoration: ${({ style }) => style?.textDecoration ?? "initial"};
`;

export const Caption = styled(Typography).attrs(({ color }) => ({
  variant: "body4",
  weight: 400,
  color: color ?? "neutral.40",
}))`
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: anywhere;
`;
