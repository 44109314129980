import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${(props) => props.theme.spacings.xs};
`;

export const Title = styled(Typography).attrs({
  weight: 700,
})`
  color: ${(props) => props.theme.colors.neutral[40]};
  text-align: center;
`;

export const Caption = styled(Typography).attrs({
  weight: 600,
})`
  color: ${(props) => props.theme.colors.neutral[50]};
  text-align: center;
`;
