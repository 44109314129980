import Flex from "$frontend/components/atoms/Flex/Flex";
import Grid from "$frontend/components/atoms/Grid/Grid";
import { ExpenseStatusTag } from "$frontend/components/molecules";
import { Routes } from "$frontend/routes";
import { getValueWithCurrency } from "$frontend/utils";
import { Expense, ExpenseStatus } from "$serverTypes";
import { ShapeIcon, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Box, IconButton } from "./styled";
import { ReimbursementSurvey } from "./Survey";

type ExpenseSummaryProps = {
  expenses: Expense[];
};

export function ExpenseSummary({ expenses }: ExpenseSummaryProps) {
  const navigate = useNavigate();
  const { t } = useTranslation("translations", {
    keyPrefix: "pages.createReimbursement.summary",
  });

  return (
    <Grid templateColumns="1fr" align="center" justifyItem="center" gap="xs">
      <Flex align="center" justify="center" direction="column" gap="xs4">
        <ShapeIcon name="IconCircleCheck" size={48} variant="success" stroke="success" />
        <Typography.Headline8>{t("title")}</Typography.Headline8>
        <Typography.Body4 color="neutral.40">{t("subtitle")}</Typography.Body4>
      </Flex>
      <Grid templateColumns="80%" justify="center" gap="xs" style={{ minWidth: "700px" }}>
        {expenses.map((expense) => (
          <Grid key={expense.id} templateColumns="1fr" borderColor="neutral.90" borderWidth="xs2" radius="m">
            <Grid templateColumns="3fr 1fr" gap="xs4" justify="space-between" align="center" padding="xs">
              <Grid templateColumns="auto 1fr" gap="xs4">
                <ShapeIcon name="IconReceipt" size={40} variant="neutral" stroke="neutral" />
                <Flex direction="column" gap="xs5">
                  <Typography.Caption color="neutral.40">{expense.category?.description}</Typography.Caption>
                  <Typography.Headline8>
                    {expense.establishment?.name || expense.establishment?.description}
                  </Typography.Headline8>
                </Flex>
              </Grid>
              <Grid templateColumns="1fr auto" gap="xs4" align="center">
                <Flex direction="column" gap="xs5" align="end">
                  <Typography.Caption color="neutral.40">{t("labels.amount")}</Typography.Caption>
                  <Typography.Headline8>{getValueWithCurrency({ value: expense.amount })}</Typography.Headline8>
                </Flex>
                <IconButton
                  name="IconChevronRight"
                  size={20}
                  onClick={() => navigate(Routes.REIMBURSEMENT.replace(":expenseId", expense.id))}
                />
              </Grid>
              <div></div>
              <Flex direction="column" gap="xs4" align="end">
                <ExpenseStatusTag type={expense.type} status={ExpenseStatus.PENDING_ACCOUNTING} />
              </Flex>
            </Grid>
            {expenses.length === 1 && (
              <Box templateColumns="1fr 1fr" padding="xs" gap="xs">
                <Grid templateColumns="auto 1fr" gap="xs4" align="center">
                  <ShapeIcon name="IconCategory" size={32} variant="neutral" stroke="neutral" />
                  <Flex direction="column" gap="xs5">
                    <Typography.Caption color="neutral.40">{t("labels.category")}</Typography.Caption>
                    <Typography.Body4 weight={700}>{expense.category?.description}</Typography.Body4>
                  </Flex>
                </Grid>
                <Grid templateColumns="auto 1fr" gap="xs4" align="center">
                  <ShapeIcon name="IconCalendarEvent" size={32} variant="neutral" stroke="neutral" />
                  <Flex direction="column" gap="xs5">
                    <Typography.Caption color="neutral.40">{t("labels.date")}</Typography.Caption>
                    <Typography.Body4 weight={700}>
                      {new Date(expense.date).toLocaleDateString("pt-BR", {
                        day: "2-digit",
                        month: "long",
                        year: "numeric",
                      })}
                    </Typography.Body4>
                  </Flex>
                </Grid>
                <Grid templateColumns="auto 1fr" gap="xs4" align="center">
                  <ShapeIcon name="IconIdBadge2" size={32} variant="neutral" stroke="neutral" />
                  <Flex direction="column" gap="xs5">
                    <Typography.Caption color="neutral.40">{t("labels.expenseId")}</Typography.Caption>
                    <Typography.Body4 weight={700}>{expense.referenceId}</Typography.Body4>
                  </Flex>
                </Grid>
                <Grid templateColumns="auto 1fr" gap="xs4" style={{ gridColumnStart: 1, gridColumnEnd: 3 }} align="end">
                  <ShapeIcon name="IconQuote" size={32} variant="neutral" stroke="neutral" />
                  <Flex direction="column" gap="xs5">
                    <Typography.Caption color="neutral.40">{t("labels.comment")}</Typography.Caption>
                    <Flex borderColor="neutral.90" borderWidth="xs2" radius="m" padding="xs3">
                      <Typography.Body4>{expense.comments || ""}</Typography.Body4>
                    </Flex>
                  </Flex>
                </Grid>
              </Box>
            )}
          </Grid>
        ))}
      </Grid>
      <ReimbursementSurvey />
    </Grid>
  );
}
