import { Icons, ShapeIcon } from "$atoms";
import Flex from "$frontend/components/atoms/Flex/Flex";
import Grid from "$frontend/components/atoms/Grid/Grid";
import { colors, LinkButton, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { useTranslation } from "react-i18next";

export function KnowMore() {
  const { t } = useTranslation("translations", { keyPrefix: "pages.statement.knowMore" });
  const box = [
    {
      title: t("box.first.title"),
      description: t("box.first.description"),
      image: "https://images.flashapp.com.br/expense/expense-lifecycle/statement-know-more-1.svg",
      alt: "Lista de pendencias",
    },
    {
      title: t("box.second.title"),
      description: t("box.second.description"),
      image: "https://images.flashapp.com.br/expense/expense-lifecycle/statement-know-more-2.svg",
      alt: "Formulário de prestação de conta",
    },
    {
      title: t("box.third.title"),
      description: t("box.third.description"),
      image: "https://images.flashapp.com.br/expense/expense-lifecycle/statement-know-more-3.svg",
      alt: "Mudar saldo da compra",
    },
  ];
  return (
    <Grid templateColumns="50%" justify="center">
      <Flex
        borderWidth="xs2"
        borderColor="neutral.80"
        radius="m"
        padding="xs"
        align="center"
        direction="column"
        gap="xs"
      >
        <Flex align="center" gap="xs5">
          <Icons name="IconSparkles" stroke={colors.get("flamingo-pink")} size={16} />
          <Typography.Body4 color="flamingo-pink" weight={700}>
            {t("title")}
          </Typography.Body4>
        </Flex>
        <Typography.Headline8 style={{ textAlign: "center" }}>{t("subtitle")}</Typography.Headline8>
        <Grid templateColumns="130%" justify="center">
          <Grid templateColumns="1fr 1fr 1fr" gap="xs">
            {box.map((item, index) => {
              return (
                <Flex
                  key={index}
                  borderWidth="xs2"
                  borderColor="secondary.95"
                  radius="m"
                  direction="column"
                  backgroundColor="neutral.100"
                >
                  <img src={item.image} alt={item.alt} />
                  <Flex direction="column" gap="xs4" padding="xs2">
                    <Typography.Headline10 color="flamingo-pink">{item.title}</Typography.Headline10>
                    <Typography.Caption>{item.description}</Typography.Caption>
                  </Flex>
                </Flex>
              );
            })}
          </Grid>
        </Grid>
        <Flex width="100%" radius="m" backgroundColor="secondary.99" padding="xs2" align="center" gap="xs2">
          <ShapeIcon variant="secondary" icon="IconMessageDots" size="extra_small" />
          <Typography.Caption weight={700}>{t("doubt")}</Typography.Caption>
          <LinkButton
            size="small"
            variant="default"
            target="_blank"
            href="https://drive.google.com/file/d/1ZhPHyiVCfQMvOc1hKYn5aw4x8uJ9NkF4/view"
          >
            {t("faq")}
          </LinkButton>
        </Flex>
      </Flex>
    </Grid>
  );
}
