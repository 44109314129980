import { CombinedExpenseStatus, PendingExpenseAction } from "$organisms";
import { ExpenseStatus, ExpenseTransactionCardType, ExpenseType, TransactionStatus } from "$serverTypes";

const common = {
  expenseStatusReimbursements: {
    [ExpenseStatus.DRAFT]: "Pendente",
    [ExpenseStatus.REQUIRE_CHANGES]: "Em revisão",
    [ExpenseStatus.PENDING_APPROVAL]: "Em aprovação",
    [ExpenseStatus.PENDING_ACCOUNTING]: "Em pagamento",
    [ExpenseStatus.FINISHED]: "Reembolsado",
    [ExpenseStatus.REJECTED]: "Reprovado",
  } satisfies Record<ExpenseStatus, string>,
  expenseStatusCorporateCard: {
    [ExpenseStatus.DRAFT]: "Pendente",
    [ExpenseStatus.REQUIRE_CHANGES]: "Em revisão",
    [ExpenseStatus.PENDING_APPROVAL]: "Em aprovação",
    [ExpenseStatus.PENDING_ACCOUNTING]: "Em fechamento",
    [ExpenseStatus.FINISHED]: "Finalizada",
    [ExpenseStatus.REJECTED]: "Reprovada",
  } satisfies Record<ExpenseStatus, string>,
  transactionStatus: {
    [TransactionStatus.AUTHORIZED]: "Autorizada",
    [TransactionStatus.COMPLETED]: "Confirmada",
    [TransactionStatus.SETTLED]: "Autorizada",
    [TransactionStatus.CANCELED]: "Cancelada",
    [TransactionStatus.REVERTED]: "Revertida",
    [TransactionStatus.PROCESSING]: "Processando",
  } satisfies Record<TransactionStatus, string>,
};

const translations = {
  translations: {
    pages: {
      categoriesList: {
        title: "Categorias",
        subtitle: "Defina as categorias de gastos que deseja disponibilizar para lançamento de despesas.",
        headerAction: "Criar categoria",
        create: "Criar",
      },
      createReimbursement: {
        back: "Voltar para Reembolsos",
        title: "Solicitar reembolso",
        help: "Central de Ajuda",
        toasts: {
          creationFailed: {
            messages: {},
            title: "Não foi possível solicitar o reembolso",
          },
          creationSuccess: {
            title: "Reembolso solicitado com sucesso!",
          },
        },
        summary: {
          title: "Reembolsos enviados para análise e pagamento com sucesso!",
          subtitle: "Os reembolsos foram enviados para análise e pagamento, acesse os detalhes para mais informações.",
          labels: {
            amount: "Valor da despesa",
            category: "Categorias",
            date: "Data da despesa",
            expenseId: "ID da despesa",
            comment: "Comentário",
          },
        },
        survey: {
          ReimbursementEasiness: "[1/3] Como você avalia a facilidade de uso da plataforma ao solicitar reembolso?",
          ReimbursementAttendance: "[2/3] O quanto a solicitação de reembolso atende às suas necessidades?",
          rates: {
            terrible: "Péssima",
            bad: "Ruim",
            regular: "Regular",
            good: "Boa",
            great: "Ótima",
            notRespond: "Não atende em nada",
            partiallyRespond: "Atende Parcialmente",
            totallyRespond: "Atende totalmente",
          },
        },
      },
      editReimbursement: {
        back: "Voltar para Reembolsos",
        title: "Editar reembolso",
        help: "Central de Ajuda",
        toasts: {
          failedEdit: {
            messages: {},
            title: "Não foi possível editar o reembolso",
          },
          successEdit: {
            title: "Reembolso solicitado com sucesso!",
          },
        },
      },
      reimbursements: {
        title: "Reembolsos",
        subtitle: "Adicione e acompanhe suas solicitações de reembolso.",
        addReimbursement: "Solicitar reembolso",
        failedToGetReimbursements: "Erro ao buscar reembolsos",
        toasts: {
          addSuccess: {
            title: "Reembolso solicitado com sucesso!",
          },
          addError: {
            title: "Não foi possível solicitar o reembolso",
            description: "Por favor, tente novamente dentro de alguns instantes.",
          },
          deleteSuccess: {
            title: "Reembolso(s) excluído(s) com sucesso!",
          },
          deleteError: {
            title: "Não foi possível excluir o(s) reembolso(s)",
            description: "Por favor, tente novamente dentro de alguns instantes.",
          },
        },
        knowMore: {
          title: "Saiba mais!",
          subtitle: "Solicite reembolsos e acompanhe o andamento da sua solicitação!",
          box: {
            first: {
              title: "Peça um reembolso",
              description:
                "Após efetuar a compra com o seu dinheiro, você pode solicitar o reembolso de forma fácil e rápida.",
            },
            second: {
              title: "Confira o status",
              description:
                "Acompanhe o status das suas solicitações e saiba exatamente quando o seu reembolso for efetuado.",
            },
          },
          doubt: "Ficou com alguma dúvida?",
          faq: "Acesse nossa FAQ",
        },
        enableReimbursementSection: {
          title: "Simplifique sua gestão de reembolsos!",
          subtitle: "Faça o acompanhamento dos reembolsos solicitados pela sua equipe.",
          features: {
            trackReimbursements: "Acompanhe os reembolsos",
            signalInconsistencies: "Sinalize inconformidades",
            easyControl: "Controle facilitado",
          },
          stepOne: "Passo 1",
          stepOneDescription: "Aprenda a utilizar a nova seção de reembolsos.",
          usageGuide: "Guia de uso",
          cards: {
            title: "Você encontrará aqui",
            trackReimbursements: {
              title: "Acompanhe os reembolsos",
              description: "Acompanhe os reembolsos em andamento da sua equipe de acordo com os status.",
            },
            signalInconsistencies: {
              title: "Sinalize inconformidades",
              description: "Envie para revisão do colaborador reembolsos que apresentarem inconformidades.",
            },
            easyControl: {
              title: "Controle facilitado",
              description: "Sinalize como reembolsado o que já tiver sido pago.",
            },
          },
        },
      },
      reimbursementsSummary: {
        title: "Reembolsos",
        subtitle: "Marque as despesas como reembolsadas, e acompanhe os reembolsos em andamento",

        failedToGetReimbursements: "Erro ao buscar reembolsos",
        remainingExpensesWarning_one:
          "Você possui um reembolso de período anterior ao selecionado para análise. Ajuste o filtro para visualizá-lo.",
        remainingExpensesWarning_other:
          "Você possui {{count}} reembolsos de período anterior ao selecionado para análise. Ajuste o filtro para visualizá-los.",
      },
      adminReimbursement: {
        breadcrumbs: {
          current: "Detalhes do reembolso",
          previous: "Reembolsos",
        },
        title: "Detalhes do Reembolso",
        edit: "Editar",
        reopen: "Reabrir",
        extraButtons: {
          reject: "Reprovar",
          requestReview: "Solicitar Revisão",
          markAsReimbursed: "Marcar como Reembolsado",
        },
        modals: {
          confirmationModalEditExpense: {
            attention: "Atenção",
            popupTitle: "Tem certeza que deseja editar o reembolso?",
            confirm: "Confirmar",
          },
          confirmationModalReopenExpense: {
            attention: "Atenção",
            popupTitle: "Tem certeza que deseja reabrir o reembolso?",
            popupSubtitle: 'Ao reabrir ele voltará ao status de "em pagamento"',
            confirm: "Confirmar Reabertura",
          },
          confirmationModalRejectExpense: {
            attention: "Atenção",
            popupTitle: "Tem certeza que deseja reprovar o reembolso?",
            confirm: "Confirmar Reprovação",
            reasonLabel: "Selecione um motivo para a reprovação",
          },
          confirmationModalRequestReview: {
            attention: "Atenção",
            popupTitle: "Tem certeza que deseja enviar o reembolso para revisão?",
            popupSubtitle: "A pessoa que solicitou o reembolso receberá como uma pendência para revisar.",
            confirm: "Solicitar Revisão",
            reasonLabel: "Explique o motivo da revisão",
          },
          confirmationModalMarkAsReimbursed: {
            attention: "Atenção",
            popupTitle: "Tem certeza que deseja marcar a despesa como reembolsada?",
            confirm: "Confirmar Reembolso",
          },
        },
        toasts: {
          reopenSuccess: {
            title: "Reembolso reaberto com sucesso!",
          },
          reopenError: {
            title: "Erro ao reabrir reembolso",
            description: "Por favor, tente novamente dentro de alguns instantes.",
          },
          rejectSuccess: {
            title: "Reembolso reprovado com sucesso!",
          },
          rejectError: {
            title: "Erro ao reprovar reembolso",
            description: "Por favor, tente novamente dentro de alguns instantes.",
          },
          rejectCommentRequired: {
            title: "Erro ao reprovar reembolso",
            description: "Por favor, selecione um motivo",
          },
          requestReviewSuccess: {
            title: "Revisão solicitada com sucesso!",
          },
          requestReviewError: {
            title: "Erro ao solicitar revisão",
            description: "Por favor, tente novamente dentro de alguns instantes.",
          },
          requestReviewCommentRequired: {
            title: "Erro ao solicitar revisão",
            description: "Por favor, selecione um motivo",
          },
          markAsReimbursedSuccess: {
            title: "Reembolso marcado como reembolsado com sucesso!",
          },
          markAsReimbursedError: {
            title: "Erro ao marcar como reembolsado",
            description: "Por favor, tente novamente dentro de alguns instantes.",
          },
        },
      },
      adminEditReimbursement: {
        back: "Voltar para Reembolsos",
        title: "Editar reembolso",
        help: "Central de Ajuda",
        toasts: {
          failedEdit: {
            messages: {},
            title: "Não foi possível editar o reembolso",
          },
          successEdit: {
            title: "Reembolso editado com sucesso!",
          },
        },
      },
      adminStatement: {
        title: "Fechamento de cartão corporativo",
        subtitle: "Aprove as prestações de conta realizadas e acompanhe as que estão em andamento.",
        failedToGetMovements: "Erro ao buscar movimentações",
        summaryCardHeader: "Prestação de conta de cartões",
        remainingExpensesWarning_one:
          "Você possui uma prestação de conta de período anterior ao selecionado para análise. Ajuste o filtro para visualizá-la.",
        remainingExpensesWarning_other:
          "Você possui {{count}} prestações de contas de período anterior ao selecionado para análise. Ajuste o filtro para visualizá-las.",
      },
      adminMovement: {
        breadcrumbs: {
          current: "Detalhes da movimentação",
          previous: "Fechamento do cartão corporativo",
        },
        title: "Detalhes da movimentação",
        edit: "Editar",
        reopen: "Reabrir",
        extraButtons: {
          reject: "Reprovar",
          requestReview: "Solicitar Revisão",
          approve: "Aprovar",
        },
        modals: {
          confirmationModalEditExpense: {
            attention: "Atenção",
            popupTitle: "Tem certeza que deseja editar a prestação de conta?",
            confirm: "Confirmar",
          },
          confirmationModalReopenExpense: {
            attention: "Atenção",
            popupTitle: "Tem certeza que deseja reabrir a prestação de conta?",
            popupSubtitle: 'Ao reabrir, ela voltará ao status "em fechamento"',
            confirm: "Confirmar",
          },
          confirmationModalRejectExpense: {
            attention: "Atenção",
            popupTitle: "Tem certeza que deseja reprovar a prestação de conta?",
            confirm: "Confirmar",
            reasonLabel: "Selecione um motivo para a reprovação",
          },
          confirmationModalRequestReview: {
            attention: "Atenção",
            popupTitle: "Tem certeza que deseja enviar a prestação de conta para revisão?",
            popupSubtitle: "A pessoa que solicitou a prestação de conta receberá como uma pendência para revisar.",
            confirm: "Solicitar Revisão",
            reasonLabel: "Explique o motivo da revisão",
          },
          confirmationModalApprove: {
            attention: "Atenção",
            popupTitle: "Tem certeza que deseja aprovar a prestação de conta?",
            confirm: "Confirmar",
          },
        },
        toasts: {
          reopenSuccess: {
            title: "Prestação de conta reaberta com sucesso!",
          },
          reopenError: {
            title: "Erro ao reabrir a prestação de conta",
            description: "Por favor, tente novamente dentro de alguns instantes.",
          },
          rejectSuccess: {
            title: "Prestação de conta reprovada com sucesso!",
          },
          rejectError: {
            title: "Erro ao reprovar prestação de conta",
            description: "Por favor, tente novamente dentro de alguns instantes.",
          },
          rejectCommentRequired: {
            title: "Erro ao reprovar prestação de conta",
            description: "Por favor, selecione um motivo",
          },
          requestReviewSuccess: {
            title: "Revisão solicitada com sucesso!",
          },
          requestReviewError: {
            title: "Erro ao solicitar revisão",
            description: "Por favor, tente novamente dentro de alguns instantes.",
          },
          requestReviewCommentRequired: {
            title: "Erro ao solicitar revisão",
            description: "Por favor, selecione um motivo",
          },
          approveSuccess: {
            title: "Prestação de conta aprovada com sucesso!",
          },
          approveError: {
            title: "Erro ao aprovar a prestação de conta",
            description: "Por favor, tente novamente dentro de alguns instantes.",
          },
        },
      },
      adminEditMovement: {
        back: "Voltar",
        title: "Editar movimentação",
        help: "Central de Ajuda",
        toasts: {
          failedEdit: {
            messages: {},
            title: "Não foi possível editar a prestação de conta",
          },
          successEdit: {
            title: "Prestação de conta editada com sucesso!",
          },
        },
      },
      statement: {
        title: "Extrato do Cartão Corporativo",
        knowMore: {
          title: "Saiba mais!",
          subtitle: "Confira suas movimentações e preste conta das suas despesas aqui!",
          box: {
            first: {
              title: "1. Revisão de pendências",
              description:
                "Aqui, você encontrará as prestações pendentes relacionadas às despesas com cartão corporativo.",
            },
            second: {
              title: "2. Prestação de contas",
              description:
                "Após a compra no cartão corporativo, complete as informações e preste conta das despesas pendentes.",
            },
            third: {
              title: "3. Comprou com saldo errado?",
              description: "Mude facilmente o saldo da compra caso tenha utilizado o cartão no saldo de benefícios.",
            },
          },
          doubt: "Ficou com alguma dúvida?",
          faq: "Acesse nossa FAQ",
        },
      },
      pendingsItems: {
        back: "Voltar",
        title: "Revisar pendências",
        help: "Central de ajuda",
        close: "Fechar",
        exitAndSave: "Sair e salvar alterações",
        skip: "Pular despesa",
        fields: {
          currency: "moeda",
          amount: "valor",
          date: "data",
          category: "categoria",
          description: "comentário",
          attachments: "recibo",
          establishment: "estabelecimento",
        },
        submit: {
          onLoading: "Prestar conta",
          description: "Prestar conta ({{submittedCount}} de {{totalExpenses}})",
        },
        toasts: {
          failedEdit: {
            messages: {},
            title: "Falha ao editar solicitação",
          },
          successEdit: {
            title: "Sucesso!",
            description: "Prestação de conta salva com sucesso!",
          },
          successSubmit: {
            title: "Sucesso!",
            description: "Prestação de conta realizada com sucesso!",
          },
          requiredField: {
            title: "Campos obrigatórios",
            description_one: "Preencha o campo {{fields}} para prestar conta",
            description_other:
              "Preencha os campos {{fields, list(style: 'long'; type: 'conjunction';)}} para prestar conta",
          },
        },
      },
      visualizeMovement: {
        title: "Detalhe da movimentação",
        titleEdit: "Editar movimentação",
        back: "Voltar",
        help: "Central de ajuda",
        cancel: "Cancelar",
        submit: {
          save: "Salvar",
          sendExpenseToAccount: "Prestar conta",
        },
        toasts: {
          failedEdit: {
            title: "Não foi possível salvar a despesa",
            description: "Por favor, tente novamente dentro de alguns instantes.",
          },
          successEdit: {
            title: "Despesa salva com sucesso!",
          },
          failedSendingToAccount: {
            title: "Não foi possível enviar a despesa para aprovação",
            description: "Por favor, tente novamente dentro de alguns instantes.",
          },
          successSendingToAccount: {
            title: "Despesa enviada para aprovação com sucesso!",
          },
          requiredField: {
            title: "Campos obrigatórios",
            description: "Preencha o campo obrigatório para salvar a despesa",
          },
        },
      },
      visualizeReimbursement: {
        breadcrumbs: {
          current: "Detalhes do reembolso",
          previous: "Reembolsos",
        },
        title: "Detalhes do reembolso",
        titleEdit: "Editar reembolso",
        edit: "Editar reembolso",
        delete: "Excluir reembolso",
        back: "Voltar",
        help: "Central de ajuda",
        cancel: "Cancelar",
        submit: {
          save: "Salvar",
          sendExpenseToAccount: "Enviar para pagamento",
        },
        toasts: {
          failedEdit: {
            title: "Não foi possível salvar o reembolso",
            description: "Por favor, tente novamente dentro de alguns instantes.",
          },
          successEdit: {
            title: "Reembolso salvo com sucesso!",
          },
          failedSendingToAccount: {
            title: "Não foi possível enviar o reembolso para aprovação",
            description: "Por favor, tente novamente dentro de alguns instantes.",
          },
          successSendingToAccount: {
            title: "Reembolso enviado para aprovação com sucesso!",
          },
          requiredField: {
            title: "Campos obrigatórios",
            description: "Preencha o campo obrigatório para salvar o reembolso",
          },
          deleteSuccess: {
            title: "Reembolso excluído com sucesso!",
          },
          deleteError: {
            title: "Não foi possível excluir o reembolso",
            description: "Por favor, tente novamente dentro de alguns instantes.",
          },
        },
      },
      acquisitionForm: {
        banner: {
          tag: "Comece a usar Flash Despesas",
          title: "Como você planeja usar a Flash Despesas?",
          description: `Compartilhe a sua expectativa com o
            produto, estamos preparando a melhor experiência de controle e gestão de cartões corporativos para você e
            sua empresa.`,
        },
        cardQuestion: {
          title: "Previsão de gastos com Cartão Flash",
          description:
            "Qual é o valor mensal aproximado que sua empresa pretende gastar com os cartões corporativos Flash?",
          label: "Faixa de valor",
        },
        userQuestion: {
          title: "Responsável pela gestão da ferramenta de despesas",
          description: " Quem será o responsável pela gestão da ferramenta de Despesas e Cartão Corporativo?",
          label: "Responsável pela gestão",
          emailLabel: "E-mail do responsável",
        },
        implementationQuestion: {
          title: "Tempo de implementação da ferramenta",
          description: "Qual a expectativa de tempo para implementar Flash Despesas e Cartão Corporativo?",
          label: "Faixa de tempo",
        },
        button: "Salvar e começar a usar",
        options: {
          requiredField: "Campo obrigatório",
          budgeLabel:
            "Qual o valor mensal aproximado que sua empresa pretende gastar com os cartões corporativos Flash?",
          budgetOptions: {
            upTo10k: "Até R$10 mil",
            from10kTo20k: "De R$10,001 a R$20,000",
            from20kTo30k: "De R$20,001 a R$30,000",
            from30kTo50k: "De R$30,001 a R$50,000",
            from50kTo100k: "De R$50,001 a R$100,000",
            from100kTo200k: "De R$100,001 a R$200,000",
            above200k: "Acima de R$200,001",
            unknown: "Não sei dizer",
          },
          travelBudgeLabel: "Qual a média de valor gasto em viagens dos últimos 6 meses?",
          personLabel: "Quem será o responsável pela utilização do módulo de despesas e cartão corporativo?",
          personOptions: {
            me: "Eu",
            other: "Outra pessoa",
          },
          deadlineExpectationLabel:
            "Qual sua expectativa de prazo para implementar a solução de Despesas e Cartão Corporativo?",
          deadlineExpectation: {
            upTo1Month: "Até 1 mês",
            upTo2Month: "Até 2 meses",
            upTo3Month: "Até 3 meses",
            upTo4Month: "Até 4 meses",
            upTo5Month: "Até 5 meses",
            above6Months: "Acima de 6 meses",
            unknown: "Não sei dizer",
          },
        },
        error: {
          title: "Não foi possível finalizar seu cadastro",
          description: "Por favor, tente novamente dentro de alguns instantes.",
        },
      },
    },
    containers: {
      category: {
        categoryList: {
          toasts: {
            toggleError: {
              title: "Falha ao ativar/desativar categoria",
              description: "Tente novamente mais tarde",
            },
            deleteError: {
              title: "Erro ao excluir categoria",
              messages: {
                EXPENSES_EXISTS_WITH_CATEGORY: "Existem despesas associadas com essa categoria",
              },
            },
          },
          deleteModal: {
            headerIconLabel: "Atenção!",
            headerTitle: "Tem certeza que deseja excluir essa categoria?",
            headerSubtitle: "Todos os dados relacionados a ela serão perdidos e essa ação não poderá ser desfeita.",
            confirmDeletion: "Confirmar exclusão",
          },
          tooltips: {
            cantDeleteDefaultCategories: "Não é possível excluir categorias padrão",
          },
        },
      },
    },
    organisms: {
      modals: {
        defaultModal: {
          cancel: "Cancelar",
          confirm: "Confirmar",
        },
        confirmationModalDeleteExpense: {
          attention: "Atenção!",
          pluralForm: {
            popupTitle: "Tem certeza que deseja excluir os {{expensesSelected}} reembolsos selecionados?",
            popupSubtitle: "Essa ação não poderá ser desfeita.",
            confirm: "Confirmar exclusões",
          },
          singularForm: {
            popupTitle: "Tem certeza que deseja excluir o reembolso?",
            popupSubtitle: "Essa ação não poderá ser desfeita.",
            confirm: "Confirmar exclusão",
          },
        },
        confirmationModalEditExpense: {
          [ExpenseType.REIMBURSEMENT]: {
            attention: "Atenção!",
            title: "Tem certeza que deseja editar o reembolso?",
            extraTitle: "Tem certeza que deseja editar o reembolso?",
            subtitle: "Ao editar um reembolso em aprovação, ele precisará passar pelo fluxo de aprovação novamente.",
            adminSubtitle: "Ele ainda está sob responsabilidade de quem efetuou o gasto.",
            confirm: "Confirmar",
          },
          [ExpenseType.CORPORATE_CARD]: {
            attention: "Atenção!",
            title: "Tem certeza que deseja editar a prestação de conta?",
            subtitle:
              "Ao editar a prestação de conta em aprovação, ela precisará passar pelo fluxo de aprovação novamente.",
            adminSubtitle: "Ela ainda está sob responsabilidade de quem efetuou o gasto.",
            confirm: "Confirmar",
          },
        },
        confirmationModalReclassifyTransaction: {
          title: "Mudar saldo da compra",
          titleCardLabels: {
            establishment: "Estabelecimento",
            payment: "Pagamento",
            expenseAmount: "Valor da despesa",
            corporateBalance: "Saldo corporativo",
            cardType: {
              [ExpenseTransactionCardType.PLASTIC]: "Cartão Físico",
              [ExpenseTransactionCardType.VIRTUAL]: "Cartão Virtual",
            } satisfies Record<ExpenseTransactionCardType, string>,
          },
          explanationTitle: "O que irá acontecer com os saldos?",
          explanationBody: "O valor da compra sairá do saldo corporativo e consumirá o saldo de {{benefitName}}.",
          contentCardLabels: {
            corporate: "Corporativo",
            currentBalance: "Saldo Atual",
            balanceAfterChange: "Saldo após mudança",
          },
          buttons: {
            cancel: "Cancelar",
            confirm: "Confirmar",
          },
          successModal: {
            iconLabel: "Tudo certo!",
            headerTitle: "Saldo da compra alterado com sucesso!",
            headerSubtitle: "A compra foi alterada do saldo corporativo para o saldo {{benefitName}}.",
            buttons: {
              confirm: "Concluir",
            },
          },
          warningModal: {
            iconLabel: "Atenção!",
            headerTitle: "Não foi possível mudar saldo da compra",
            headerSubtitle: "Por favor, tente novamente dentro de alguns instantes.",
            buttons: {
              confirm: "Ok, entendi",
            },
          },
        },
      },
      expenseUserGroupList: {
        emptyState: {
          title: "Nenhum resultado encontrado",
          description: "Revise a busca ou os filtros e tente novamente.",
        },
      },
      listAccordions: {
        filters: "Filtros",
        orderBy: "Ordenar por",
        searchByTeamMember: "Buscar por membro da equipe",
      },
      expenseTable: {
        emptyState: "Nenhuma solicitação de reembolso cadastrada",
        filters: "Filtros",
        searchByCategory: "Buscar por categoria",
        pageSizeFormat: "{{value}} Itens",
        noCategory: "Não categorizada",
        transactionStatus: common.transactionStatus,
        toasts: {
          undeletableExpensesSelected: {
            title: "Erro",
            description: "Despesas não excluíveis estão selecionadas",
          },
        },
        columns: {
          date: "Data",
          description: {
            [ExpenseType.REIMBURSEMENT]: "Descrição",
            [ExpenseType.CORPORATE_CARD]: "Transaction",
          } satisfies Record<ExpenseType, string>,
          amount: "Valor",
          receipt: "Recibo",
          status: "Status",
          actions: "Ações",
        },
        actions: {
          edit: "Editar",
          delete: "Excluir",
          visualize: "Ver detalhes",
          requestReview: "Solicitar revisão",
          checkmark: {
            [ExpenseType.REIMBURSEMENT]: "Marcar como reembolsado",
            [ExpenseType.CORPORATE_CARD]: "Aprovar",
          } satisfies Record<ExpenseType, string>,
          verticalDotsDropdown: {
            tooltip: "",
            visualize: {
              [ExpenseType.REIMBURSEMENT]: "Ver detalhes do reembolso",
              [ExpenseType.CORPORATE_CARD]: "Ver detalhes da prestação",
            } satisfies Record<ExpenseType, string>,
            edit: {
              [ExpenseType.REIMBURSEMENT]: "Editar reembolso",
              [ExpenseType.CORPORATE_CARD]: "Editar prestação",
            } satisfies Record<ExpenseType, string>,
            reject: {
              [ExpenseType.REIMBURSEMENT]: "Reprovar reembolso",
              [ExpenseType.CORPORATE_CARD]: "Reprovar prestação",
            } satisfies Record<ExpenseType, string>,
          },
          reopen: "Reabrir",
          notify: "Notificar",
        },
        visions: {
          open: "Abertos",
          finished: "Finalizados",
          all: "Todos",
        },
      },
      expenseUserGroupRow: {
        [ExpenseType.REIMBURSEMENT]: {
          emptyState: {
            buttonText: "Adicionar Despesa",
            description: "O colaborador não possui nenhuma despesa vinculada",
          },
          actions: {
            check: "Marcar todos como reembolsado",
            notify: "Notificar",
            options: "Opções",
          },
          infoCard: {
            pendingExpenses_one: "{{count}} despesa",
            pendingExpenses_other: "{{count}} despesas",
            totalOpen: "Total gasto",
            awaitAccounting: common.expenseStatusReimbursements.PENDING_ACCOUNTING,
            awaitApproval: "Em prestação",
          },
          expenseStatus: common.expenseStatusReimbursements,
        },
        [ExpenseType.CORPORATE_CARD]: {
          emptyState: {
            buttonText: "",
            description: "O colaborador não possui nenhuma conta a prestar",
          },
          actions: {
            check: "Aprovar tudo",
            notify: "Notificar",
            options: "Opções",
          },
          infoCard: {
            pendingExpenses_one: "{{count}} despesa",
            pendingExpenses_other: "{{count}} despesas",
            totalOpen: "Total gasto",
            awaitAccounting: common.expenseStatusCorporateCard.PENDING_ACCOUNTING,
            awaitApproval: "Em prestação",
          },
          expenseStatus: common.expenseStatusCorporateCard,
        },
      },
      expenseFilterDrawer: {
        filters: "Filtros",
        submit: "Filtrar",
        reset: "Limpar filtros",
        noCategory: "Não categorizada",
        fields: {
          dateRange: {
            title: "Período",
            presets: {
              lastMonth_one: "Último mês",
              lastMonth_other: "Último {{count}} meses",
            },
          },
          reimbursementsStatus: {
            title: "Status do reembolso",
            labels: common.expenseStatusReimbursements,
          },
          corporateCardStatus: {
            title: "Status da prestação",
            labels: common.expenseStatusCorporateCard,
          },
          transactionStatus: {
            title: "Status da movimentação",
            labels: common.transactionStatus,
          },
          category: {
            title: "Categoria",
          },
          amountRange: {
            title: "Valor",
          },
        },
      },
      movementTable: {
        tabs: {
          allMovements: "Todas as movimentações",
          ins: "Entradas",
          outs: "Saídas",
        },
        emptyState: "Nenhuma movimentação cadastrada",
        addReceipt: "Adicionar recibo",
        filters: "Filtros",
        pageSizeFormat: "{{value}} Itens",
        noCategory: "Não categorizada",
        failedToGetMovements: "Erro ao buscar movimentações",
        cardType: {
          [ExpenseTransactionCardType.PLASTIC]: "Físico",
          [ExpenseTransactionCardType.VIRTUAL]: "Virtual",
        } satisfies Record<ExpenseTransactionCardType, string>,
        expenseStatus: common.expenseStatusCorporateCard,
        cardLastDigits: "Final {{cardLastDigits}}",
        transactionStatus: common.transactionStatus,
        columns: {
          date: "Data",
          description: "Movimentação",
          amount: "Valor",
          payment: "Pagamento",
          status: "Status da prestação",
          receipt: "Recibo",
          actions: "Ações",
        },
        actions: {
          sendToAccount: {
            enabled: "Prestar contas",
            disabled: "A compra ainda não foi confirmada. Aguarde a confirmação para prestar contas",
          },
          reclassify: "Mudar saldo",
          visualize: "Visualizar",
          empty: {
            tooltip: "Depósitos e estornos não possuem prestação de conta",
          },
          canceledTransaction: {
            tooltip: "Movimentações canceladas não possuem prestação de conta",
          },
        },
      },
      pendingExpenseGrid: {
        failedToGetPendencies: "Falha ao carregar pendências",
        totalAmount: "Valor total",
        amount: "Valor",
        emptyState: "Nenhuma despesa pendente",
        reviewPendingIssues: "Revisar pendências",
        cardType: {
          [ExpenseTransactionCardType.PLASTIC]: "Cartão Físico",
          [ExpenseTransactionCardType.VIRTUAL]: "Cartão Virtual",
        } satisfies Record<ExpenseTransactionCardType, string>,
        cardLastDigits: "Final {{cardLastDigits}}",
        expensePendingAction: {
          [PendingExpenseAction.CATEGORY_MISSING]: "Categoria pendente",
          [PendingExpenseAction.MULTIPLE_ITEMS_MISSING]: "Itens pendentes",
          [PendingExpenseAction.PENDING_SUBMIT]: "Envio pendente",
          [PendingExpenseAction.RECEIPT_MISSING]: "Recibo pendente",
        } satisfies Record<PendingExpenseAction, string>,
        actionDetails: {
          title: {
            [PendingExpenseAction.CATEGORY_MISSING]: "Categoria",
            [PendingExpenseAction.MULTIPLE_ITEMS_MISSING]: "Recibo",
            [PendingExpenseAction.PENDING_SUBMIT]: "Despesa",
            [PendingExpenseAction.RECEIPT_MISSING]: "Recibo",
          } satisfies Record<PendingExpenseAction, string>,
          subtitle: {
            [PendingExpenseAction.CATEGORY_MISSING]: "Adicionar categoria",
            [PendingExpenseAction.MULTIPLE_ITEMS_MISSING]: "Adicionar recibo",
            [PendingExpenseAction.PENDING_SUBMIT]: "Prestar conta",
            [PendingExpenseAction.RECEIPT_MISSING]: "Adicionar recibo",
          } satisfies Record<PendingExpenseAction, string>,
        },
        actions: {
          sendToAccount: "Prestar conta",
          reclassify: "Mudar saldo",
        },
        pendingExpenses_zero: "Nenhuma despesa pendente",
        pendingExpenses_one: "{{count}} Despesa pendente",
        pendingExpenses_other: "{{count}} Despesas pendentes",
        pageSizeFormat: "{{value}} Itens",
      },
      expenseSummaryChart: {
        [ExpenseType.REIMBURSEMENT]: {
          title: "Total de Reembolsos",
          expenses_zero: "Nenhum Reembolso",
          expenses_one: "{{count}} Reembolso",
          expenses_other: "{{count}} Reembolsos",
        },
        [ExpenseType.CORPORATE_CARD]: {
          cardTitle: "Prestação de conta de cartões",
          title: "Total gasto em despesas",
        },
      },
      expenseSummaryStatusCard: {
        [ExpenseType.REIMBURSEMENT]: {
          expenses_zero: "Nenhum Reembolso",
          expenses_one: "{{count}} Reembolso",
          expenses_other: "{{count}} Reembolsos",
          single: "Reembolso",
          multiple: "Reembolsos",
          statusCard: {
            removeFilter: "Remover Filtro",
            applyFilter: "Filtrar",
          },
          status: {
            awaitingAccounting: common.expenseStatusReimbursements[ExpenseStatus.PENDING_ACCOUNTING],
            awaitingApproval: common.expenseStatusReimbursements[ExpenseStatus.PENDING_APPROVAL],
            paid: common.expenseStatusReimbursements[ExpenseStatus.FINISHED],
            pending: "Em prestação",
            rejected: "Reprovado",
          },
          tooltip: {
            [CombinedExpenseStatus.DRAFT_OR_REQUIRE_CHANGES]:
              "Solicitações de reembolso pendentes com quem realizou o gasto.",
            [CombinedExpenseStatus.PENDING_ACCOUNTING]: "Reembolsos que aguardam o pagamento por parte da empresa.",
            [CombinedExpenseStatus.FINISHED]: "Reembolsos finalizados.",
            [CombinedExpenseStatus.REJECTED]: "Solicitações de reembolso reprovadas.",
          },
        },
        [ExpenseType.CORPORATE_CARD]: {
          statusCard: {
            removeFilter: "Remover Filtro",
            applyFilter: "Filtrar",
          },
          status: {
            awaitingAccounting: common.expenseStatusCorporateCard[ExpenseStatus.PENDING_ACCOUNTING],
            awaitingApproval: common.expenseStatusCorporateCard[ExpenseStatus.PENDING_APPROVAL],
            paid: common.expenseStatusCorporateCard[ExpenseStatus.FINISHED],
            pending: "Em prestação",
            rejected: "Reprovado",
          },
          tooltip: {
            DRAFT_OR_REQUIRE_CHANGES: "Prestações de conta pendentes com quem realizou o gasto.",
            PENDING_ACCOUNTING: "Prestações de conta que aguardam a aprovação por parte da empresa.",
            FINISHED: "Prestações de conta finalizadas.",
            REJECTED: "Prestações de conta reprovadas.",
          },
        },
      },
      expensesStatusUpdateModal: {
        variants: {
          [ExpenseType.REIMBURSEMENT]: {
            [ExpenseStatus.FINISHED]: {
              headerTitle_one: "Tem certeza que deseja marcar a despesa como reembolsada?",
              headerTitle_other: "Tem certeza que deseja marcar todas as {{count}} despesas como reembolsadas?",
              buttonLabel: "Confirmar reembolso",
              toasts: {
                failed: {
                  title_one: "Não foi possível marcar a despesa como reembolsada",
                  title_other: "Não foi possível marcar as despesas como reembolsadas",
                },
                success: {
                  title_one: "Despesa marcada como reembolsada com sucesso!",
                  title_other: "Despesas marcadas como reembolsadas com sucesso!",
                },
              },
            },
            [ExpenseStatus.REQUIRE_CHANGES]: {
              headerTitle_one: "Tem certeza que deseja enviar o reembolso para revisão?",
              headerTitle_other: "Tem certeza que deseja enviar todos os {{count}} reembolsos para revisão?",
              headerSubtitle: "A pessoa que solicitou o reembolso receberá como uma pendência para revisar.",
              buttonLabel: "Confirmar",
              reasonLabel_one: "Explique o motivo para a revisão",
              reasonLabel_other: "Explique o motivo para as revisões",
              toasts: {
                failed: {
                  title_one: "Não foi possível enviar o reembolso para revisão",
                  title_other: "Não foi possível enviar os reembolsos para revisão",
                },
                success: {
                  title_one: "Reembolso enviado para revisão com sucesso!",
                  title_other: "Reembolsos enviados para revisão com sucesso!",
                },
              },
            },
            [ExpenseStatus.REJECTED]: {
              headerTitle_one: "Tem certeza que deseja reprovar o reembolso?",
              headerTitle_other: "Tem certeza que deseja reprovar {{count}} reembolsos?",
              buttonLabel: "Reprovar",
              reasonLabel_one: "Selecione um motivo para a reprovação",
              reasonLabel_other: "Selecione um motivo para as reprovações",
              toasts: {
                failed: {
                  title_one: "Não foi possível reprovar o reembolso",
                  title_other: "Não foi possível reprovar os reembolsos",
                },
                success: {
                  title_one: "Reembolso marcado como reprovado com sucesso!",
                  title_other: "Reembolsos marcados como reprovado com sucesso!",
                },
              },
            },
            [ExpenseStatus.PENDING_ACCOUNTING]: {
              headerTitle_one: "Tem certeza que deseja reabrir o reembolso?",
              headerTitle_other: "Tem certeza que deseja reabrir {{count}} reembolsos?",
              headerSubtitle_one: 'Ao reabrir, ela voltará ao status "em pagamento"',
              headerSubtitle_other: 'Ao reabrir, elas voltarão ao status "em pagamento"',
              buttonLabel: "Reabrir",
              toasts: {
                failed: {
                  title_one: "Não foi possível reabrir o reembolso",
                  title_other: "Não foi possível reabrir os reembolsos",
                },
                success: {
                  title_one: "Reembolso reaberto com sucesso!",
                  title_other: "Reembolsos reabertos com sucesso!",
                },
              },
            },
          },
          [ExpenseType.CORPORATE_CARD]: {
            [ExpenseStatus.FINISHED]: {
              headerTitle_one: "Tem certeza que deseja aprovar a prestação de conta?",
              headerTitle_other: "Tem certeza que deseja aprovar as {{count}} prestações de conta?",
              buttonLabel: "Confirmar",
              toasts: {
                failed: {
                  title_one: "Não foi possível finalizar a prestação de conta",
                  title_other: "Não foi possível finalizar as prestações de contas",
                },
                success: {
                  title_one: "Prestação de conta finalizada com sucesso!",
                  title_other: "Prestações de contas finalizadas com sucesso!",
                },
              },
            },
            [ExpenseStatus.REQUIRE_CHANGES]: {
              headerTitle_one: "Tem certeza que deseja enviar a prestação de conta para revisão?",
              headerTitle_other: "Tem certeza que deseja enviar todas as {{count}} prestações de contas para revisão?",
              headerSubtitle:
                "A pessoa que realizou a despesa receberá como uma pendência para revisar a prestação de conta.",
              buttonLabel: "Confirmar",
              reasonLabel_one: "Explique o motivo para a revisão",
              reasonLabel_other: "Explique o motivo para as revisões",
              toasts: {
                failed: {
                  title_one: "Não foi possível enviar a prestação de conta para revisão",
                  title_other: "Não foi possível enviar as prestações de contas para revisão",
                },
                success: {
                  title_one: "Prestação de conta enviada para revisão com sucesso!",
                  title_other: "Prestações de contas enviadas para revisão com sucesso!",
                },
              },
            },
            [ExpenseStatus.REJECTED]: {
              headerTitle_one: "Tem certeza que deseja reprovar a prestação de conta?",
              headerTitle_other: "Tem certeza que deseja reprovar {{count}} prestações de conta?",
              buttonLabel: "Reprovar",
              reasonLabel_one: "Selecione um motivo para a reprovação",
              reasonLabel_other: "Selecione um motivo para as reprovações",
              toasts: {
                failed: {
                  title_one: "Não foi possível finalizar a prestação de conta",
                  title_other: "Não foi possível reprovar as prestações de conta",
                },
                success: {
                  title_one: "Prestação de conta reprovada com sucesso!",
                  title_other: "Prestações de contas reprovadas com sucesso!",
                },
              },
            },
            [ExpenseStatus.PENDING_ACCOUNTING]: {
              headerTitle_one: "Tem certeza que deseja reabrir a prestação de conta?",
              headerSubtitle_one: 'Ao reabrir, ela voltará ao status "em fechamento"',
              headerTitle_other: "Tem certeza que deseja reabrir {{count}} prestações de conta?",
              headerSubtitle_other: 'Ao reabrir, elas voltarão ao status "em fechamento"',
              buttonLabel: "Reabrir",
              toasts: {
                failed: {
                  title_one: "Não foi possível reabrir a prestação de conta",
                  title_other: "Não foi possível reabrir as prestações de conta",
                },
                success: {
                  title_one: "Prestação de conta reaberta com sucesso!",
                  title_other: "Prestações de contas reabertas com sucesso!",
                },
              },
            },
          },
        },
        pleaseSelectAReason: "Por favor, selecione um motivo",
        warning: "Atenção!",
        pleaseTryAgainLater: "Por favor, tente novamente dentro de alguns instantes.",
      },
      expensesNotifyModal: {
        headerIconLabel: "Atenção",
        headerTitle: "Tem certeza que deseja notificar a pessoa?",
        headerSubtitle:
          "A pessoa será notificada de que precisa realizar a prestação de contas das despesas selecionadas.",
        buttonLabel: "Notificar",
        toasts: {
          notifySuccess: "Notificação enviada com sucesso!",
          notifyFailed: "Não foi possível enviar a notificação",
          pleaseTryAgainLater: "Por favor, tente novamente dentro de alguns instantes.",
        },
      },
      filterEmptyState: {
        title: "Nenhum resultado encontrado",
        subtitle: "Revise a busca, ou os filtros e tente novamente.",
      },
    },
    molecules: {
      categoryFormModal: {
        editCategory: "Editar categoria",
        createCategory: "Criar categoria",
        save: "Salvar",
        create: "Criar",
        fields: {
          name: {
            label: "Nome da categoria",
          },
          externalId: {
            label: "Código",
            helperText: "Insira o código utilizado pela sua empresa para melhor controle.",
          },
          activated: {
            label: "Ativo/Inativo",
          },
        },
        requiredField_one: "Campo obrigatório",
        requiredField_other: "Campos obrigatórios",
        helperTextCodeField: "Código de referência da categoria",
        mandatoryDescription: "Campos obrigatórios",
        toasts: {
          successCreated: {
            title: "Sucesso",
            description: "Categoria criada com sucesso!",
          },
          successEdited: {
            title: "Sucesso",
            description: "Categoria editada com sucesso!",
          },
          errorCreate: {
            title: "Erro ao criar categoria",
            messages: {
              DUPLICATED_CATEGORY_NAME: "Já existe uma categoria com o mesmo nome",
            },
          },
          errorEdit: {
            title: "Erro ao editar categoria",
            messages: {
              DUPLICATED_CATEGORY_NAME: "Já existe uma categoria com o mesmo nome",
            },
          },
        },
        tooltips: {
          cantEditDefaultCategoryName: "Não é possível editar nome de categorias padrão",
        },
      },
      dateRangeField: { label: "Seletor de período" },
      filterButton: {
        filters: "Filtros",
        tooltip: {
          tooltipTitle_one: "Você tem {{count}} filtro selecionado",
          tooltipTitle_other: "Você tem {{count}} filtros selecionados",
        },
      },
      errorBoundary: {
        defaultTitle: "Erro ao tentar carregar o conteúdo",
        pageLabel: "Página:",
        moduleLabel: "Módulo:",
        retryButton: "Tentar novamente",
        isolatedError: {
          modulePrefix: "Módulo:",
          defaultTitle: "Ocorreu um erro",
          defaultDescription: "Algo deu errado ao renderizar este módulo.",
        },
      },
      defaultModal: {
        cancel: "Cancelar",
        confirm: "Confirmar",
      },
      receiptDropzone: {
        title: "Comprovante",
        empty: {
          title: "Arraste e solte aqui o seu arquivo",
          subtitle: "Formatos permitidos: {{acceptedFileExtensions}}",
          uploadButton: "Selecionar arquivo",
        },
        addReceipt: "Adicionar recibo",
        prevReceipt: "Comprovante anterior",
        nextReceipt: "Próximo comprovante",
        alt: "Comprovante",
        loading: "Carregando recibos...",
        modals: {
          delete: {
            iconLabel: "Atenção!",
            title: "Tem certeza que deseja remover este recibo?",
            subtitle: "Todos os dados deste recibo serão perdidos e essa ação não poderá ser desfeita.",
            action: "Remover",
          },
        },
        toasts: {
          uploadError: {
            title: "Falha ao enviar comprovante",
            messages: {
              INVALID_FILE_FORMAT: "Arquivo com formato inválido, por favor, tente novamente com um arquivo diferente.",
            },
          },
        },
      },
      reimbursementForm: {
        amount: "R$0,00",
        establishment: "Estabelecimento",
        category: "Categoria",
        expenseAmount: "Valor da despesa",
        fields: {
          currency: "Moeda",
          amount: "Valor",
          date: "Data da despesa",
          category: "Categoria",
          description: "Comentário (opcional)",
        },
        categoryLoading: "Categoria (Carregando...)",
      },
      expenseFooterActions: {
        [ExpenseType.REIMBURSEMENT]: {
          exitAndSave: "Sair e salvar alterações",
          addAnother: "Salvar e solicitar outro",
          submit: {
            onLoading: "Solicitar reembolso",
            description: "Solicitar reembolso",
          },
          toasts: {
            requiredField: {
              title: "Campos obrigatórios",
              saveDescription: "Preencha o campo obrigatório para salvar as alterações",
              description: "Preencha o campo obrigatório para envio da solicitação",
            },
          },
          new: "Novo reembolso",
          edit: "Editar reembolso",
          cancel: "Cancelar",
          return: "Voltar",
          save: "Salvar",
          requestReimbursement: "Solicitar reembolso",
          creating: "Criando reembolso...",
          updating: "Atualizando reembolso...",
        },
        [ExpenseType.CORPORATE_CARD]: {
          exitAndSave: "Sair e salvar alterações",
          addAnother: "",
          submit: {
            onLoading: "Salvar alteração",
            description: "Salvar alteração",
          },
          toasts: {
            requiredField: {
              title: "Campos obrigatórios",
              saveDescription: "Preencha o campo obrigatório para salvar as alterações",
              description: "Preencha o campo obrigatório para salvar as alterações",
            },
          },
          new: "",
          edit: "Editar movimento",
          cancel: "Cancelar",
          return: "Voltar",
          save: "Salvar alteração",
          requestReimbursement: "Salvar alteração",
          creating: "Salvando...",
          updating: "Atualizando...",
        },
      },

      imagesModal: {
        toasts: {
          failedLoadingReceipts: {
            title: "Erro!",
            description: "Falha ao carregar comprovantes, por favor tente novamente mais tarde",
          },
        },
      },
      silderField: {
        min: "Valor Mínimo",
        max: "Valor Máximo",
      },
      checkboxGroup: {
        selectAll: "Selecionar todos",
      },
      expenseStatusTag: {
        expenseStatus: {
          [ExpenseType.REIMBURSEMENT]: common.expenseStatusReimbursements,
          [ExpenseType.CORPORATE_CARD]: common.expenseStatusCorporateCard,
        } satisfies Record<ExpenseType, Record<ExpenseStatus, string>>,
      },
      categorySelect: {
        label: "Categoria",
        errorDescription: "Este campo deve ser preenchido",
      },
      expenseDescriptionInput: {
        label: "Comentário (opcional)",
        maxCharacters: "Máximo {{count}} caracteres",
      },
      finishedPendingItemsList: {
        title: "Prestações de contas realizadas com sucesso!",
        subTitle: "As despesas já foram enviadas para os aprovadores. Acesse os detalhes para mais informações.",
        cardTitle: "Estabelecimento",
        expenseAmount: "Valor da despesa",
      },
      accountabilitySurvey: {
        AccountabilityEasiness:
          "[1/3] Como você avalia a facilidade de uso da plataforma ao prestar conta de um gasto com o cartão Flash?",
        AccountabilityAttendance:
          "[2/3] O quanto a prestação de contas dos gastos corporativos atende às suas necessidades?",
        rates: {
          terrible: "Péssima",
          bad: "Ruim",
          regular: "Regular",
          good: "Boa",
          great: "Ótima",
          notRespond: "Não atende em nada",
          partiallyRespond: "Atende Parcialmente",
          totallyRespond: "Atende totalmente",
        },
      },

      pendingsItemsForm: {
        view: "Ver despesa",
        accordionFooter: "Envie os recibos e preencha as informações pendentes das suas despesas.",
        cardTitle: "Estabelecimento",
        expenseAmount: "Valor da despesa",
        expenseDate: "Data da despesa",
        expenseDateValue: "{{date}}, às {{time}}",
        payment: "Pagamento",
        corporative: "Saldo corporativo",
        reviewReason: "Motivo da revisão: {{accountingComments}}",
        reviewedBy: "Marcada para revisão por {{name}}",
        expenseStatus: common.expenseStatusCorporateCard,
        cardType: {
          [ExpenseTransactionCardType.PLASTIC]: "Cartão Físico",
          [ExpenseTransactionCardType.VIRTUAL]: "Cartão Virtual",
        } satisfies Record<ExpenseTransactionCardType, string>,
        balanceChange: {
          title: "Comprou com saldo errado?",
          subTitle: "Sem problemas, mude sua compra para o saldo de benefícios aqui.",
          submit: "Mudar saldo",
        },
      },
      expenseStatusStepperCard: {
        title: "Status da prestação",
        expenseStatus: {
          [ExpenseType.REIMBURSEMENT]: common.expenseStatusReimbursements,
          [ExpenseType.CORPORATE_CARD]: common.expenseStatusCorporateCard,
        } satisfies Record<ExpenseType, Record<ExpenseStatus, string>>,
        subtitle: "Em caso de irregularidade com a prestação, entre em contato com o seu gestor.",
      },
      reasonSelect: {
        [ExpenseType.REIMBURSEMENT]: {
          label: "Motivo",
          otherReasonLabel: "Adicione uma observação",
          reasons: {
            absenceOfReceipt: "Ausência de comprovante",
            invalidReceipt: "Comprovante inválido",
            lateRefundRequest: "Solicitação de reembolso fora do prazo",
            suspectedFraud: "Suspeita de fraude",
            valueAboveWhatForeseenPolicy: "Valor acima do previsto na política",
            other: "Outro motivo",
          },
        },
        [ExpenseType.CORPORATE_CARD]: {
          label: "Motivo",
          otherReasonLabel: "Adicione uma observação",
          reasons: {
            absenceOfReceipt: "Ausência de comprovante",
            invalidReceipt: "Comprovante inválido",
            lateRefundRequest: "Prestação de conta fora do prazo",
            suspectedFraud: "Suspeita de fraude",
            valueAboveWhatForeseenPolicy: "Valor acima do previsto na política",
            other: "Outro motivo",
          },
        },
      },
      movementForm: {
        fields: {
          category: "Categoria",
          comments: "Comentário",
        },
        edit: "Editar prestação de conta",
        cardTitle: "Estabelecimento",
        expenseAmount: "Valor da despesa",
        expenseDate: "Data da despesa",
        expenseDateValue: "{{date}}, às {{time}}",
        payment: "Pagamento",
        corporative: "Saldo corporativo",
        canceledTransaction: {
          title: "Compra cancelada",
          subtitle:
            "A prestação de conta foi reprovada porquê a compra foi cancelada pelo estabelecimento, em caso de dúvida sobre o motivo, contate-o diretamente.",
          bottomSubtitle: "O valor já foi devolvido ao seu saldo.",
        },
        reviewReason: "Motivo da revisão: {{accountingComments}}",
        rejectionReason: "Motivo da reprovação: {{accountingComments}}",
        reviewedBy: "Marcada para revisão por {{name}}",
        rejectedBy: "Reprovada por {{name}}",
        errorSubmitting: "Ocorreu um erro ao submeter para revisão",
        cardType: {
          [ExpenseTransactionCardType.PLASTIC]: "Cartão Físico",
          [ExpenseTransactionCardType.VIRTUAL]: "Cartão Virtual",
        } satisfies Record<ExpenseTransactionCardType, string>,
        expenseStatus: common.expenseStatusCorporateCard,
        balanceChange: {
          title: "Comprou com saldo errado?",
          subTitle: "Sem problemas, mude sua compra para o saldo de benefícios aqui.",
          submit: "Mudar saldo",
        },
      },
      reimbursementDetails: {
        fields: {
          date: "Data da despesa",
          id: "ID da despesa",
          category: "Categoria",
          comments: "Comentário",
        },
        edit: "Editar reembolso",
        cardTitle: "Estabelecimento",
        expenseAmount: "Valor da despesa",
        expenseDate: "Data da despesa",
        expenseDateValue: "{{date}}",
        payment: "Pagamento",
        corporative: "Saldo corporativo",
        reviewReason: "Motivo da revisão: {{accountingComments}}",
        reviewedBy: "Marcado para revisão por {{name}}",
        rejectedBy: "Reprovado por {{name}}",
        rejectionReason: "Motivo da reprovação: {{accountingComments}}",
        errorSubmitting: "Ocorreu um erro ao submeter para revisão",
        cardType: {
          [ExpenseTransactionCardType.PLASTIC]: "Cartão Físico",
          [ExpenseTransactionCardType.VIRTUAL]: "Cartão Virtual",
        } satisfies Record<ExpenseTransactionCardType, string>,
        expenseStatus: common.expenseStatusReimbursements,
      },
      accountBalanceCard: {
        title: "Saldo corporativo",
        linkButtonText: "Accessar extratos",
        initialDateTitle: "Total inicial na Flash (em {{- dateValue, datetime}})",
        finalDateTitle: "Total final na Flash (em {{- dateValue, datetime}})",
        wallet: "Carteira corporativa",
        cards: "Cartões corporativos",
      },
      establishmentSelect: {
        establishment: "Estabelecimento",
        typeEstablishmentNameOrAddress: "Digite o nome ou o endereço do estabelecimento.",
        noEstablishmentFound: "Nenhum estabelecimento encontrado",
        loading: "Carregando...",
      },
    },
    externals: {
      dashboardReimbursementsSection: {
        title: "Reembolso",
      },
      reimbursementsSummarySection: {
        title: "Reembolsos",
        action: {
          label: "Acessar reembolsos",
        },
        chart: {
          totalReimbursements: "Total de reembolsos (últimos 30 dias)",
        },
        tooltip: {
          [ExpenseStatus.DRAFT]:
            "Solicitações de reembolso pendentes, que estão com o colaborador ou aguardam análise e pagamento por parte da empresa.",
          [ExpenseStatus.FINISHED]: "Reembolsos finalizados.",
          [ExpenseStatus.REJECTED]: "Solicitações de reembolso reprovadas.",
        },
        status: {
          open: "Abertos",
          paid: "Reembolsados",
          rejected: "Reprovados",
        },
      },
      statementSummarySection: {
        title: "Prestação de contas de cartões",
        action: {
          label: "Acessar fechamento",
        },
        chart: {
          totalStatement: "Total de despesas (últimos 30 dias)",
        },
        tooltip: {
          [ExpenseStatus.DRAFT]:
            "Prestações de contas pendentes, que estão com o colaborador ou aguardam aprovação da empresa.",
          [ExpenseStatus.FINISHED]: "Prestações de contas finalizadas.",
          [ExpenseStatus.REJECTED]: "Prestações de contas reprovadas.",
        },
        status: {
          open: "Abertas",
          paid: "Finalizadas",
          rejected: "Reprovadas",
        },
      },
    },
  },
};

export default translations;
