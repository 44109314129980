import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { trpc } from "$client";
import { handleTrcpError, useDisplayToast } from "$frontend/utils";
import { Expense } from "$serverTypes";
import { useState } from "react";
import { ExpenseFormDto } from "../types";

export function useCreateExpense() {
  const { displayToast } = useDisplayToast();
  const { t } = useTranslation("translations", {
    keyPrefix: "pages.createReimbursement",
  });

  const methods = useForm<ExpenseFormDto>({
    defaultValues: {
      amount: undefined,
      attachments: undefined,
      comments: undefined,
      date: undefined,
      category: undefined,
    },
  });
  const [expenses, setExpenses] = useState<Expense[]>([]);
  const [showSummary, setShowSummary] = useState(false);

  const { mutateAsync: createExpense, isLoading } = trpc.expense.createExpenseV2.useMutation();

  async function onSubmit(formData: ExpenseFormDto, shouldSendToAccount: boolean, shouldNavigateBack: boolean) {
    if (!formData.date || isNaN(new Date(formData.date).getTime())) return;

    const expense = {
      ...formData,
      currency: "BRL",
      date: formData.date.toISOString(),
      establishment: formData.establishment
        ? {
            sessionToken: formData.establishment.sessionToken,
            placeId: formData.establishment.placeAutocomplete.placeId,
          }
        : undefined,
    };
    try {
      const createdExpense = await createExpense({ data: expense, sendToAccount: shouldSendToAccount });
      setExpenses([...expenses, createdExpense]);
      displayToast({
        title: t("toasts.creationSuccess.title"),
      });

      if (shouldNavigateBack) {
        setShowSummary(true);
      } else {
        methods.reset();
      }
    } catch (e) {
      const description = handleTrcpError(e, (errorCode) =>
        t(`toasts.creationFailed.messages.${errorCode}`, {
          defaultValue: null,
        }),
      );

      displayToast({
        type: "error",
        title: t("toasts.creationFailed.title"),
        description,
      });
    }
  }

  return {
    onSubmit,
    isLoading,
    methods,
    expenses,
    showSummary,
  };
}
