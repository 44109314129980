import { ShapeIcon } from "$atoms";
import { DangerActionModal, FocusedFooter, ReasonSelect, SuccessActionModal } from "$frontend/components/molecules";
import { Alignment } from "$frontend/utils";
import { Expense, ExpenseType } from "$serverTypes";
import { Button, Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAccountExpense } from "../data/useAccountExpense";
import { useRejectExpense } from "../data/useRejectExpense";
import { useRequestReview } from "../data/useRequestReview";
export function PendingAccountActions({ expense }: { expense: Expense }) {
  const { t } = useTranslation("translations", { keyPrefix: "pages.adminMovement" });

  const { rejectExpense, isRejecting } = useRejectExpense(expense.type);
  const { requestReview, isRequestingReview } = useRequestReview(expense.type);
  const { accountMovement, isAccounting } = useAccountExpense();

  const [openRejectModal, setOpenRejectModal] = useState(false);
  const [openRequestReviewModal, setOpenRequestReviewModal] = useState(false);
  const [openAccountMovementModal, setOpenAccountMovementModal] = useState(false);

  const [selectedReason, setSelectedReason] = useState<string>("");
  return (
    <>
      <FocusedFooter
        end={[
          <Button
            key="reject"
            variant="secondary"
            variantType="neutral"
            size="small"
            onClick={() => setOpenRejectModal(true)}
          >
            {t("extraButtons.reject")} <Icons name="IconX" fill="transparent" size={24} />
          </Button>,
          <Button
            key="request-review"
            variant="secondary"
            variantType="neutral"
            size="small"
            onClick={() => setOpenRequestReviewModal(true)}
          >
            {t("extraButtons.requestReview")} <Icons name="IconAlertTriangle" fill="transparent" size={24} />
          </Button>,
          <Button
            key="approve"
            variant="primary"
            variantType="success"
            size="small"
            onClick={() => setOpenAccountMovementModal(true)}
          >
            {t("extraButtons.approve")} <Icons name="IconCheck" fill="transparent" size={24} />
          </Button>,
        ]}
      />
      <DangerActionModal
        iconPosition={Alignment.left}
        isLoading={isRejecting}
        headerIconLabel={t("modals.confirmationModalRejectExpense.attention")}
        headerTitle={t("modals.confirmationModalRejectExpense.popupTitle")}
        labelButtonAction={t("modals.confirmationModalRejectExpense.confirm")}
        onActionClick={() => rejectExpense(expense.id, selectedReason)}
        onCancelClick={() => setOpenRejectModal(false)}
        onCloseClick={() => setOpenRejectModal(false)}
        open={openRejectModal}
      >
        <Typography variant="body4" color="neutral.40">
          {t("modals.confirmationModalRejectExpense.reasonLabel")}
        </Typography>
        <ReasonSelect
          onChange={(selectedReason) => setSelectedReason(selectedReason.name)}
          hideSelectField={false}
          error={false}
          type={ExpenseType.REIMBURSEMENT}
        />
      </DangerActionModal>
      <DangerActionModal
        iconPosition={Alignment.left}
        isLoading={isRequestingReview}
        headerIconLabel={t("modals.confirmationModalRequestReview.attention")}
        headerTitle={t("modals.confirmationModalRequestReview.popupTitle")}
        headerSubtitle={t("modals.confirmationModalRequestReview.popupSubtitle")}
        labelButtonAction={t("modals.confirmationModalRequestReview.confirm")}
        onActionClick={() => requestReview(expense.id, selectedReason)}
        onCancelClick={() => setOpenRequestReviewModal(false)}
        onCloseClick={() => setOpenRequestReviewModal(false)}
        open={openRequestReviewModal}
      >
        <Typography variant="body4" color="neutral.40">
          {t("modals.confirmationModalRequestReview.reasonLabel")}
        </Typography>
        <ReasonSelect
          onChange={(selectedReason) => setSelectedReason(selectedReason.name)}
          hideSelectField={true}
          error={false}
          type={ExpenseType.REIMBURSEMENT}
        />
      </DangerActionModal>
      <SuccessActionModal
        iconPosition={Alignment.left}
        isLoading={isAccounting}
        headerIcon={<ShapeIcon size="large" variant={"success"} icon={"IconCurrencyDollar"} />}
        headerIconLabel={t("modals.confirmationModalApprove.attention")}
        headerTitle={t("modals.confirmationModalApprove.popupTitle")}
        labelButtonAction={t("modals.confirmationModalApprove.confirm")}
        onActionClick={() => accountMovement(expense.id)}
        onCancelClick={() => setOpenAccountMovementModal(false)}
        onCloseClick={() => setOpenAccountMovementModal(false)}
        open={openAccountMovementModal}
      />
    </>
  );
}
