export const enum Routes {
  ADMIN_CATEGORIES = "/expense-lifecycle/admin/categories",

  ADMIN_REIMBURSEMENTS = "/expense-lifecycle/admin/reimbursements",
  ADMIN_REIMBURSEMENT = "/expense-lifecycle/admin/reimbursement/:expenseId",
  ADMIN_REIMBURSEMENT_EDIT = "/expense-lifecycle/admin/reimbursement/edit/:expenseId",

  ADMIN_STATEMENT = "/expense-lifecycle/admin/statement",
  ADMIN_MOVEMENT = "/expense-lifecycle/admin/movement/:expenseId",
  ADMIN_MOVEMENT_EDIT = "/expense-lifecycle/admin/movement/edit/:expenseId",

  REIMBURSEMENTS = "/expense-lifecycle/reimbursements",
  REIMBURSEMENT = "/expense-lifecycle/reimbursement/:expenseId",
  REIMBURSEMENT_APPROVAL = "/expense-lifecycle/reimbursement/approval/:expenseId",
  REIMBURSEMENT_EDIT = "/expense-lifecycle/reimbursement/edit/:expenseId",
  REIMBURSEMENT_CREATE = "/expense-lifecycle/reimbursement/create",

  STATEMENT = "/expense-lifecycle/statement",
  MOVEMENT = "/expense-lifecycle/movement/:expenseId",
  MOVEMENT_APPROVAL = "/expense-lifecycle/movement/approval/:expenseId",
  MOVEMENT_EDIT = "/expense-lifecycle/movement/edit/:expenseId",
  STATEMENT_PENDING_ITEMS = "/expense-lifecycle/statement/pending-items",

  SUBSCRIPTION = "/expenseManagement/subscription",

  ADMIN_ACQUISITION_FORM = "/expense-lifecycle/acquisition/form",
}

export const enum ExternalRoutes {
  PENDING_ACCOUNT_APPROVALS = "/expense/approvals/pending-approvals",
  PENDING_REIMBURSEMENT_APPROVALS = "/expense/approvals/pending-approvals/reimbursements",
}
