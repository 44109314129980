import { FooterActions, FooterContainer } from "././styled";

type FooterProps = {
  start?: JSX.Element[];
  middle?: JSX.Element[];
  end?: JSX.Element[];
};

export const FocusedFooter = ({ start, middle, end }: FooterProps) => {
  return (
    <FooterContainer>
      <FooterActions>
        {start && start.map((element, index) => <div key={`start-${index}`}>{element}</div>)}
      </FooterActions>
      <div>{middle && middle.map((element, index) => <div key={`middle-${index}`}>{element}</div>)}</div>
      <FooterActions>{end && end.map((element, index) => <div key={`end-${index}`}>{element}</div>)}</FooterActions>
    </FooterContainer>
  );
};
