import { Loader } from "$atoms";
import { BasePage } from "$frontend/components/organisms/BasePage/BasePage";
import { useTranslation } from "react-i18next";
import { useExpense } from "./data/useExpense";
import { ReimbursementDetails } from "./Details";
import { FooterActions } from "./FooterActions/FooterActions";
import { HeaderActions } from "./HeaderActions/HeaderActions";
import { useBreadCrumb } from "./hooks/useBreadcrumb";

export type Origin =
  | "user-reimbursement"
  | "admin-reimbursement"
  | "user-movement"
  | "admin-movement"
  | "approval-reimbursement"
  | "approval-movement";

export function ExpenseDetails({ origin }: { origin: Origin }) {
  const { expense, isLoading } = useExpense();
  const breadcrumb = useBreadCrumb(origin);

  const isMovement = ["user-movement", "admin-movement", "approval-movement"].includes(origin);

  const { t } = useTranslation("translations", {
    keyPrefix: isMovement ? "pages.visualizeMovement" : "pages.visualizeReimbursement",
  });

  return (
    <BasePage
      header={{
        title: t("title"),
        action: <HeaderActions expense={expense} origin={origin} />,
        breadcrumbItems: breadcrumb,
      }}
      footer={<FooterActions expense={expense} origin={origin} />}
    >
      {expense ? <ReimbursementDetails expense={expense} isLoading={isLoading} /> : <Loader show={isLoading} />}
    </BasePage>
  );
}
