import Flex from "$frontend/components/atoms/Flex/Flex";
import { ShapeIcon, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { useTranslation } from "react-i18next";

export function FilterEmptyState() {
  const { t } = useTranslation("translations", { keyPrefix: "organisms.filterEmptyState" });
  return (
    <Flex direction="column" align="center" justify="center" gap="xs" padding="xs">
      <ShapeIcon stroke="neutral" variant="neutral" name="IconZoomCancel" size={64} />

      <Flex direction="column" align="center" justify="center">
        <Typography.Headline8 color="neutral.50">{t("title")}</Typography.Headline8>
        <Typography.Body4 color="neutral.50">{t("subtitle")}</Typography.Body4>
      </Flex>
    </Flex>
  );
}
