import dayjs from "dayjs";
export interface GetFormatLocaleValueProps {
  value: number;
  isDecimal?: boolean;
}
export interface GetValueWithCurrencyProps {
  value?: number;
  currencyPrefix?: string;
  locale?: string;
}

export function getValueWithCurrency({
  value = 0,
  currencyPrefix = "BRL",
  locale = "pt-BR",
}: GetValueWithCurrencyProps) {
  value = Number((value / 100).toFixed(2));

  return value?.toLocaleString(locale, {
    style: "currency",
    currency: currencyPrefix,
  });
}

export function getFormatDecimalValue(value: number, locale = "pt-BR") {
  return `${value?.toLocaleString(locale, { maximumFractionDigits: 1 })}`;
}

export function centsToMoney(cents: number | undefined) {
  return Number(((cents || 0) / 100).toFixed(2));
}

export function getLastMinuteAndSecondOfDay(date: Date): Date {
  const endOfDay = new Date(date);
  endOfDay.setHours(23, 59, 59, 999);
  return endOfDay;
}

/**
 * Format Date to DD/MM/YYYY
 *
 * Ex: 22/08/2016
 **/
export function formatDate(date: Date) {
  return dayjs(date).format("DD/MM/YYYY");
}

/**
 * Format Date period
 *
 * Ex: DD/MM/YYYY - DD/MM/YYYY
 **/
export function formatPeriod(firstDate: Date, lastDate: Date) {
  return `${formatDate(firstDate)} - ${formatDate(lastDate)}`;
}
