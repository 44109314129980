import { trpc } from "$client";
import { Routes } from "$frontend/routes";
import { dispatchToast } from "$frontend/utils/dispatch-events";
import { ExpenseStatus } from "$serverTypes";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export function useReimburseExpense() {
  const navigate = useNavigate();
  const { t } = useTranslation("translations", { keyPrefix: "pages.adminReimbursement" });
  const { mutate, isLoading: isReimbursing } = trpc.expense.updateSingleStatus.useMutation({
    onSuccess: () => {
      dispatchToast({
        type: "success",
        content: t("toasts.markAsReimbursedSuccess.title"),
      });

      navigate(Routes.ADMIN_REIMBURSEMENTS);
    },
    onError: () => {
      dispatchToast({
        type: "error",
        content: t("toasts.markAsReimbursedError.title"),
        description: t("toasts.markAsReimbursedError.description"),
      });
    },
  });

  function markAsReimbursed(id: string) {
    mutate({
      expenseId: id,
      targetStatus: ExpenseStatus.FINISHED,
    });
  }

  return { markAsReimbursed, isReimbursing };
}
