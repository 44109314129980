import { Skeleton } from "@mui/material";
import { Container } from "./styled";

export interface CategoryListSwitchCardSkeletonProps {
  printItems?: number;
}

export const CategoriesListLoadingSkeleton = ({ printItems = 10 }: CategoryListSwitchCardSkeletonProps) => {
  return (
    <Container>
      {new Array(printItems).fill({}).map((_, key) => (
        <Skeleton key={key} height={56} width={"100%"} variant="rounded" />
      ))}
    </Container>
  );
};
