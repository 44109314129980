import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { ArcElement, Chart } from "chart.js";
import { useTranslation } from "react-i18next";

import { DoughnutChartExpenseData } from "$molecules";
import { ExpenseType, Summary } from "$serverTypes";
import { getValueWithCurrency } from "$utils";
import { ChartSubtitle, Circle, Container, Content } from "./styled";

Chart.register(ArcElement);

/**
 * Props for the DoughnutChart component.
 */
type ReimbursementChartProps = {
  /** Title of chart */
  title?: string;

  /** Summaries to display */
  summaries: Summary[];

  /** expense type */
  type: ExpenseType;

  /** 100% width css if true */
  fullWidth?: boolean;
};

export const ExpenseChart = ({ title, summaries, type, fullWidth }: ReimbursementChartProps) => {
  const { t } = useTranslation("translations", {
    keyPrefix: `organisms.expenseSummaryChart.${type}`,
  });

  const totalAmount = summaries.reduce((total, { amount }) => total + amount, 0);

  const totalCount = summaries.reduce((total, { count }) => total + count, 0);

  return (
    <Container fullWidth={fullWidth}>
      <Content>
        <DoughnutChartExpenseData summaries={summaries} />
        <div>
          <Typography variant="body4" weight={600} color="neutral.40">
            {title ?? t("title")}
          </Typography>
          <ChartSubtitle>
            <Typography variant="headline8" weight={700} color="neutral.30">
              {getValueWithCurrency({
                value: totalAmount,
              })}
            </Typography>
            {type === ExpenseType.REIMBURSEMENT && (
              <>
                <Circle variant="gray" size={4} />
                <Typography variant="caption" color="neutral.40">
                  {t("expenses", { count: totalCount })}
                </Typography>
              </>
            )}
          </ChartSubtitle>
        </div>
      </Content>
    </Container>
  );
};
