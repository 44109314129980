import { Icons, LinkButton, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { useNavigate } from "react-router-dom";

import { Routes } from "$frontend/routes";
import { FocusedHeader } from "$molecules";
import { useTranslation } from "react-i18next";

export function Header() {
  const navigate = useNavigate();
  const { t } = useTranslation("translations", {
    keyPrefix: "pages.createReimbursement",
  });

  return (
    <FocusedHeader
      start={[
        <LinkButton variant="neutral" key={1} onClick={() => navigate(Routes.REIMBURSEMENTS)}>
          <Icons name="IconChevronLeft" />
          {t("back")}
        </LinkButton>,
      ]}
      middle={[
        <Typography variant="headline9" weight={700} color="neutral.30">
          {t("title")}
        </Typography>,
      ]}
    />
  );
}
