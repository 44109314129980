import { Button, LinkButton, PillButton, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { Drawer } from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";

import { useCategory } from "$containers/category";
import { CheckboxGroup, SliderField } from "$molecules";
import { ExpenseStatus, ExpenseType, TransactionStatus } from "$serverTypes";
import { Card, CloseButton, Container, Content, Footer } from "./styled";
import { ExpenseFiltersFields, FiltersConfig } from "./types";

type ExpenseFilterDrawerProps = {
  /**
   * Filters applied to the expense data.
   */
  filters: ExpenseFiltersFields;

  /**
   * Configuration to toggle specific filters.
   */
  config: FiltersConfig;

  /**
   * Expense type to define terms in filter
   */
  expenseType: ExpenseType;

  /**
   * Determines if the drawer is open.
   */
  isOpen: boolean;

  /**
   * Callback to close the drawer.
   */
  onClose: () => void;

  /**
   * Callback to submit the selected filters.
   */
  onSubmit: (filters: ExpenseFiltersFields) => void;

  /**
   * Callback to return the count of selected filters
   */
  onFilterCountChange?: (count: number) => void;
};

const expenseStatusOptions = [
  ExpenseStatus.DRAFT,
  ExpenseStatus.REQUIRE_CHANGES,
  ExpenseStatus.PENDING_ACCOUNTING,
  ExpenseStatus.FINISHED,
  ExpenseStatus.REJECTED,
] satisfies ExpenseStatus[];

const transactionStatusOptions = [
  TransactionStatus.AUTHORIZED,
  TransactionStatus.COMPLETED,
  TransactionStatus.CANCELED,
  TransactionStatus.REVERTED,
  TransactionStatus.PROCESSING,
] satisfies TransactionStatus[];

const NO_CATEGORY = "UNCATEGORIZED";

export const ExpenseFilterDrawer = (props: ExpenseFilterDrawerProps) => {
  const [key, setKey] = useState(0);

  const defaultValues: ExpenseFiltersFields = {
    valueRange: {},
    transactionStatus: [],
    categoryNanoIds: [],
    expenseStatus: [],
  };

  const methods = useForm<ExpenseFiltersFields>({
    defaultValues: defaultValues,
  });
  const control = methods.control;

  useEffect(() => {
    methods.reset(props.filters);

    if (props.onFilterCountChange) {
      const count = getSelectedFiltersCount(props.filters);
      props.onFilterCountChange(count);
    }
  }, [methods, props.filters]);

  const { data: categories, isFetching: isFetchingCategories } = useCategory();

  const { t } = useTranslation("translations", { keyPrefix: "organisms.expenseFilterDrawer" });

  const theme = useTheme();

  const resolvedConfig: Required<FiltersConfig> = {
    expenseStatus: false,
    categoryNanoIds: false,
    valueRange: false,
    transactionStatus: false,
    ...props.config,
  };

  function onReset() {
    methods.reset(defaultValues);
    setKey(key + 1);
  }

  function handleSubmit() {
    props.onSubmit(methods.getValues());
    props.onClose();
  }

  function getSelectedFiltersCount(filters: ExpenseFiltersFields): number {
    let count = 0;

    if ((filters.expenseStatus ?? []).length > 0) count += 1;
    if ((filters.transactionStatus ?? []).length > 0) count += 1;
    if ((filters.categoryNanoIds ?? []).length > 0) count += 1;
    if (filters?.valueRange?.min || filters.valueRange?.max) count += 1;

    return count;
  }

  return (
    <Drawer open={props.isOpen} onClose={props.onClose} anchor="right">
      <Container>
        <Content>
          <CloseButton>
            <PillButton variant="default" size="small" icon="IconX" onClick={props.onClose} />
          </CloseButton>
          <Typography variant="headline7" tag="h1" color="neutral.20">
            {t("filters")}
          </Typography>

          {resolvedConfig.expenseStatus && (
            <Controller
              control={control}
              name="expenseStatus"
              render={({ field: { onChange, value } }) => (
                <Card>
                  <Typography variant="headline9" color="neutral.30" tag="p">
                    {props.expenseType === ExpenseType.CORPORATE_CARD
                      ? t("fields.corporateCardStatus.title")
                      : t("fields.reimbursementsStatus.title")}
                  </Typography>
                  <CheckboxGroup
                    options={expenseStatusOptions.map((status) => ({
                      label:
                        props.expenseType === ExpenseType.CORPORATE_CARD
                          ? t(`fields.corporateCardStatus.labels.${status}`)
                          : t(`fields.reimbursementsStatus.labels.${status}`),
                      value: status,
                    }))}
                    value={value}
                    onChange={onChange}
                    key={`expense-status-${key}`}
                  />
                </Card>
              )}
            />
          )}

          {resolvedConfig.transactionStatus && (
            <Controller
              control={control}
              name="transactionStatus"
              render={({ field: { onChange, value } }) => (
                <Card>
                  <Typography variant="headline9" color="neutral.30" tag="p">
                    {t("fields.transactionStatus.title")}
                  </Typography>
                  <CheckboxGroup
                    options={transactionStatusOptions.map((status) => ({
                      label: t(`fields.transactionStatus.labels.${status}`),
                      value: status,
                    }))}
                    value={value}
                    onChange={onChange}
                    key={`transaction-status-${key}`}
                  />
                </Card>
              )}
            />
          )}

          {resolvedConfig.categoryNanoIds && (
            <Controller
              control={control}
              name="categoryNanoIds"
              render={({ field: { onChange, value } }) => (
                <Card>
                  <Typography variant="headline9" color="neutral.30" tag="p">
                    {t("fields.category.title")}
                  </Typography>
                  <CheckboxGroup
                    options={[
                      { label: t("noCategory"), value: NO_CATEGORY },
                      ...(categories?.map((category) => ({
                        label: category.name,
                        value: category.id,
                      })) || []),
                    ]}
                    value={value}
                    onChange={onChange}
                    loading={isFetchingCategories}
                    key={`category-${key}`}
                  />
                </Card>
              )}
            />
          )}

          {resolvedConfig.valueRange && (
            <Controller
              control={control}
              name="valueRange"
              render={({ field: { onChange, value } }) => (
                <Card>
                  <Typography variant="headline9" color="neutral.30" tag="p">
                    {t("fields.amountRange.title")}
                  </Typography>
                  <SliderField min={value?.min} max={value?.max} onChange={onChange} />
                </Card>
              )}
            />
          )}
        </Content>
        <Footer>
          <LinkButton variant="default" onClick={onReset}>
            {t("reset")}
          </LinkButton>
          <Button minWidth="200px" variant="primary" onClick={handleSubmit}>
            {t("submit")}
          </Button>
        </Footer>
      </Container>
    </Drawer>
  );
};
