import { Button, Icons, LinkButton, Typography } from "@flash-tecnologia/hros-web-ui-v2"; // TODO passar pelo styled ou criar componente
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { Loader } from "$atoms";
import { trpc } from "$client";
import { useDisplayToast } from "$frontend/utils";
import { FocusedContainer, FocusedFooter, FocusedHeader, MovementForm } from "$molecules";
import { ExpenseStatus } from "$serverTypes";
import { ExpenseFormDto } from "../ReimbursementCreate";

export const MovementPage = () => {
  const { expenseId } = useParams();
  const {
    data: expense,
    isFetching: isFetchingExpense,
    refetch,
  } = trpc.expense.getExpenseById.useQuery(expenseId ?? "");
  const { mutateAsync: updateExpenseStatus, isLoading: isSendingExpenseToAccount } =
    trpc.expense.updateSingleStatus.useMutation();

  const [formToggle, setFormToggle] = useState(false);
  const { mutateAsync: patchExpense, isLoading: isLoadingPatchExpense } = trpc.expense.patchExpense.useMutation();
  const navigate = useNavigate();
  const { t } = useTranslation("translations", { keyPrefix: "pages.visualizeMovement" });
  const { displayToast } = useDisplayToast();

  const isLoading = isFetchingExpense || isLoadingPatchExpense || isSendingExpenseToAccount;
  const isExpenseRequireChanges = expense?.status === ExpenseStatus.REQUIRE_CHANGES;

  const currentExpenseFormData = expense && {
    attachments: expense.attachments ?? [],
    category: expense.category ?? { nanoId: "", description: "" },
    comments: expense.comments ?? "",
    amount: expense.amount,
    date: new Date(expense.date),
  };
  const methods = useForm<ExpenseFormDto>({
    defaultValues: currentExpenseFormData,
    values: currentExpenseFormData,
  });
  const unchagedForm = !methods.formState.isDirty;

  async function onSubmit(formData: ExpenseFormDto, shouldSendToAccount: boolean) {
    if ((unchagedForm && !shouldSendToAccount) || !expense) {
      return;
    }

    const fieldsToPatch = {
      attachments: formData.attachments,
      comments: formData.comments,
      category: formData.category,
    };

    try {
      await patchExpense([expense.id, fieldsToPatch]);
      if (shouldSendToAccount) {
        await updateExpenseStatus({
          expenseId: expense.id,
          targetStatus: ExpenseStatus.PENDING_ACCOUNTING,
        });
      }
      displayToast({
        title: t(shouldSendToAccount ? "toasts.successSendingToAccount.title" : "toasts.successEdit.title"),
      });

      setFormToggle(false);
      refetch();
    } catch (e) {
      displayToast({
        type: "error",
        title: t(shouldSendToAccount ? "toasts.failedSendingToAccount.title" : "toasts.failedEdit.title"),
        description: t(
          shouldSendToAccount ? "toasts.failedSendingToAccount.description" : "toasts.failedEdit.description",
        ),
      });
    }
  }

  function onInvalidFields() {
    displayToast({
      type: "warning",
      title: t("toasts.requiredField.title"),
      description: t("toasts.requiredField.description"),
    });
  }

  return (
    <FocusedContainer
      header={
        <FocusedHeader
          start={[
            <LinkButton variant="neutral" key={1} onClick={() => navigate(-1)}>
              {t("back")}
            </LinkButton>,
          ]}
          middle={[
            <Typography variant="headline9" weight={700} color="neutral.30">
              {t(formToggle ? "titleEdit" : "title")}
            </Typography>,
          ]}
        />
      }
      footer={
        formToggle ? (
          <FocusedFooter
            start={[
              <LinkButton variant="neutral" key={2} onClick={() => setFormToggle(false)}>
                {t("cancel")}
              </LinkButton>,
            ]}
            end={[
              <Button
                disabled={unchagedForm}
                loading={isLoading}
                variant="primary"
                size="medium"
                key={2}
                onClick={methods.handleSubmit(
                  (formData) => onSubmit(formData, isExpenseRequireChanges),
                  onInvalidFields,
                )}
              >
                {t(isExpenseRequireChanges ? "submit.sendExpenseToAccount" : "submit.save")}
                <Icons name="IconCheck" />
              </Button>,
            ]}
          />
        ) : undefined
      }
    >
      {expense ? (
        <MovementForm
          expense={expense}
          isLoading={isLoading}
          methods={methods}
          formToggle={formToggle}
          onFormToggle={setFormToggle}
        />
      ) : (
        <Loader show={isLoading} />
      )}
    </FocusedContainer>
  );
};
